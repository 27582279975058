import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'smart-payment-email',
  templateUrl: './payment-email.component.html',
  styleUrls: ['./payment-email.component.scss']
})
export class PaymentEmailComponent implements OnInit {

  visible = false;

  paymentItem: any;

  text = '';

  constructor() { }

  ngOnInit() {
  }

  show(
    paymentItem: any
  ) {
    this.visible = true;
    this.paymentItem = paymentItem;
  }

  close() {
    this.visible = false;
  }

  send() {
    this.close();
  }

}
