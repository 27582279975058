import { NgModule } from '@angular/core';
import { UserRolePipe } from './user-role.pipe';
import { RoundPipe } from './round.pipe';
import { IsEmptyStringPipe } from './is-empty-string.pipe';
import { FormsCodesPipe } from './forms-codes.pipe';
import { SafeStylePipe } from './safe-style.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { UserRegPersonPipe } from './user-reg-person.pipe';

@NgModule({
  imports: [],
  declarations: [UserRolePipe, RoundPipe, IsEmptyStringPipe, FormsCodesPipe, SafeStylePipe, SafeUrlPipe, UserRegPersonPipe],
  exports: [UserRolePipe, RoundPipe, IsEmptyStringPipe, FormsCodesPipe, SafeStylePipe, SafeUrlPipe, UserRegPersonPipe],
  providers: [UserRolePipe, RoundPipe],
})

export class CustomPipesModule {}
