export enum queryOperators {
  OR = 'OR',
  AND = 'AND'
}

export enum queryFilterTypes {
  EQUAL = 'Equal',
  NOTEQUAL = 'NotEqual',
  GREATERTHAN = 'GreaterThan',
  LESSTHAN = 'LessThan',
  Contains = 'Contains',
  LessThanOrEqual = 'LessThanOrEqual',
  GreaterThanOrEqual = 'GreaterThanOrEqual',
  NotContains = 'NotContains',
  StartsWith = 'StartsWith',
  IsNull = 'IsNull',
  IsNotNull = 'IsNotNull'
}

export enum queryResultOrder {
  DESC = 'DESC',
  ASC = 'ASC'
}

export class QueryDesignerOrderBy {
  field: string;
  order: queryResultOrder;

  constructor(field: string, order: queryResultOrder) {
    this.field = field;
    this.order = order;
  }
}

export class QueryDesignerOperand {
  field: string;
  filterType: queryFilterTypes;
  value?: string | number | null;
}

export class QueryDesignerWhere {
  operatorType: queryOperators;
  operands: QueryDesignerSubWhere[] = [];
}

export class QueryDesignerSubWhere {
  operatorType: queryOperators;
  operands: QueryDesignerOperand[] = [];
}

export class QueryDesignerSearch {
  fields: string[] = [];
  value: string;

  constructor() {
    this.fields = [];
    this.value = '';
  }

  public createEmpty(): QueryDesignerSearch {
    const c = new QueryDesignerSearch();
    c.fields = [];
    c.value = '';
    return c;
  }
}

export class QueryDesigner {
  search: [QueryDesignerSearch];
  where: QueryDesignerWhere;
  orderBy: QueryDesignerOrderBy[];
  page: number;
  pageSize: number;

  static createEmpty(): QueryDesigner {
    const q = new QueryDesigner();
    q.search = [new QueryDesignerSearch()];
    q.where = new QueryDesignerWhere();
    q.orderBy = [];
    q.pageSize = 15;
    q.page = 1;

    return q;
  }
}
