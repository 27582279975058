import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ReaderCardFullModel } from '../../../../models/reader-card-full.model';
import { CardsService } from '../../../../services/cards.service';
import { ReaderCardUpdateRequestModel } from '../../../../models/reader-card-update-request.model';
import { ToastrService } from 'ngx-toastr';
import { Dropdown } from 'primeng';
import { ConfirmationService } from 'primeng';
import { ReaderCardBaseComponent } from '../../../../components/reader-card/reader-card-base.component';
import { AppCommunicationService } from '../../../../services/app-communication.service';
import * as moment from 'moment';
import { I18nService } from "../../../../../core-frontend-common/template/shared/i18n";

@Component({
  selector: 'smart-readercard-view',
  templateUrl: './readercard-view.component.html',
  styleUrls: ['./readercard-view.component.scss']
})
export class ReadercardViewComponent extends ReaderCardBaseComponent implements OnInit {
  TABS = {
    normalCards: 'normalCards',
    historicCards: 'historicCards'
  };

  @ViewChild('cardNumberEl', { static: false }) cardNumberEl: ElementRef;

  @ViewChild('readerCardEl', { static: false }) readerCardEl: Dropdown;

  constructor(
    protected cardsService: CardsService,
    protected confirmation: ConfirmationService,
    protected message: ToastrService,
    protected appCommunicationService: AppCommunicationService,
    protected i18n: I18nService
  ) {
    super(cardsService, message, appCommunicationService, i18n);

    this.setAdminView(true);
  }

  ngOnInit() {
    this.focusToInput();
  }

  search() {
    this.getCardByCardNumber();
  }

  expireCard() {
    this.confirmation.confirm({
      message: 'Skutočne si prajete nastaviť kartu ako expirovanú?',
      header: 'Expirovanie karty',
      acceptLabel: 'Áno',
      rejectLabel: 'Nie',
      accept: () => {
        this.cardsService
          .expireCard(this.searchedCard.id)
          .subscribe(v => {
            this.message.success('Karta bola nastavená úspešne ako expirovaná');
            this.resetForm();
          }, e => {
            this.message.error('Nastala chyba pri nastavení expirovanej karty.');
          });
      }
    });
  }

  renewCard() {
    this.confirmation.confirm({
      message: 'Skutočne si prajete obnoviť čitateľskú kartu?',
      header: 'Obnovenie karty',
      acceptLabel: 'Áno',
      rejectLabel: 'Nie',
      accept: () => {
        this.cardsService
          .renewCard(this.searchedCard.id)
          .subscribe(v => {
            this.message.success('Karta bola úspešne obnovaná.');
            this.resetForm();
          }, e => {
            this.message.error('Nastala chyba pri obnovení karty.');
          });
      }
    });
  }

  async clickedCreateCard() {

    const res = await this.createCard();

    if (res) {
      this.resetForm();
    }
  }

  updateCard() {
    console.log('ide sem??');

    this.confirmation.confirm({
      message: 'Skutočne si prajete uložiť zmeny údajov karty?',
      header: 'Zmena údajov karty',
      acceptLabel: 'Áno',
      rejectLabel: 'Nie',
      accept: () => {


        const updateCard = new ReaderCardUpdateRequestModel();

        Object.assign(updateCard, this.searchedCard);

        if (this.bornDateFromInput) {
          const date = moment(this.bornDateFromInput, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss');

          if (date) {
            updateCard.borndate = date;
          }
        }

        this.cardsService
          .updateCardAsAdmin(updateCard)
          .subscribe(v => {
            this.message.success('Údaje na karte boli úspešne upravené.');
            this.resetForm();
          }, e => {
            this.message.error('Nastala chyba pri upravovaní údajov na karte.');
          });
      }
    });
  }

  toggleEdit() {
    this.allowedEdit = !this.allowedEdit;
  }

  resetForm() {
    this.currentViewState = this.viewStates.searching;
    this.searchedCard = new ReaderCardFullModel();
    this.allowedEdit = false;
    this.cardNumber = '';
    this.focusToInput();
  }

  focusToInput() {

    setTimeout(() => {
      if (this.cardNumberEl) {
        this.cardNumberEl.nativeElement.focus();
      }
    }, 300);
  }

  keyDownAction(e) {
    if (e && e.key === 'Enter') {
      if (this.cardNumber && this.cardNumber.length >= 2) {
        this.search();
      }
    }
  }

}
