export class ReaderCardUpdateRequestModel {
  id: number;
  barcode: string;
  address1street: string;
  address1psc: string;
  address1town: string;
  phone: string;
  email: string;

  borndate?: Date | string;
  remark?: string;
}
