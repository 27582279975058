import { NgModule } from '@angular/core';
import { LibtukeComponent } from './libtuke.component';
import { CommonModule } from '@angular/common';
import { AddBookNoveltyComponent } from './books/add-book-novelty/add-book-novelty.component';
import { NgxPhotoEditorModule } from 'ngx-photo-editor';
import { CustomPipesModule } from '../../pipes/customPipes.module';
import { FormsModule } from '@angular/forms';
import { ListBooksComponent } from './books/list-books/list-books.component';
import { DropdownModule, EditorModule, FileUploadModule, TableModule, TooltipModule } from "primeng";
import { RouterModule, Routes } from '@angular/router';
import { BooksComponent } from './books/books.component';
import { AuthGuard } from '../../../core-frontend-common/auth/guards/auth.guard';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { FaqComponent } from './faq/faq.component';
import { NewsComponent } from './news/news.component';
import { AddFaqComponent } from './faq/add-faq/add-faq.component';
import { ListFaqComponent } from './faq/list-faq/list-faq.component';
import { ListNewsComponent } from './news/list-news/list-news.component';
import { AddNewsComponent } from './news/add-news/add-news.component';
import { AddPortfolioComponent } from './portfolio/add-portfolio/add-portfolio.component';
import { ListPortfolioComponent } from './portfolio/list-portfolio/list-portfolio.component';
import { LibtukeNewsService } from "./news/libtuke-news.service";
import { LibtukeFaqService } from "./faq/libtuke-faq.service";
import { LibtukePortfolioService } from "./portfolio/libtuke-portfolio.service";
import { I18nModule } from "../../../core-frontend-common/template/shared/i18n/i18n.module";
import { DragDropModule } from "@angular/cdk/drag-drop";

const routes: Routes = [
  {
    path: '',
    component: LibtukeComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'books', component: BooksComponent, pathMatch: 'full' },
      { path: 'news', component: NewsComponent, pathMatch: 'full' },
      { path: 'faq', component: FaqComponent, pathMatch: 'full' },
      { path: 'portfolio', component: PortfolioComponent, pathMatch: 'full' },
    ],
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    CommonModule,
    NgxPhotoEditorModule,
    CustomPipesModule,
    FormsModule,
    TableModule,
    RouterModule.forChild(routes),
    EditorModule,
    DropdownModule,
    I18nModule,
    FileUploadModule,
    TooltipModule,
    DragDropModule
  ],
  declarations: [
    LibtukeComponent,
    AddBookNoveltyComponent,
    ListBooksComponent,
    BooksComponent,
    PortfolioComponent,
    FaqComponent,
    NewsComponent,
    AddFaqComponent,
    ListFaqComponent,
    ListNewsComponent,
    AddNewsComponent,
    AddPortfolioComponent,
    ListPortfolioComponent
  ],
  providers: [
    LibtukeNewsService,
    LibtukeFaqService,
    LibtukePortfolioService
  ]
})

export class LibtukeModule {}
