import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FaqItemModel } from '../faq-item.model';
import { AppCommunicationService } from '../../../../services/app-communication.service';
import { LibtukeFaqService } from '../libtuke-faq.service';
import { FAQ_SYSTEMS } from '../../../../constants';
import { first } from 'rxjs/operators';
import { ConfirmationService } from 'primeng';

@Component({
  selector: 'smart-list-faq',
  templateUrl: './list-faq.component.html',
  styleUrls: ['./list-faq.component.scss']
})
export class ListFaqComponent implements OnInit {
  allUkSystems = FAQ_SYSTEMS;

  currentSystem = FAQ_SYSTEMS[0];

  table = {
    columns: [],
    page: 1,
    pageSize: 30,
    totalItems: 0
  };

  data: FaqItemModel[] = [];

  @Output() editItemEvent: EventEmitter<FaqItemModel> = new EventEmitter<FaqItemModel>();

  constructor(
    private appCommunication: AppCommunicationService,
    private faqService: LibtukeFaqService,
    private confirmation: ConfirmationService
  ) { }

  ngOnInit() {
  }

  setPage(pageInfo) {
    this.table.page = (pageInfo.first + pageInfo.rows) / this.table.pageSize;
    this.loadData();
  }

  changedSystemFilter(system) {
    this.currentSystem = system;
    this.loadData();
  }

  loadData() {
    this.appCommunication.loadingOn('Načítavam dáta.');

    const queryDesigner = {
      page: this.table.page,
      pageSize: this.table.pageSize
    };

    this.faqService
      .getAllBySystem(this.currentSystem)
      .subscribe(v => {
        this.table.totalItems = v.totalItems;
        this.data = v.items;
        this.appCommunication.loadingOff();
      }, err => {
        console.log(err);
        this.appCommunication.loadingOff();
      });
  }

  showDeleteConfirmation(item: FaqItemModel) {
    this.confirmation
      .confirm({
        message: 'Skutočne si prajete odstrániť záznam?',
        acceptLabel: 'Áno',
        rejectLabel: 'Nie',
        accept: () => this.deleteFaq(item)
      });
  }

  deleteFaq(item: FaqItemModel) {
    this.faqService
      .delete(item.id)
      .pipe(first())
      .subscribe({
        next: () => this.loadData()
      });
  }

  openDetail(item: FaqItemModel) {
    this.editItemEvent.emit(item);
  }

}
