export interface Seminar {
  code: string;
  text: string;
}

export enum SeminarsEnum {
  cit = 'cit',
  etd = 'etd',
  epc = 'epc',
  sluz = 'sluz',
  dig = 'dig',
  res = 'res',
  mvs = 'mvs',
  acm_iee = 'acm_iee',
  inet = 'inet'
}

export const Seminars: Seminar[] = [
  {
    code: SeminarsEnum.etd,
    text: `${SeminarsEnum.etd.toUpperCase()} - Elektronické záverečné práce na TUKE`
  },
  {
    code: SeminarsEnum.cit,
    text: `${SeminarsEnum.cit.toUpperCase()} - Citovanie literatúry a tvorba zoznamu bibliografických ohlasov`
  },
  {
    code: SeminarsEnum.epc,
    text: `${SeminarsEnum.epc.toUpperCase()} - Evidencia publikačnej činnosti`
  },
  {
    code: SeminarsEnum.sluz,
    text: `${SeminarsEnum.sluz.toUpperCase()} - Knižnično-informačné služby UK TUKE`
  },
  {
    code: SeminarsEnum.dig,
    text: `${SeminarsEnum.dig.toUpperCase()} - Digitálna knižnica: vyhľadávanie v informačných zdrojoch TUKE, rešerš`
  }
];

export enum TukeRelationshipEnum {
  student = 1,
  phdStudent = 2,
  employer = 3
}

export const TukeRelationShips = [
  {
    code: TukeRelationshipEnum.student,
    text: 'Študent'
  },
  {
    code: TukeRelationshipEnum.phdStudent,
    text: 'Doktorand'
  },
  {
    code: TukeRelationshipEnum.employer,
    text: 'Zamestnanec'
  }
];

export enum SeminarFormTypeEnum {
  person = 1,
  group = 2
}

export const SeminarFormTypes = [
  {
    code: SeminarFormTypeEnum.person,
    text: 'Prihlásiť sa na školenie/seminár'
  },
  {
    code: SeminarFormTypeEnum.group,
    text: 'Prihlásiť študentov/skupinu na školenie/seminár'
  }
];
