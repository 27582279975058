export const EpcCorrectionRequestTypes = {
  exports: {
    text: 'Požiadavka na korektúry záznamov vo výstupoch',
    value: 1
  },
  archiveIds: {
    text: 'Reklamácia na nespracované archívne čísla',
    value: 2
  },
  others: {
    text: 'Iné',
    value: 3
  }
};

export const EpcCorrectionTypes = [
  {
    text: EpcCorrectionRequestTypes.exports.text,
    value: EpcCorrectionRequestTypes.exports.value
  },
  {
    text: EpcCorrectionRequestTypes.archiveIds.text,
    value: EpcCorrectionRequestTypes.archiveIds.value
  },
  {
    text: EpcCorrectionRequestTypes.others.text,
    value: EpcCorrectionRequestTypes.others.value
  }
]
