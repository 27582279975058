import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DigitalBookAuthorModel } from '../../../../models/digital-book-author.model';
import { DigitalBooksService } from '../../../../services/digital-books.service';

@Component({
  selector: 'app-new-author-modal-form',
  templateUrl: './new-author-modal-form.component.html',
  styleUrls: ['./new-author-modal-form.component.scss'],
})
export class NewAuthorModalFormComponent implements OnInit {
  authorForm: FormGroup;

  author: DigitalBookAuthorModel = new DigitalBookAuthorModel();

  @Output() externalFormSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() externalUserData: any;

  constructor(
    private formBuilder: FormBuilder,
    protected digitalBooksService: DigitalBooksService,
  ) {
  }

  ngOnInit() {
    this.fullFillForm();
  }

  fullFillForm() {
    this.authorForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      surname: ['', [Validators.required]],
    });

    this.valid.emit(false);

    this.authorForm.valueChanges.subscribe(() => {
      this.checkValidForm();
    });
  }

  checkValidForm() {
    if (this.authorForm.valid) {
      this.valid.emit(true);
      return true;
    } else {
      this.valid.emit(false);
      return false;
    }
  }

  onSubmit(): DigitalBookAuthorModel {
    return this.authorForm.value;
  }
}
