import { Component, OnInit } from '@angular/core';
import { WarehouseLayoutModel } from '../../../../models/warehouseLayout.model';
import { BorrowService } from '../../../../services/borrow.service';
import { OverlayService } from '../../../../services/overlay.service';

@Component({
  selector: 'smart-warehouse-view',
  templateUrl: './warehouse-view.component.html',
  styleUrls: ['./warehouse-view.component.scss'],
})
export class WarehouseViewComponent implements OnInit {
  data: WarehouseLayoutModel[] = [];
  pTable = {
    pageSize: 30,
    totalItems: 0,
    page: 1
  }

  isEditingRow = false;

  editedWarehouse = new WarehouseLayoutModel();

  constructor(
    protected borrowService: BorrowService,
    protected overlayService: OverlayService
  ) {
  }

  ngOnInit() {
    this.getAllData();
  }

  getAllData() {
    this.borrowService
      .getAllWarehouseLayout()
      .subscribe(v => {
        this.data = v;
        this.pTable.totalItems = v.length;
        this.pTable.page = 1;
      });
  }

  setPage(pageInfo) {
    this.pTable.page = (pageInfo.first + pageInfo.rows) / 15;
  }

  saveItem() {
    if (this.editedWarehouse) {
      this.borrowService
        .updateWarehouseLayout(this.editedWarehouse)
        .subscribe(v => {
          this.getAllData();
          this.isEditingRow = false;
          this.editedWarehouse = new WarehouseLayoutModel();
          this.overlayService.successNotification('Záznam bol úspešne upravený');
        }, error => {
          this.getAllData();
          this.overlayService.errorNotification('Nepodarilo sa upraviť záznam', 'Chyba');
          this.isEditingRow = false;
          this.editedWarehouse = new WarehouseLayoutModel();
          setTimeout(() => this.overlayService.errorNotification('Uistite sa, že Sign. start je menšia ako Sign. end'), 1000);
        });
    }
  }

  onEditClick(warehouse: WarehouseLayoutModel) {
    this.isEditingRow = true;
    this.editedWarehouse = warehouse;
  }

  closeEdit() {
    this.isEditingRow = false;
    this.editedWarehouse = new WarehouseLayoutModel();
  }
}
