import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppCommunicationService } from '../../../../services/app-communication.service';
import { Subscription } from 'rxjs';

export interface UserFormValue {
  name: string;
  workcenter: string;
  email: string;
  phone: string;
}

export interface UserSectionValidity {
  valid: boolean;
  user: UserFormValue;
}

@Component({
  selector: 'smart-user-form-section',
  templateUrl: './user-form-section.component.html',
  styleUrls: ['./user-form-section.component.scss']
})
export class UserFormSectionComponent implements OnInit, OnDestroy, OnChanges {
  form: FormGroup;

  currentUser;

  userFormSubscription: Subscription;

  @Output() userSectionValidity: EventEmitter<UserSectionValidity> = new EventEmitter<UserSectionValidity>();

  @Input() showFaculty = false;

  @Input() hideWorkcenter = false;

  constructor(
    protected fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.initForm();
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.showFaculty) {
      if (!this.form) return;

      if (this.showFaculty) {
        const userGroup = this.form.get('user') as FormGroup;

        userGroup.addControl('faculty', this.fb.control(this.currentUser.facultyName || this.currentUser.facultyCode || '', [Validators.required]));
      }
    }
  }

  ngOnDestroy() {
    this.userFormSubscription.unsubscribe();
  }

  initForm() {
    this.currentUser = AppCommunicationService.getCurrentUser().user;

    this.form = this.fb.group({
      user: this.fb.group({
        fullName: [`${this.currentUser.name}, ${this.currentUser.surname}`, [Validators.required]],
        workcenter: [this.currentUser.workCenterName || '', [Validators.required]],
        email: [this.currentUser.email || '', [Validators.required, Validators.email]],
        phone: [this.currentUser.phone || '', [Validators.required]]
      })
    });

    if (this.showFaculty) {
      const userGroup = this.form.get('user') as FormGroup;

      userGroup.addControl('faculty', this.fb.control(this.currentUser.facultyName || this.currentUser.facultyCode || '', [Validators.required]));
    }

    this.userSectionValidity.emit({
      valid: this.form.valid,
      user: this.form.get('user').value
    });

    this.userFormSubscription = this.form
      .valueChanges
      .subscribe(val => {
        this.userSectionValidity.emit({
          valid: this.form.valid,
          user: this.form.get('user').value
        });
      });
  }

}
