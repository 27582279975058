import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { NewsItemModel } from "../news-item.model";
import { AppCommunicationService } from "../../../../services/app-communication.service";
import { LibtukeNewsService } from "../libtuke-news.service";
import { first } from "rxjs/operators";
import { ConfirmationService } from "primeng";
import { QueryDesignerOrderBy, queryResultOrder } from "../../../../../core-frontend-common/misc/query-designer/query-designer";

@Component({
  selector: 'smart-list-news',
  templateUrl: './list-news.component.html',
  styleUrls: ['./list-news.component.scss']
})
export class ListNewsComponent implements OnInit {
  table = {
    columns: [],
    page: 1,
    pageSize: 30,
    totalItems: 0
  };

  data: NewsItemModel[] = [];

  @Output() editItemEvent: EventEmitter<NewsItemModel> = new EventEmitter<NewsItemModel>();

  constructor(
    private appCommunication: AppCommunicationService,
    private newsService: LibtukeNewsService,
    private confirmation: ConfirmationService
  ) {
  }

  ngOnInit() {
  }

  setPage(pageInfo) {
    this.table.page = (pageInfo.first + pageInfo.rows) / this.table.pageSize;
    this.loadData();
  }

  loadData() {
    this.appCommunication.loadingOn('Načítavam dáta.');

    const orderBy = new QueryDesignerOrderBy('createdDatetime', queryResultOrder.DESC);

    const queryDesigner = {
      page: this.table.page,
      pageSize: this.table.pageSize,
      orderBy: [
        orderBy
      ]
    };

    this.newsService
      .getAllByFilterAdmin(queryDesigner)
      .subscribe(v => {
        this.data = v.items;

        // sort by createdDatetime, newest first
        this.data.sort((a, b) => {
          if (a.createdDatetime > b.createdDatetime) {
            return -1;
          }
          if (a.createdDatetime < b.createdDatetime) {
            return 1;
          }
          return 0;
        });
        this.table.totalItems = v.totalItems;
        this.appCommunication.loadingOff();
      }, err => {
        console.log(err);
        this.appCommunication.loadingOff();
      });
  }

  openDetail(item: NewsItemModel) {
    this.editItemEvent.emit(item);
  }

  showDeleteConfirmation(item: NewsItemModel) {
    this.confirmation
      .confirm({
        message: 'Skutočne si prajete odstrániť záznam?',
        acceptLabel: 'Áno',
        rejectLabel: 'Nie',
        accept: () => this.deleteNews(item)
      });
  }

  deleteNews(item: NewsItemModel) {
    this.newsService
      .delete(item.id)
      .pipe(first())
      .subscribe({
        next: () => this.loadData()
      });
  }
}
