import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { FormStatusEnum } from '../constants';

@Directive({
  selector: '[requestState]'
})
export class RequestStateDirective implements OnInit {

  @Input() status: FormStatusEnum;

  constructor(private renderer: Renderer2, protected hostElement: ElementRef) {
  }

  ngOnInit() {
    this.applyClass();
  }

  @HostListener('change')
  applyClass() {
    this.renderer.addClass(this.hostElement.nativeElement, 'badge');
    this.renderer.addClass(this.hostElement.nativeElement, 'badge-default');

    switch (this.status) {
      case FormStatusEnum.CREATED:
        this.renderer.addClass(this.hostElement.nativeElement, 'bg-color-detail');
        return;
      case FormStatusEnum.IN_PROGRESS:
        this.renderer.addClass(this.hostElement.nativeElement, 'bg-color-download');
        return;
      case FormStatusEnum.FINISHED:
        this.renderer.addClass(this.hostElement.nativeElement, 'bg-color-success');
        return;
    }
  }


}
