import { NgModule } from '@angular/core';
import { AuthorSearchComponent } from './author-search.component';
import { NewAuthorModalFormComponent } from '../new-author-modal-form/new-author-modal-form.component';
import { CommonModule } from '@angular/common';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap';
import { TooltipModule } from 'primeng';
import { I18nModule } from '../../../../../core-frontend-common/template/shared/i18n/i18n.module';

@NgModule({
  imports: [
    CommonModule,
    AutoCompleteModule,
    FormsModule,
    I18nModule,
    ModalModule,
    ReactiveFormsModule,
    TooltipModule
  ],
  declarations: [
    AuthorSearchComponent,
    NewAuthorModalFormComponent
  ],
  exports: [
    AuthorSearchComponent
  ]
})

export class AuthorSearchModule {
}
