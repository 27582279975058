import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDatepicker } from '@angular/material';

import * as _moment from 'moment';
import { Moment } from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

const moment = _moment;

export const YEAR_MODE_FORMATS = {
  parse: {
    dateInput: 'YYYY'
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'smart-custom-year-picker',
  templateUrl: './custom-year-picker.component.html',
  styleUrls: ['./custom-year-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: YEAR_MODE_FORMATS },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomYearPickerComponent),
      multi: true
    }
  ]
})
export class CustomYearPickerComponent implements ControlValueAccessor, OnChanges {
  @Input() get max(): number | Date {
    return this._max ? this._max.year() : undefined;
  }

  set max(max: number | Date) {
    if (max) {
      const momentDate = typeof max === 'number' ? moment([max, 0, 1]) : moment(max);
      this._max = momentDate.isValid() ? momentDate : undefined;
    }
  }
  @Input() get min(): number | Date {
    return this._min ? this._min.year() : undefined;
  }

  set min(min: number | Date) {
    if (min) {
      const momentDate = typeof min === 'number' ? moment([min, 0, 1]) : moment(min);
      this._min = momentDate.isValid() ? momentDate : undefined;
    }
  }
  /** Component label */
  @Input() label = '';

  @Input() disabled = false;

  _max: Moment;

  _min: Moment;

  @Input() touchUi = false;

  @ViewChild(MatDatepicker, {static: false}) _picker: MatDatepicker<Moment>;

  _inputCtrl: FormControl = new FormControl();

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.disabled && (changes.disabled.currentValue !== undefined)) {
      this.setDisabledState(changes.disabled.currentValue);
    }
  }

  // Function to call when the date changes.
  onChange = (year: Date) => {
  }

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {
  }

  writeValue(date: Date): void {
    if (date && this._isYearEnabled(date.getFullYear())) {
      const momentDate = moment(date);
      if (momentDate.isValid()) {
        this._inputCtrl.setValue(moment(date), { emitEvent: false });
      }
    } else {
      this._inputCtrl.setValue(date, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    if (!this._picker || !this._inputCtrl) { return; }

    isDisabled ? this._picker.disabled = true : this._picker.disabled = false;

    isDisabled ? this._inputCtrl.disable() : this._inputCtrl.enable();
  }

  _yearSelectedHandler(chosenDate: Moment, datepicker: MatDatepicker<Moment>) {
    if (!this._isYearEnabled(chosenDate.year())) {
      datepicker.close();
      return;
    }

    this._inputCtrl.setValue(chosenDate, { emitEvent: false });
    this.onChange(chosenDate.toDate());
    this.onTouched();
    datepicker.close();
  }

  _openDatepickerOnClick(datepicker: MatDatepicker<Moment>) {
    if (!datepicker.opened) {
      datepicker.open();
    }
  }

  _openDatepickerOnFocus(datepicker: MatDatepicker<Moment>) {
    setTimeout(() => {
      if (!datepicker.opened) {
        datepicker.open();
      }
    });
  }

  removeValue(e: Event) {
    e.preventDefault();
    this._inputCtrl.setValue('', { emitEvent: false });
    this.onChange(null)
    this.onTouched();
  }

  /** Whether the given year is enabled. */
  private _isYearEnabled(year: number) {
    // disable if the year is greater than maxDate lower than minDate
    if (year === undefined || year === null ||
      (this._max && year > this._max.year()) ||
      (this._min && year < this._min.year())) {
      return false;
    }

    return true;
  }


}
