// Sem patria globalne konstanty

import { FormType } from './views/forms-view/form-types.enum';

export const SYSTEM_NAME_AUTH = 'AUTH';
export const SYSTEM_NAME_EPC = 'EPC';
export const SYSTEM_NAME_ETD = 'ETD';
export const SYSTEM_NAME_ISBN = 'ISBN';
export const SYSTEM_NAME_ROOMBOOK = 'ROOMBOOK';
export const SYSTEM_NAME_ZP = 'ZP';
export const SYSTEM_NAME_UIK = 'UIK';
export const SYSTEM_NAME_HELPDESK = 'HELPDESK';
export const SYSTEM_NAME_TOOLS = 'TOOLS';
export const SYSTEM_NAME_LIBADMIN = 'LIBADMIN';
export const SYSTEM_NAME_WAREHOUSE = 'WAREHOUSE';
export const SYSTEM_NAME_ALFRED = 'ALFRED';
export const SYSTEM_NAME_EBOOK = 'EBOOK';
export const SYSTEM_NAME_ONLINE_CATALOG = 'ONLINECATALOG';
export const SYSTEM_NAME_HELIOS = 'HELIOS';


export const SYSTEM_FULL_NAME_ETD = 'thesisEvidence';
export const SYSTEM_FULL_NAME_ROOMBOOK = 'roombook';
export const SYSTEM_FULL_NAME_WAREHOUSE = 'warehouse';
export const SYSTEM_FULL_NAME_ALFRED = 'Alfred';
export const SYSTEM_FULL_NAME_LIBADMIN = 'libadmin';

export const ALL_SYSTEMS = [
  SYSTEM_NAME_EPC,
  SYSTEM_NAME_ETD,
  SYSTEM_NAME_ISBN,
  SYSTEM_NAME_ROOMBOOK,
  SYSTEM_NAME_UIK,
  SYSTEM_NAME_ALFRED
];

export const FAQ_SYSTEMS = [
  SYSTEM_NAME_EPC,
  SYSTEM_NAME_ETD,
  SYSTEM_NAME_ISBN,
  SYSTEM_NAME_EBOOK,
  SYSTEM_NAME_ONLINE_CATALOG,
  SYSTEM_NAME_ALFRED
];


/*
 * USER ROLES
 *
 *
 *
 */
const USER_ROLES = {
  SUPERADMIN: 'SUPERADMIN',
  ALFRED_WAREHOUSE: 'ALFRED_WAREHOUSE',
  ALFRED_BORROW: 'ALFRED_BORROW',
  ALFRED_DIGI: 'ALFRED_DIGI',
  ALFRED_STUDENT: 'ALFRED_STUDENT',
  ALFRED_PEDAGOGUE: 'ALFRED_PEDAGOGUE',
  ALFRED_ADMIN: 'ALFRED_ADMIN'
} as const;

export const UserRoles = {
  All: [
    USER_ROLES.SUPERADMIN,
    USER_ROLES.ALFRED_WAREHOUSE,
    USER_ROLES.ALFRED_BORROW,
    USER_ROLES.ALFRED_DIGI,
    USER_ROLES.ALFRED_STUDENT,
    USER_ROLES.ALFRED_PEDAGOGUE,
    USER_ROLES.ALFRED_ADMIN
  ],
  Superadmin: USER_ROLES.SUPERADMIN,
  AlfredWarehouse: USER_ROLES.ALFRED_WAREHOUSE,
  AlfredBorrow: USER_ROLES.ALFRED_BORROW,
  AlfredDigi: USER_ROLES.ALFRED_DIGI,
  AlfredStudent: USER_ROLES.ALFRED_STUDENT,
  AlfredPedagogue: USER_ROLES.ALFRED_PEDAGOGUE,
  AlfredAdmin: USER_ROLES.ALFRED_ADMIN
} as const;

export const skLocalizationCalendar = {
  firstDayOfWeek: 1,
  dayNames: ['Nedeľa', 'Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota'],
  dayNamesShort: ['Ne', 'Po', 'Ut', 'St', 'Št', 'Pi', 'So'],
  dayNamesMin: ['Ne', 'Po', 'Ut', 'St', 'Št', 'Pi', 'So'],
  monthNames: [
    'Január',
    'Február',
    'Marec',
    'Apríl',
    'Máj',
    'Jún',
    'Júl',
    'August',
    'September',
    'Október',
    'November',
    'December',
  ],
  monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Máj', 'Jún', 'Júl', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
  today: 'dnes',
  clear: 'Zmazať',
};

export const COLORS = {
  blueLight: '#92a2a8',
  blue: '#57889c',
  teal: '#568a89',
  blueDark: '#4c4f53',
  green: '#356e35',
  greenDark: '#496949',
  greenLight: '#71843f',
  purple: '#6e587a',
  magenta: '#6e3671',
  pink: '#ac5287',
  pinkDark: '#a8829f',
  yellow: '#b09b5b',
  orange: '#c79121',
  red: '#a90329',
  redLight: '#a65858'
};


export class WarehouseSections {
    public static readonly ALL_SECTION = 0;
    public static readonly SECTION_1 = 1;
    public static readonly SECTION_2 = 2;
    public static readonly SECTION_3 = 3;
    public static readonly SECTION_4 = 4;
    public static readonly SECTION_5 = 5;
    public static readonly SECTION_6 = 6;
    public static readonly SECTION_7 = 7;
    public static readonly SECTION_8 = 8;
}

export enum ReaderCardCategoryEnum {
  student = 0,
  employeer = 1
}

export const ReaderCardCategories = [
  {
    value: ReaderCardCategoryEnum.student,
    title: 'student'
  },
  {
    value: ReaderCardCategoryEnum.employeer,
    title: 'employeer'
  }
];

export enum FormStatusEnum {
  CREATED = 1,
  IN_PROGRESS = 2,
  FINISHED = 3
}

export const FormStatuses = [
  {
    value: FormStatusEnum.CREATED,
    title: 'Podaná',
    visible: [FormType.mvsBook, FormType.mvsJournal, FormType.seminar, FormType.search, FormType.buyDocument]
  },
  {
    value: FormStatusEnum.IN_PROGRESS,
    title: 'Spracovávaná',
    visible: [FormType.mvsBook, FormType.mvsJournal, FormType.search, FormType.seminar]
  },
  {
    value: FormStatusEnum.FINISHED,
    title: 'Dokončená',
    visible: [FormType.mvsBook, FormType.mvsJournal, FormType.seminar, FormType.search, FormType.buyDocument]
  }
];

export const PERSON_TYPES_USER_REG = [
  {
    code: 'P',
    name: 'PERSON_PEDAGOG'
  },
  {
    code: 'N',
    name: 'PERSON_NOT_PEDAGOG'
  },
  {
    code: 'U',
    name: 'PERSON_TO_DEAL'
  },
  {
    code: 'Z',
    name: 'PERSON_TO_DEAL_NOT_PEDAGOG'
  },
  {
    code: 'D',
    name: 'PERSON_DAILY_PHD'
  },
  {
    code: 'E',
    name: 'PERSON_EXTERNAL_PHD'
  },
  {
    code: 'S',
    name: 'PERSON_DAILY_STUDENT'
  },
  {
    code: 'X',
    name: 'PERSON_EXTERNAL_STUDENT'
  },
  {
    code: 'M',
    name: 'PERSON_SPECIAL_STUDENT'
  }
];
