import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SeminarFormTypes, Seminars, TukeRelationShips } from './seminars';
import { OVI_SUPPORT } from '../../../../app-constants';
import { BaseForm } from '../../base-form';
import { FormType } from '../../form-types.enum';
import { FormSeminarRequest } from '../../../../models/forms/FormSeminarRequest';
import { FormsService } from '../../../../services/forms.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-education-form',
  templateUrl: './education-form.component.html',
  styleUrls: ['./education-form.component.scss']
})
export class EducationFormComponent extends BaseForm implements OnInit {
  form: FormGroup;

  seminars = Seminars;

  oviSupport = OVI_SUPPORT;

  formSeminar: FormSeminarRequest;

  tukeRelationShips = TukeRelationShips;

  seminarFormTypes = SeminarFormTypes;

  constructor(
    protected fb: FormBuilder,
    protected formService: FormsService,
    protected toastr: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      book: this.fb.group({
        tukeRelationshipCode: ['', [Validators.required]],
        typeCode: ['', [Validators.required]],
        categoryCode: ['', [Validators.required]],
        participantCount: ['', [Validators.required]],
        date: ['', [Validators.required]],
        time: ['', [Validators.required]]
      })
    });
  }

  async submit(e: Event) {
    e.preventDefault();

    this.formSeminar = new FormSeminarRequest();
    this.formSeminar = this.fullFillUserForm(this.formSeminar);
    this.formSeminar = this.fullFillDataFromForm(this.formSeminar, this.form.get('book').value);
    this.formSeminar.eventDatetime = new Date();
    const date = this.form.get('book').get('date').value as Date;
    const time = this.form.get('book').get('time').value as Date;
    this.formSeminar.eventDatetime = moment(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${time.getHours() + 1}:${time.getMinutes()}:${time.getSeconds()}`, 'YYYY-MM-DD HH:mm:ss').toDate();
    delete this.formSeminar['date'];
    delete this.formSeminar['time'];

    const req = await this.formService
      .create(FormType.seminar, this.formSeminar).toPromise();

    if (req) {
      this.toastr.success('Záznam úspešne uložený.');
      this.form.reset();
      return;
    }

    this.toastr.error('Záznam sa uložiť nepodarilo.');
  }

  downloadForm() {
    console.log('download form');
  }
}
