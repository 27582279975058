import { Injectable } from '@angular/core';
import { PRODUCTION_BUILD, SYSTEM_NAME } from '../app.settings';
import { BehaviorSubject, Subject } from 'rxjs';

import * as CryptoJS from 'crypto-js';
import { AuthenticationService } from 'src/app/core-frontend-common/auth/authentication.service';

@Injectable()
export class AppCommunicationService {
  loadingAnimation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingAnimationText: Subject<string> = new Subject<string>();

  static decryptData(data) {
    try {
      return CryptoJS.AES.decrypt(data, CryptoJS.enc.Hex.parse('7061737323313233'), {
        keySize: 128 / 8,
        iv: CryptoJS.enc.Hex.parse('7061737323313233'),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }).toString(CryptoJS.enc.Utf8);
    } catch (e) {
      if (!PRODUCTION_BUILD) {
        console.error(e);
      }
    }
  }

  static getCurrentUser() {
    return AuthenticationService.getCurrentUser();
  }

  constructor() {}

  loadingOn(text = null) {
    setTimeout(() => {
      if (text) {
        this.loadingAnimationText.next(text);
      }
      this.loadingAnimation.next(true);
    }, 20);
  }

  loadingOff() {
    this.loadingAnimation.next(false);
    this.loadingAnimationText.next(null);
  }
}
