export class PortfolioItemModel {
  id?: number;
  title: string;
  type: string;
  headlines: PortfolioHeadline[] = [];
  priceTitle = 'Cena produktu';
  priceAsString: string;
  priceDescriptions: string;
  images?: PortolioImage[] = [];

  titleEn: string;
  typeEn: string;
  priceTitleEn = 'Product price';
  priceAsStringEn: string;
}

export class PortolioImage {
  url: string;
  type?: string = 'image';
  isMain: boolean;
  id: number;
  orderNumber: number;
}

export class PortfolioHeadline {
  title = '';
  description = '';
  portfolioId?: number;

  titleEn: string;
  descriptionEn: string;
}
