import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'smart-articles-management-view',
  templateUrl: './articles-management-view.component.html',
  styleUrls: ['./articles-management-view.component.scss']
})
export class ArticlesManagementViewComponent implements OnInit {
  TABS = {
    list: 'list',
    create: 'create'
  };

  activeTab = this.TABS.list;

  newArticle: string;

  constructor() { }

  ngOnInit() {
  }

}
