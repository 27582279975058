import { Component, OnInit } from '@angular/core';
import { UserModel } from '../../models/user.model';
import { OverlayService } from '../../services/overlay.service';
import { NotificationModel } from '../../components/notification/notification.model';
import { TileModel } from '../../components/tile/tile.model';
import { AppCommunicationService } from '../../services/app-communication.service';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  user: UserModel;
  notifications: NotificationModel[] = [];
  quickActions: TileModel[] = [];

  constructor(
    protected overlayService: OverlayService,
    protected generalService: GeneralService
  ) {
  }

  ngOnInit() {
    this.loadUser();

    this.loadNotifications();

    this.generateQuickActions();
  }

  loadUser() {
    this.user = AppCommunicationService.getCurrentUser().user;
  }

  loadNotifications() {
    this.generalService
      .getNotifications()
      .subscribe(val => this.notifications = val);
  }

  generateQuickActions() {
    this.quickActions.push(
      {
        id: 1,
        title: 'Sklad',
        route: '/warehouse',
        roles: ['SUPERADMIN', 'ALFRED_WAREHOUSE'],
        imageUrl: '/assets/img/custom/registration.png'
      },
      {
        id: 2,
        title: 'Digit. kníh',
        route: '/ebooks',
        roles: ['SUPERADMIN', 'ALFRED_DIGI'],
        imageUrl: '/assets/img/custom/myThesis.png'
      }
    );
  }


}
