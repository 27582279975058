import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DigitalBookAuthorModel } from '../../../models/digital-book-author.model';
import { DigitalBooksService } from '../../../services/digital-books.service';
import { DigitalBookModel } from '../../../models/digital-book.model';
import { flatMap } from 'rxjs/operators';

@Component({
  selector: 'smart-digital-books-form',
  templateUrl: './digital-books-form.component.html',
  styleUrls: ['./digital-books-form.component.scss'],
})
export class DigitalBooksFormComponent implements OnInit {
  file: File;

  form: FormGroup;

  authors: DigitalBookAuthorModel[] = [];

  isFormValid = false;

  isLoaded = false;

  constructor(
    protected notification: ToastrService,
    protected formBuilder: FormBuilder,
    protected digitalBooksService: DigitalBooksService,
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      title: ['', [Validators.required]],
      subtitle: [''],
      otherIdentifier: [''],
      yearOfPublishing: [''],
      comment: [''],
      isbn: [''],
      issn: [''],
      doi: [''],
    });

    this.form
      .valueChanges
      .subscribe(() => this.checkFormValidity());

    this.addEmptyAuthor();

    this.isLoaded = true;
  }

  onSelect(event) {
    if (event && event.addedFiles && event.addedFiles.length > 0) {
      this.file = event.addedFiles[0];
    }

    this.checkFormValidity();
  }

  onRemove() {
    this.file = null;
  }

  addEmptyAuthor() {
    this.authors.push(DigitalBookAuthorModel.createEmptyAuthor());
  }

  onSubmitAuthor(author: DigitalBookAuthorModel, index: number) {
    this.authors[index] = author;

    this.addEmptyAuthor();

    this.checkFormValidity();
  }

  deleteAuthor(index: number) {
    this.authors.splice(index, 1);
  }

  checkFormValidity() {
    if (this.authors.length > 1 && this.form.valid && this.file) {
      this.isFormValid = true;
      return;
    }
    this.isFormValid = false;
    return;
  }

  onSubmit() {
    let digitalBook = new DigitalBookModel();
    Object.assign(digitalBook, this.form.value);
    if (this.authors && this.authors.length > 1) {
      this.authors.forEach(author => {
        if (author && (author.id && author.id !== 0)) {
          if (!digitalBook.authorIds) {
            digitalBook.authorIds = [];
          }
          digitalBook.authorIds.push(author.id);
        }
      });

      this.digitalBooksService
        .createDigitalBook(digitalBook)
        .pipe(flatMap(book => {
          digitalBook = book;
          return this.digitalBooksService.uploadFile(book.id, this.file);
        }))
        .subscribe(uploaded => {
          if (uploaded) {
            this.resetForm();
            this.notification.success('Kniha bola úspešne nahraná do systému.', 'Úspech');
          }
        }, err => {
          this.notification.error('Nastala chyba - ' + err, 'Chyba');
        });
    }
  }

  resetForm() {
    this.form.reset(this.form.value);
    this.authors = [];
    this.addEmptyAuthor();
    this.file = null;
  }

}
