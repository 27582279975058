import { AfterViewInit, Component, forwardRef, Input, OnDestroy, ViewEncapsulation } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "smart-custom-datepicker",
  templateUrl: "./custom-datepicker.component.html",
  styleUrls: ["./custom-datepicker.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomDatepickerComponent),
      multi: true
    }
  ]
})
export class CustomDatepickerComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {

  @Input() mode: "YEAR" | "MONTH" | "MONTHYEAR" | "WEEK" | "" | null;

  @Input() label = "";

  @Input() max: any;

  @Input() min: any;

  @Input() touchUi = false;

  @Input() disabled = false;

  yearPickerCtrl: FormControl = new FormControl();
  monthPickerCtrl: FormControl = new FormControl();
  regularPickerCtrl: FormControl = new FormControl();

  private onDestroy: Subject<void> = new Subject<void>();

  constructor() {
  }

  ngAfterViewInit() {

    switch (this.mode) {
      case "YEAR":
        this._subscribeToChanges(this.yearPickerCtrl);
        break;

      case "MONTH":
      case "MONTHYEAR":
        this._subscribeToChanges(this.monthPickerCtrl);
        break;

      default:
        this._subscribeToChanges(this.regularPickerCtrl);

    }

  }

  ngOnChanges() {
  }

  // Function to call when the date changes.
  onChange = (date: any) => {
  };

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {
  };

  private _subscribeToChanges(control: FormControl) {
    if (!control) {
      return;
    }

    control
      .valueChanges
      .pipe(
        takeUntil(this.onDestroy)
      ).subscribe((value) => {
      if (value) {
        const date = new Date(value);
        this.onChange(date);
      } else {
        this.onChange(null);
      }
      this.onTouched();
    });

  }

  ngOnDestroy(): void {
  }

  writeValue(date: any): void {
    // if (date) {
    this._writeValue(date);
    // }
    //
    // if (!date) {
    //   this.resetValue();
    // }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  resetValue() {
    switch (this.mode) {
      case "YEAR":
        const validators = this.yearPickerCtrl.validator;
        this.yearPickerCtrl.reset();
        // this.yearPickerCtrl.setValidators(validators);
        break;

      case "MONTHYEAR":
      case "MONTH":
      default:
        this.regularPickerCtrl.setValue("");
    }
  }

  private _writeValue(date: any): any {

    switch (this.mode) {
      case "YEAR":
        // if (date instanceof Date) {
        this.yearPickerCtrl.setValue(date, { emitEvent: false });
        // }
        break;

      case "MONTHYEAR":
      case "MONTH":
      default:
        if (date instanceof Date) {
          this.regularPickerCtrl.setValue(date);
        }
    }
  }

  private _parseMonthAndYear(date: string) {
    const dashIndex = date.indexOf("-");
    const forwardSlashIndex = date.indexOf("/");
    if (dashIndex === -1 && forwardSlashIndex === -1) {
      return;
    }

    if (forwardSlashIndex > -1) {
      return this._getValidStringMonth(date.split("/"));
    }

    if (dashIndex > -1) {
      return this._getValidStringMonth(date.split("-"));
    }
  }

  private _parseYear(date: string) {
    const month = Number(date);
    if (isNaN(month)) {
      return;
    }

    return month;
  }

  private _getValidStringMonth(splittedDate: string[]) {
    if (splittedDate.length !== 2) {
      return;
    }

    const month = Number(splittedDate[0]);

    if (isNaN(month) || month > 11 || month < 0) {
      return;
    }

    const year = Number(splittedDate[1]);

    if (isNaN(year) || year < 0) {
      return;
    }

    // Notice we are converting month to human convention, 1..12.
    // This is done because of the way month-picker is building up
    // the moment object using MM/YYYY format instead of and array.
    return (month + 1) + "/" + year;
  }

  get _showMonthPicker(): boolean {
    return this.mode === "MONTH" || this.mode === "MONTHYEAR";
  }

  get _showRegularDatepicker(): boolean {
    return !this.mode || this.mode === "WEEK";
  }
}
