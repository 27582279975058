export class NewsItemModel {
  id?: number;
  title: string;
  description: string;
  fileUrl: string;
  createdDatetime: Date;

  titleEn: string;
  descriptionEn: string;
}
