import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppCommunicationService } from './app-communication.service';
import { AuthenticationService } from '../../core-frontend-common/auth/authentication.service';
import { Router } from '@angular/router';
import { getAllNotificationsForSystem, getDepartmentCodebookUrl, getFacultyCodebookUrl } from '../app.urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { SYSTEM_NAME } from '../app.settings';
import { NotificationModel } from '../components/notification/notification.model';
import { ThesisStatusModel } from '../models/thesis-status.model';
import { ThesisTypeModel } from '../models/thesis-type.model';

@Injectable()
export class GeneralService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
  }

  getFaculties(): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    if (this.authenticationService.checkUser()) {
      return this.http.get(getFacultyCodebookUrl(), httpOptions).pipe(map((res: any) => res.items));
    }
  }

  getDepartments(facultyId: number): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    if (this.authenticationService.checkUser()) {
      return this.http.get(getDepartmentCodebookUrl(facultyId), httpOptions).pipe(map((res: any) => res.items));
    }
  }

  getNotifications(): Observable<NotificationModel[]> {
    return this.http
      .post(getAllNotificationsForSystem(false, SYSTEM_NAME), {})
      .pipe(map((res: any) => res.items));
  }

  getListTypes(): Observable<ThesisTypeModel[]> {
    return this.http
      .get('/assets/api/thesis/thesisTypes.json')
      .pipe() as Observable<ThesisTypeModel[]>;
  }

  getListStatus(): Observable<ThesisStatusModel[]> {
    return this.http
      .get('assets/api/thesis/thesisStatus.json')
      .pipe() as Observable<ThesisStatusModel[]>;
  }
}
