import {Component, OnInit} from '@angular/core';
import { FormsService } from '../../../../services/forms.service';
import { FormType } from '../../form-types.enum';
import { AppCommunicationService } from '../../../../services/app-communication.service';
import { FormFeedbackRequest } from '../../../../models/forms/FormFeedbackRequest';
import { TukeRelationShips } from '../education-form/seminars';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent implements OnInit {
  tukeRelationShips = TukeRelationShips;

  tukeRelationship = TukeRelationShips[0].code;

  message = '';

  constructor(
    protected formService: FormsService,
    protected toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
  }

  async submit(e: Event) {
    e.preventDefault();
    if (this.message.length <= 2 || !this.tukeRelationship) {
      return;
    }

    const form = new FormFeedbackRequest();
    const user = AppCommunicationService.getCurrentUser().user;
    form.email = user.email || '';
    form.fullName = `${user.name} ${user.surname}`;
    form.comment = this.message;
    form.tukeRelationshipCode = this.tukeRelationship;

    const req = await this.formService
      .create(FormType.feedback, form).toPromise();

    if (req) {
      this.toastr.success('Záznam úspešne uložený.');
      this.tukeRelationship = this.tukeRelationShips[0].code;
      this.message = '';
      return;
    }

    this.toastr.error('Záznam sa uložiť nepodarilo.');
  }

}
