import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseForm } from '../../base-form';
import { FormSearchRequest } from '../../../../models/forms/FormSearchRequest';
import { FormType } from '../../form-types.enum';
import { FormsService } from '../../../../services/forms.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent extends BaseForm implements OnInit {
  form: FormGroup;

  formSearch: FormSearchRequest;

  languages = [
    {
      value: 'slovenský'
    },
    {
      value: 'anglický'
    },
    {
      value: 'nemecký'
    },
    {
      value: 'český'
    },
    {
      value: 'iba slovenský a český'
    },
    {
      value: 'neobmedzený'
    }
  ];

  selectedLanguage = this.languages[0];

  constructor(
    protected fb: FormBuilder,
    protected formService: FormsService,
    protected toastr: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      book: this.fb.group({
        description: ['', [Validators.required]],
        keywordsSk: ['', [Validators.required]],
        keywordsEn: ['', [Validators.required]],
        yearRange: ['', [Validators.required]],
        yearFrom: ['', [Validators.required]],
        yearTo: ['', [Validators.required]],
        documentLanguage: ['', [Validators.required]],
        message: ['']
      })
    });

    this.selectedLanguageChange();
  }

  yearRangeChanged() {
    const yearFrom = this.form.get('book').get('yearFrom').value;
    const yearTo = this.form.get('book').get('yearTo').value;

    if (yearFrom && yearTo) {
      const from = new Date(yearFrom).getFullYear();
      const to = new Date(yearTo).getFullYear();
      this.form.get('book').get('yearRange').patchValue(`${from}-${to}`);
    } else {
      this.form.get('book').get('yearRange').reset();
    }
  }

  selectedLanguageChange() {
    if (this.selectedLanguage) {
      this.form.get('book').get('documentLanguage').patchValue(this.selectedLanguage.value);
    }
  }

  async submit(e: Event) {
    e.preventDefault();

    const formData = this.form.get('book').value;
    delete formData.yearFrom;
    delete formData.yearTo;

    if (formData['keywordsSk']) {
      formData['keywordsSk'] = (formData['keywordsSk'] as string[]).join(',');
    }

    if (formData['keywordsEn']) {
      formData['keywordsEn'] = (formData['keywordsEn'] as string[]).join(',');
    }

    this.formSearch = new FormSearchRequest();
    this.formSearch = this.fullFillUserForm(this.formSearch);
    this.formSearch = this.fullFillDataFromForm(this.formSearch, formData);

    const req = await this.formService
      .create(FormType.search, this.formSearch).toPromise();

    if (req) {
      this.toastr.success('Záznam úspešne uložený.');
      this.form.reset();
      return;
    }

    this.toastr.error('Záznam sa uložiť nepodarilo.');
  }
}
