import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuyDocumentRequest } from '../models/forms/FormBuyDocumentRequest';
import { FormMvsBookRequest } from '../models/forms/FormMvsBookRequest';
import { FormMvsJournalRequest } from '../models/forms/FormMvsJournalRequest';
import { FormSearchRequest } from '../models/forms/FormSearchRequest';
import { FormSeminarRequest } from '../models/forms/FormSeminarRequest';
import { Observable } from 'rxjs';
import { FormType } from '../views/forms-view/form-types.enum';
import {
  getAllByFilterFormUrl,
  getByIdFormUrl,
  getCreateFormUrl,
  getSetStatusFormUrl,
  getUpdateFormUrl
} from '../app.urls';
import { map } from 'rxjs/operators';
import { FormFeedbackRequest } from '../models/forms/FormFeedbackRequest';
import { FormEpcBiblioCorrectionRequest } from '../models/forms/FormEpcBiblioCorrectionRequest';
import { AppCommunicationService } from './app-communication.service';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor(
    protected http: HttpClient
  ) {
  }

  create(formType: FormType, object: FormBuyDocumentRequest | FormMvsBookRequest | FormMvsJournalRequest | FormSearchRequest | FormSeminarRequest | FormFeedbackRequest | FormEpcBiblioCorrectionRequest): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post(getCreateFormUrl(formType), object, httpOptions)
      .pipe(map(res => res));
  }

  update(formType: FormType, object: FormBuyDocumentRequest | FormMvsBookRequest | FormMvsJournalRequest | FormSearchRequest | FormSeminarRequest | FormFeedbackRequest | FormEpcBiblioCorrectionRequest): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post(getUpdateFormUrl(formType), object, httpOptions)
      .pipe(map(res => res));
  }

  getById(formType: FormType, object: FormBuyDocumentRequest | FormMvsBookRequest | FormMvsJournalRequest | FormSearchRequest | FormSeminarRequest | FormFeedbackRequest | FormEpcBiblioCorrectionRequest): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post(getByIdFormUrl(formType, object.id), object, httpOptions)
      .pipe(map(res => res));
  }

  setStatus(formType: FormType, object: FormBuyDocumentRequest | FormMvsBookRequest | FormMvsJournalRequest | FormSearchRequest | FormSeminarRequest | FormFeedbackRequest | FormEpcBiblioCorrectionRequest, status: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .get(getSetStatusFormUrl(formType, object.id, status), httpOptions)
      .pipe(map(res => res));
  }

  getAllByFilter(formType: FormType, filter: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post(getAllByFilterFormUrl(formType), filter, httpOptions)
      .pipe(map(res => res));
  }
}
