import { BaseFormEntityRequest } from './BaseFormEntityRequest';

export class FormMvsJournalRequest extends BaseFormEntityRequest {
  author: string;
  journalTitle: string;
  yearOfPublishing?: number;
  volume: string;
  issue: string;
  issn?: string;
  articleTitle: string;
  pageRange: string;
  validUntilDatetime?: Date;
  id?: number;
}
