import { Component, OnInit, ViewChild } from '@angular/core';
import { AddNewsComponent } from './add-news/add-news.component';
import { NewsItemModel } from './news-item.model';

@Component({
  selector: 'smart-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  TABS = {
    addNews: 'addNews',
    listNews: 'listNews'
  };

  currentTab = this.TABS.addNews;

  @ViewChild(AddNewsComponent, {static: false}) addNewsComponent: AddNewsComponent;

  constructor() {
  }

  ngOnInit() {
  }

  editItem(item: NewsItemModel) {
    this.currentTab = this.TABS.addNews;

    setTimeout(() => {
      this.addNewsComponent.editMode(item);
    }, 100);
  }
}
