export class DigitalBookAuthorModel {
  id?: number;
  name: string;
  surname: string;

  static createEmptyAuthor(): DigitalBookAuthorModel {
    const a = new DigitalBookAuthorModel();
    a.name = '';
    a.surname = '';
    return a;
  }
}
