import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'smart-payment-refund',
  templateUrl: './payment-refund.component.html',
  styleUrls: ['./payment-refund.component.scss']
})
export class PaymentRefundComponent implements OnInit {

  visible = false;

  paymentItem: any;

  refundAmount: number;

  constructor() { }

  ngOnInit() {
  }

  show(
    paymentItem: any
  ) {
    this.visible = true;
    this.paymentItem = paymentItem;
    this.refundAmount = paymentItem.paymentAmount;
  }

  close() {
    this.visible = false;
  }

  refund() {
    this.close();
  }

}
