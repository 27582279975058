import { NgModule } from '@angular/core';
import { ReaderCardFormComponent } from './reader-card-form.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { I18nModule } from '../../../../core-frontend-common/template/shared/i18n/i18n.module';
import { DropdownModule } from 'primeng';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    I18nModule,
    DropdownModule
  ],
  exports: [
    ReaderCardFormComponent
  ],
  declarations: [
    ReaderCardFormComponent
  ]
})

export class ReaderCardFormModule {}
