export class ReaderCardFullModel {
  id: number;
  identify: string;
  bornid?: number;
  barcode: string;
  firstname: string;
  lastname: string;
  identifyCard: string;
  sign: string;
  address1street: string;
  address1psc: string;
  address1town: string;
  address2street: string;
  address2psc: string;
  address2town: string;
  category?: number;
  created: Date;
  registration: Date;
  registered: Date;
  nextVisit: Date;
  autoVisit: boolean;
  userSign: string;
  // Vklada sa nazov fakulty - nazov katedry ako text
  employeer: string;
  nationality: string;
  phone: string;
  email: string;
  switches: number;
  borndate: Date;
  parentName: string;
  location: string;
  remark: string;
}
