import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppCommunicationService } from './app-communication.service';
import {
  getAllCardsByFilterUrl, getCardByCardNumberAdminUrl,
  getCardByCardNumberUrl,
  getCreateCardUrl, getCurrentUserCardNumberUrl,
  getExpiredCardByIdUrl,
  getRenewCardByIdUrl, getSearchUvtUserUrl, getUpdateCardAsAdminUrl,
  getUpdateCardUrl, getUserByPersonalNumberUrl, getUserPhotoByLoginUrl, getUserUvtByCardNumberUrl
} from "../app.urls";
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../core-frontend-common/auth/authentication.service';
import { ReaderCardFullModel } from '../models/reader-card-full.model';
import { FullUserModel } from '../models/full-user.model';
import { ReaderCardCreateRequestModel } from '../models/reader-card-create-request.model';
import { CurrentUserCardModel } from '../models/current-user-card.model';
import { ReaderCardUpdateRequestModel } from '../models/reader-card-update-request.model';

@Injectable({
  providedIn: 'root'
})
export class CardsService {

  constructor(
    protected authenticationService: AuthenticationService,
    protected http: HttpClient
  ) {
  }

  getCardByCardNumber(cardNumber: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`
      })
    };
    if (this.authenticationService.checkUser()) {
      return this.http
        .get(getCardByCardNumberUrl(cardNumber), httpOptions)
        .pipe(map((res: any) => res));
    }
  }

  getCardByCardNumberAdmin(cardNumber: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`
      })
    };
    if (this.authenticationService.checkUser()) {
      return this.http
        .get(getCardByCardNumberAdminUrl(cardNumber), httpOptions)
        .pipe(map((res: any) => res));
    }
  }

  getCurrentUserCardNumber(): Observable<CurrentUserCardModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`
      })
    };
    if (this.authenticationService.checkUser()) {
      return this.http
        .get(getCurrentUserCardNumberUrl(), httpOptions)
        .pipe(map((res: any) => res as CurrentUserCardModel));
    }
  }

  getAllCardsByFilter(filter: any): Observable<ReaderCardFullModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`
      })
    };
    if (this.authenticationService.checkUser()) {
      return this.http
        .post(getAllCardsByFilterUrl(), filter, httpOptions)
        .pipe(map((res: any) => res.items as ReaderCardFullModel[]));
    }
  }

  createCard(card: ReaderCardCreateRequestModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`
      })
    };
    if (this.authenticationService.checkUser()) {
      return this.http
        .post(getCreateCardUrl(), card, httpOptions)
        .pipe(map((res: any) => res));
    }
  }

  updateCard(card: ReaderCardUpdateRequestModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`
      })
    };
    if (this.authenticationService.checkUser()) {
      return this.http
        .post(getUpdateCardUrl(), card, httpOptions)
        .pipe(map((res: any) => res));
    }
  }

  updateCardAsAdmin(card: ReaderCardUpdateRequestModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`
      })
    };
    if (this.authenticationService.checkUser()) {
      return this.http
        .post(getUpdateCardAsAdminUrl(), card, httpOptions)
        .pipe(map((res: any) => res));
    }
  }

  renewCard(id: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`
      })
    };
    if (this.authenticationService.checkUser()) {
      return this.http
        .get(getRenewCardByIdUrl(id), httpOptions)
        .pipe(map((res: any) => res));
    }
  }

  expireCard(id: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`
      })
    };
    if (this.authenticationService.checkUser()) {
      return this.http
        .get(getExpiredCardByIdUrl(id), httpOptions)
        .pipe(map((res: any) => res));
    }
  }

  getUserByCardNumber(cardNumber: string): Observable<FullUserModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`
      })
    };
    if (this.authenticationService.checkUser()) {
      return this.http
        .get(getUserUvtByCardNumberUrl(cardNumber), httpOptions)
        .pipe(map((res: any) => res as FullUserModel));
    }
  }

  getUserByPersonalNumber(personalNumber: string): Observable<ReaderCardFullModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`
      })
    };
    if (this.authenticationService.checkUser()) {
      return this.http
        .get(getUserByPersonalNumberUrl(personalNumber), httpOptions)
        .pipe(map((res: any) => res as ReaderCardFullModel));
    }
  }

  getUserPhotoByLogin(login: string): Observable<Blob> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${AppCommunicationService.getCurrentUser().access_token}`);

    if (this.authenticationService.checkUser()) {
      return this.http
        .get(getUserPhotoByLoginUrl(login), { headers, responseType: 'blob' as 'blob' })
        .pipe(map((res: any) => res));
    }
  }

  searchUvtUser(phrase: string, historicalIncluded = false): Observable<any[]> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${AppCommunicationService.getCurrentUser().access_token}`);

    if (this.authenticationService.checkUser()) {
      return this.http
        .get(getSearchUvtUserUrl(phrase, historicalIncluded), {headers})
        .pipe(map((res: any) => res as any[]));
    }
  }
}
