import { Component, OnInit, ViewChild } from "@angular/core";
import { PortfolioItemModel } from "./portfolio-item.model";
import { AddPortfolioComponent } from "./add-portfolio/add-portfolio.component";

@Component({
  selector: 'smart-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {
  TABS = {
    addPortfolio: 'addPortfolio',
    listPortfolio: 'listPortfolio'
  };

  currentTab = this.TABS.addPortfolio;

  @ViewChild(AddPortfolioComponent, {static: false}) addItemComponent: AddPortfolioComponent;

  constructor() {
  }

  ngOnInit() {
  }

  editItem(item: PortfolioItemModel) {
    this.currentTab = this.TABS.addPortfolio;

    setTimeout(() => {
      this.addItemComponent.editMode(item);
    }, 100);
  }
}
