import { Component, OnInit } from '@angular/core';
import { OVI_SUPPORT } from '../../../../app-constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EpcCorrectionRequestTypes } from './epc-correction-request-types';
import { BaseForm } from '../../base-form';
import { FormType } from '../../form-types.enum';
import { FormEpcBiblioCorrectionRequest } from '../../../../models/forms/FormEpcBiblioCorrectionRequest';
import { FormsService } from '../../../../services/forms.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-epc-correction-request-form',
  templateUrl: './epc-correction-request-form.component.html',
  styleUrls: ['./epc-correction-request-form.component.scss']
})
export class EpcCorrectionRequestFormComponent extends BaseForm implements OnInit {
  oviSupport = OVI_SUPPORT;

  epcCorrectionRequestTypes = EpcCorrectionRequestTypes;

  epcCorrection: FormEpcBiblioCorrectionRequest;

  form: FormGroup;

  // min date for datepicker is today + 3 days
  minDate = new Date(new Date().setDate(new Date().getDate() + 3));

  constructor(
    protected fb: FormBuilder,
    protected formService: FormsService,
    protected toastr: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      book: this.fb.group({
        archiveIds: ['', [Validators.required]],
        reasonCode: ['', [Validators.required]],
        requiredDate: ['', [Validators.required]],
        message: ['']
      })
    });

    this.form.get('book').get('reasonCode')
      .valueChanges.subscribe({
      next: value => {
        if (value === this.epcCorrectionRequestTypes.others.value) {
          this.form.get('book').get('message').setValidators([Validators.required]);
          this.form.get('book').get('message').updateValueAndValidity({emitEvent: true});
        } else {
          this.form.get('book').get('message').clearValidators();
          this.form.get('book').get('message').updateValueAndValidity({emitEvent: true});
        }
      }
    });
  }

  async submit(e: Event) {
    e.preventDefault();

    this.epcCorrection = new FormEpcBiblioCorrectionRequest();
    this.epcCorrection = this.fullFillUserForm(this.epcCorrection);
    this.epcCorrection = this.fullFillDataFromForm(this.epcCorrection, this.form.get('book').value);

    this.epcCorrection.comment = this.form.get('book').value.message || '';

    const req = await this.formService
      .create(FormType.epcCorrectionRequest, this.epcCorrection).toPromise();

    if (req) {
      this.toastr.success('Záznam úspešne uložený.');
      this.form.reset();
      return;
    }

    this.toastr.error('Záznam sa uložiť nepodarilo.');
  }

  downloadForm() {
    console.log('download form');
  }

}
