import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { FormType } from '../../forms-view/form-types.enum';
import { FormStatusEnum } from '../../../constants';
import { copyTextToClipboard } from '../../../misc/copy-text-to-clipboard';
import { ToastrService } from 'ngx-toastr';
import { FormsService } from '../../../services/forms.service';
import { UserModel } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'smart-request-detail',
  templateUrl: './request-detail.component.html',
  styleUrls: ['./request-detail.component.scss']
})
export class RequestDetailComponent implements OnInit {
  data: any;

  activeSection: FormType;

  FormType = FormType;

  formStatusEnum = FormStatusEnum;

  workflowUsers: UserModel[] = [];

  seminar = {
    date: new Date(),
    time: new Date(),
    workflowUser: null
  };

  @ViewChild('staticModal', { static: false }) modal: ModalDirective;

  @Output() changeStatusEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected toastr: ToastrService,
    protected formsService: FormsService,
    protected userService: UserService
  ) {
  }

  ngOnInit() {
  }

  loadWorkflowUsers() {
    this.userService
      .getAllWorkflowUsers()
      .subscribe(v => {
        this.workflowUsers = [];
        this.workflowUsers = this.workflowUsers.concat(v);
      });
  }

  openModal(data, activeSection: FormType) {
    this.data = data;

    if (activeSection === FormType.seminar) {
      const date = new Date(data.eventDatetime);
      this.seminar.date = date;
      this.seminar.time = date;

      this.loadWorkflowUsers();
    }

    this.activeSection = activeSection;
    this.modal.show();
  }

  close() {
    this.modal.hide();
  }

  clickedButton() {
    this.close();
    return;
  }

  changeStatus() {
    this.close();
    this.changeStatusEvent.emit(this.data);
  }

  copyEmailBtnClick(email: string) {
    copyTextToClipboard(email);
    this.toastr.success('E-Mail skopírovany do schránky.');
  }

  emailBtnClick(email: string) {
    window.location.assign(`mailto:${email}`);
  }

  async changeSeminarVisitorsCount(e: Event) {
    e.preventDefault();
    if (this.activeSection === FormType.seminar && this.data.visitorsCount) {
      const res = await this.formsService
        .update(this.activeSection, this.data).toPromise();

      if (res) {
        this.toastr.success('Počet účastníkov úspešne zadaný do systému.');
        return;
      }

      this.toastr.error('Počet účastníkov sa nepodarilo zmeniť.');
      console.log(res);
      return;
    }
  }


}
