import { Component, OnInit } from '@angular/core';
import { FaqItemModel } from '../faq-item.model';
import { AppCommunicationService } from '../../../../services/app-communication.service';
import { LibtukeFaqService } from '../libtuke-faq.service';
import { ToastrService } from 'ngx-toastr';
import { FAQ_SYSTEMS } from '../../../../constants';
import { first } from 'rxjs/operators';

@Component({
  selector: 'smart-add-faq',
  templateUrl: './add-faq.component.html',
  styleUrls: ['./add-faq.component.scss']
})
export class AddFaqComponent implements OnInit {

  allUkSystems = FAQ_SYSTEMS;

  currentSystem = FAQ_SYSTEMS[0];

  faqItem: FaqItemModel = new FaqItemModel();

  isEditing = false;

  constructor(
    private appCommunication: AppCommunicationService,
    private faqService: LibtukeFaqService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
  }

  reset() {
    this.faqItem = new FaqItemModel();
  }

  editMode(item: FaqItemModel) {
    this.faqItem = item;

    this.isEditing = true;

    this.currentSystem = item.system;
  }

  submit(e: Event) {
    e.preventDefault();

    this.appCommunication.loadingOn('Ukladám.');

    this.faqItem.system = this.currentSystem;

    let api;

    if (this.isEditing) {
      api = this.faqService.update(this.faqItem);
    } else {
      api = this.faqService.create(this.faqItem);
    }

    api.pipe(first())
      .subscribe(v => {
        this.toastr.success('Novinka úspešne uložená.');
        this.reset();
        this.appCommunication.loadingOff();
      }, err => {
        console.log(err);
        this.toastr.error('Novinku sa uložiť nepodarilo.');
        this.appCommunication.loadingOff();
      });
  }

}
