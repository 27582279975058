import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap';
import { DigitalBookAuthorModel } from '../../../../models/digital-book-author.model';
import { DigitalBooksService } from '../../../../services/digital-books.service';
import { AppCommunicationService } from '../../../../services/app-communication.service';
import { NewAuthorModalFormComponent } from '../new-author-modal-form/new-author-modal-form.component';
import { AutoComplete } from 'primeng';

@Component({
  selector: 'app-author-search',
  templateUrl: './author-search.component.html',
  styleUrls: ['./author-search.component.scss'],
})
export class AuthorSearchComponent implements OnInit {
  data = [];

  author: DigitalBookAuthorModel;

  states = {
    opened: false,
    searching: false,
    submitted: false,
  };

  newAuthorModalValid = false;

  @Output() submittedAuthor: EventEmitter<DigitalBookAuthorModel> = new EventEmitter<DigitalBookAuthorModel>();

  @Output() onDeleteAuthor: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() selectedUser: DigitalBookAuthorModel;

  @ViewChild('searchInput', { static: false }) searchInput: AutoComplete;

  @ViewChild('newAuthorModal', { static: true }) newAuthorModal: ModalDirective;

  @ViewChild(NewAuthorModalFormComponent, { static: true }) newAuthorModalForm: NewAuthorModalFormComponent;

  constructor(
    private digitalBooksService: DigitalBooksService,
    private appCommunicationService: AppCommunicationService,
  ) {
  }

  ngOnInit() {
    if (this.selectedUser.name !== '' || this.selectedUser.surname !== '' && this.selectedUser.id) {
      this.author = this.selectedUser;
      this.states.opened = true;
      this.states.searching = false;
      this.states.submitted = true;
    }
  }

  onExternalUserSubmitForm() {
    if (this.newAuthorModalValid) {
      const user: DigitalBookAuthorModel = this.newAuthorModalForm.onSubmit();

      this.digitalBooksService
        .createAuthor(user)
        .subscribe(
          (v) => {
            this.author = v;
            this.selectedUser = v;
            this.states.opened = true;
            this.states.searching = false;
          },
          () => {
            this.appCommunicationService.loadingOff();
          },
        );

      this.newAuthorModal.hide();
    }
  }

  externalUserFormValidChange(val) {
    this.newAuthorModalValid = !!val;
  }

  onFilterUsers(event) {

    const queryDesigner = {
      search: [],

    };

    if (event.query.length > 0) {

      const stringArray = event.query.split(/(\s+)/).filter( function(e) { return e.trim().length > 0; } );
      for (let i = 0; i < stringArray.length; i++) {
        queryDesigner.search.push({
          fields: [],
          value: ''
        });
        queryDesigner.search[i].fields = ['surname'];
        queryDesigner.search[i].value = stringArray[i];
      }
    } else {
      return;
    }


    this.digitalBooksService.searchUser(queryDesigner).subscribe(val => {
      if (val === null || val.length === 0) {
        val = [];
        val.push({
          id: 0,
          name: 'Nenašli ste čo ste hľadali?',
          surname: '',
        });
      } else {
        val.push({
          id: 0,
          name: 'Nenašli ste čo ste hľadali?',
          surname: '',
        });
      }
      this.data = val;
    });
  }

  onSelectOption() {
    if (this.selectedUser.id === 0) {
      this.openExternalUserModal();
      return;
    }
    this.appCommunicationService.loadingOn('Načítavam dáta.');
    this.states.searching = false;
    this.digitalBooksService.getSearched(this.selectedUser).subscribe(
      user => {
        this.author = user;
        this.appCommunicationService.loadingOff();
      },
      () => {
        this.appCommunicationService.loadingOff();
        this.states.searching = true;
      },
    );
  }

  openExternalUserModal() {
    this.newAuthorModal.show();
  }

  onSubmitParticipant() {
    this.submittedAuthor.emit(this.selectedUser);
    this.states.submitted = true;
  }

  toggleSearching() {
    this.states.searching = !this.states.searching;
  }

  onCloseButton() {
    this.states.opened = false;
    this.states.searching = false;
    if (this.states.submitted) {
      this.onDeleteAuthor.emit(true);
      this.states.submitted = false;
    }
  }

  onAddParticipantButton() {
    this.states.opened = true;
    this.states.searching = true;
    // setTimeout(() => {
    //   console.log(this.searchInput)
    //
    //   this.searchInput.el.nativeElement.focus();
    // }, 500);
  }
}
