export class ReaderCardCreateRequestModel {
  id: number;
  identify: string;
  barcode: string;
  firstname: string;
  lastname: string;
  identifyCard: string;
  sign: string;
  address1street: string;
  address1psc: string;
  address1town: string;
  category?: number;
  // Vklada sa nazov fakulty - nazov katedry ako text
  employeer: string;
  phone: string;
  email: string;
  borndate: Date;
}
