import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../app.urls';
import { Observable } from 'rxjs';
import { NewsItemModel } from './news-item.model';
import { map } from 'rxjs/operators';
import { AppCommunicationService } from '../../../services/app-communication.service';
import { AuthenticationService } from '../../../../core-frontend-common/auth/authentication.service';

@Injectable()

export class LibtukeNewsService {

  baseUrl = `${BASE_URL}/newsfeed`;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {
  }

  getAllByFilterAnonymous(filter: any): Observable<NewsItemModel[]> {
    return this.http
      .post(`${this.baseUrl}/anonymous/GetAllByFilter`, filter)
      .pipe(map((res) => res as NewsItemModel[]));
  }

  getAllByFilterAdmin(filter: any): Observable<{items: NewsItemModel[], totalItems: number}> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authService.checkUser()) {
      return this.http
        .post(`${this.baseUrl}/GetAllByFilter`, filter, httpOptions)
        .pipe(map((res) => {
          return {items: res['items'] as NewsItemModel[], totalItems: res['totalItems']};
        }));
    }
  }

  getById(id: number): Observable<NewsItemModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authService.checkUser()) {
      return this.http
        .get(`${this.baseUrl}/GetById/${id}`, httpOptions)
        .pipe(map((res) => res as NewsItemModel));
    }
  }

  create(news: NewsItemModel): Observable<NewsItemModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authService.checkUser()) {
      return this.http
        .post(`${this.baseUrl}/create`, news, httpOptions)
        .pipe(map((res) => res as NewsItemModel));
    }
  }

  update(news: NewsItemModel): Observable<NewsItemModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authService.checkUser()) {
     return this.http
      .post(`${this.baseUrl}/update`, news, httpOptions)
      .pipe(map((res) => res as NewsItemModel));
    }
  }

  delete(id: number): Observable<NewsItemModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authService.checkUser()) {
     return this.http
      .delete(`${this.baseUrl}/delete/${id}`, httpOptions)
      .pipe(map((res) => res as NewsItemModel));
    }
  }

}
