import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { I18nModule } from '../core-frontend-common/template/shared/i18n/i18n.module';

import { UserProfileViewComponent } from './views/user-profile-view/user-profile-view.component';

import { AppCommunicationService } from './services/app-communication.service';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { CustomPipesModule } from './pipes/customPipes.module';
import { TileComponent } from './components/tile/tile.component';
import { UserSearchComponent } from './components/user-search/user-search.component';
import { ExternalUserModalFormComponent } from './components/external-user-modal-form/external-user-modal-form.component';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ModalModule, PopoverModule, TooltipModule } from 'ngx-bootstrap';
import { CalendarModule } from 'primeng/calendar';
import { DevComponent } from './components/dev/dev.component';
import { UserPipe } from './pipes/user.pipe';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { TableModule } from 'primeng/table';
import { SharedModule } from '../core-frontend-common/template/shared/shared.module';
import { SmartClockpickerDirective } from '../core-frontend-common/template/shared/utils/smart-clockpicker.directive';
import { BtnGroupFilterComponent } from './components/btn-group-filter/btn-group-filter.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CardsViewComponent } from './views/cards-view/cards-view.component';
import { AutorityViewComponent } from './views/autority-view/autority-view.component';
import { MailerViewComponent } from './views/mailer-view/mailer-view.component';
import { InterStatsViewComponent } from './views/inter-stats-view/inter-stats-view.component';
import { BorrowViewComponent } from './views/warehouse-view/views/borrow-view/borrow-view.component';
import { WarehouseViewComponent } from './views/warehouse-view/views/warehouse-view/warehouse-view.component';
import { ReadercardViewComponent } from './views/warehouse-view/views/readercard-view/readercard-view.component';
import { DigitalBooksFormComponent } from './views/digital-books-view/digital-books-form/digital-books-form.component';
import { DigitalBooksCatalogComponent } from './views/digital-books-view/digital-books-catalog/digital-books-catalog.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AuthorSearchModule } from './views/digital-books-view/components/author-search/author-search.module';
import { BaseModalDialogModule } from './components/dialog/base-modal-dialog.module';
import { BorrowNotifyViewComponent } from './views/warehouse-view/views/borrow-notify-view/borrow-notify-view.component';
import { NotificationModule } from './components/notification/notification.module';
import { InputTextModule } from 'primeng/inputtext';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmationService, DialogModule, EditorModule, MessageService, TooltipModule as pTooltipModule } from "primeng";
import { RequestsViewComponent } from './views/requests-view/requests-view.component';
import { RequestDetailComponent } from './views/requests-view/request-detail/request-detail.component';
import { ArticlesManagementViewComponent } from './views/articles-management-view/articles-management-view.component';
import { CustomDirectivesModule } from './directives/custom-directives.module';
import { CustomDatepickerModule } from './components/custom-datepicker/custom-datepicker.module';
import { LibtukeModule } from './views/libtuke/libtuke.module';
import { PublicBorrowReadingCardComponent } from './views/public-borrow/public-borrow-reading-card/public-borrow-reading-card.component';
import { ReaderCardFormModule } from "./components/reader-card/reader-card-form/reader-card-form.module";
import { ReaderCardMismatchFormModule } from "./components/reader-card/reader-card-mismatch-form/reader-card-mismatch-form.module";
import { ReaderCardBaseComponent } from "./components/reader-card/reader-card-base.component";
import { CardItemComponent } from './views/cards-view/card-item/card-item.component';
import { PaymentsViewComponent } from "./views/payments-view/payments-view.component";
import { PaymentRefundComponent } from './views/payments-view/payment-refund/payment-refund.component';
import { PaymentEmailComponent } from './views/payments-view/payment-email/payment-email.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    I18nModule,
    CustomPipesModule,
    RouterModule,
    AutoCompleteModule,
    ModalModule,
    CalendarModule,
    TooltipModule,
    FullCalendarModule,
    TableModule,
    InputTextModule,
    PopoverModule,
    ConfirmDialogModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
    DropdownModule,
    NgxDropzoneModule,
    AuthorSearchModule,
    BaseModalDialogModule,
    BaseModalDialogModule,
    NotificationModule,
    EditorModule,
    CustomDirectivesModule,
    CustomDatepickerModule,
    LibtukeModule,
    ReaderCardFormModule,
    ReaderCardMismatchFormModule,
    pTooltipModule,
    DialogModule
  ],
  declarations: [
    DashboardComponent,
    UserProfileViewComponent,
    DashboardComponent,
    TileComponent,
    UserSearchComponent,
    ExternalUserModalFormComponent,
    UserPipe,
    DevComponent,
    SmartClockpickerDirective,
    BtnGroupFilterComponent,
    WarehouseViewComponent,
    CardsViewComponent,
    AutorityViewComponent,
    MailerViewComponent,
    InterStatsViewComponent,
    BorrowViewComponent,
    ReadercardViewComponent,
    DigitalBooksFormComponent,
    DigitalBooksCatalogComponent,
    BorrowNotifyViewComponent,
    RequestsViewComponent,
    RequestDetailComponent,
    ArticlesManagementViewComponent,
    PublicBorrowReadingCardComponent,
    ReaderCardBaseComponent,
    CardItemComponent,
    PaymentsViewComponent,
    PaymentRefundComponent,
    PaymentEmailComponent
  ],
  exports: [],
  providers: [
    AppCommunicationService,
    ConfirmationService,
    DeviceDetectorService,
    MessageService
  ]
})
export class CustomModule {
}
