import { Component, OnInit, ViewChild } from '@angular/core';
import { FormType } from '../forms-view/form-types.enum';
import { FormsService } from '../../services/forms.service';
import { RequestDetailComponent } from './request-detail/request-detail.component';
import { FormStatusEnum, FormStatuses } from '../../constants';
import { ConfirmationService } from 'primeng';
import { ToastrService } from 'ngx-toastr';
import { Seminars } from '../forms-view/forms/education-form/seminars';

export class TableColumn {
  field: string;
  header: string;
}

export class Tab {
  title: string;
  code: FormType;
}

@Component({
  selector: 'smart-requests-view',
  templateUrl: './requests-view.component.html',
  styleUrls: ['./requests-view.component.scss']
})

export class RequestsViewComponent implements OnInit {

  TABS: Tab[] = [
    {
      title: 'MVS knihy',
      code: FormType.mvsBook
    },
    {
      title: 'MVS časopisy',
      code: FormType.mvsJournal
    },
    {
      title: 'Zakúpenie dokumentov',
      code: FormType.buyDocument
    },
    {
      title: 'EPC korekcia',
      code: FormType.epcCorrectionRequest
    },
    {
      title: 'Dig. kópie',
      code: FormType.epcDigitalCopy
    },
    {
      title: 'Feedback',
      code: FormType.feedback
    },
    {
      title: 'Vzdelávanie a školenia',
      code: FormType.seminar
    },
    {
      title: 'Žiadosť o rešerš',
      code: FormType.search
    }
  ];

  formTypes = FormType;

  formStatusEnum = FormStatusEnum;

  activeTab: Tab = this.TABS[0];

  data = [];

  table = {
    columns: [],
    page: 1,
    pageSize: 30,
    totalItems: 0
  };

  seminars = Seminars;

  @ViewChild(RequestDetailComponent, {static: false}) requestDetailDialog: RequestDetailComponent;
  constructor(
    protected formsService: FormsService,
    protected confirmationService: ConfirmationService,
    protected toastr: ToastrService
  ) { }

  ngOnInit() {
  }

  changeTab(tab) {
    this.activeTab = tab;
    this.loadData();
  }

  setPage(pageInfo) {
    this.table.page = (pageInfo.first + pageInfo.rows) / this.table.pageSize;
    this.loadData();
  }

  loadData() {
    const queryDesigner = {
      page: this.table.page,
      pageSize: this.table.pageSize,
      orderBy: [
        {
          field: 'status',
          order: 'ASC'
        },
        {
          field: 'createdDatetime',
          order: 'DESC'
        }
      ]
    };

    this.loadTableColumns();
    this.formsService
      .getAllByFilter(this.activeTab.code, queryDesigner)
      .subscribe(v => {
        this.data = v.items;
        this.table.totalItems = v.totalItems;
      });
  }

  openDetail(item) {
    this.requestDetailDialog.openModal(item, this.activeTab.code);
  }

  async changeStatus(item) {
    if (item.status === FormStatusEnum.FINISHED) {
      return;
    }
    let goToInProgress = false;

    if (item.status === FormStatusEnum.CREATED) {
      const inProgressObj = FormStatuses.find(f => f.value === FormStatusEnum.IN_PROGRESS);
      goToInProgress = inProgressObj.visible.indexOf(this.activeTab.code) !== -1;
    }

    this.confirmationService
      .confirm({
        message: `Skutočne si prajete zmeniť stav požiadavky na ${goToInProgress ? 'Spracovávaná' : 'Dokončená'}?`,
        acceptLabel: 'Áno',
        rejectLabel: 'Nie',
        accept: () => {
          this.formsService
            .setStatus(this.activeTab.code, item, goToInProgress ? FormStatusEnum.IN_PROGRESS : FormStatusEnum.FINISHED)
            .subscribe(v => {
              this.toastr.success('Zmena prebehla úspešne.');
              this.loadData();
            }, e => {
              console.log(e);
              this.toastr.error('Zmena prebehla neúspešne.');
            });
        }
      });
  }

  loadTableColumns() {
    switch (this.activeTab.code) {
      case FormType.mvsBook:
        this.table.columns = [
          {
            field: 'author',
            header: 'Autor'
          },
          {
            field: 'title',
            header: 'Názov'
          },
          {
            field: 'isbn',
            header: 'ISBN'
          },
          {
            field: 'validUntilDatetime',
            header: 'Platné do'
          }
        ];
        break;
      case FormType.mvsJournal:
        this.table.columns = [
          {
            field: 'author',
            header: 'Autor'
          },
          {
            field: 'journalTitle',
            header: 'Názov'
          },
          {
            field: 'volume',
            header: 'Číslo'
          },
          {
            field: 'issue',
            header: 'Ročník'
          },
          {
            field: 'articleTitle',
            header: 'Názov časopisu'
          },
          {
            field: 'validUntilDatetime',
            header: 'Platné do'
          }
        ];
        break;
      case FormType.search:
        this.table.columns = [
          {
            field: 'description',
            header: 'Popis'
          },
          {
            field: 'keywordsSk',
            header: 'Kľúč. slová'
          },
          {
            field: 'keywordsEn',
            header: 'Kľúč. slová EN'
          },
          {
            field: 'yearRange',
            header: 'Rozsah rokov'
          },
          {
            field: 'documentLanguage',
            header: 'Jazyk'
          }
        ];
        break;
      case FormType.seminar:
        this.table.columns = [
          {
            field: 'tukeRelationshipCode',
            header: 'Vzťah k TUKE'
          },
          {
            field: 'typeCode',
            header: 'Typ'
          },
          {
            field: 'categoryCode',
            header: 'Kategória'
          },
          {
            field: 'eventDatetime',
            header: 'Dátum/čas'
          },
          {
            field: 'participantCount',
            header: 'Počet ľudí'
          }
        ];
        break;
      case FormType.buyDocument:
        this.table.columns = [
          {
            field: 'author',
            header: 'Autor'
          },
          {
            field: 'title',
            header: 'Názov'
          },
          {
            field: 'isbnIssn',
            header: 'ISBN/ISSN'
          }
        ];
        break;
      case FormType.epcCorrectionRequest:
        this.table.columns = [
          {
            field: 'archiveIds',
            header: 'Archívne čísla'
          },
          {
            field: 'reasonCode',
            header: 'Dôvod'
          },
          {
            field: 'requiredDate',
            header: 'Dátum'
          }
        ];
        break;
      case FormType.epcDigitalCopy:
        this.table.columns = [
          {
            field: 'archiveIds',
            header: 'Archívne čísla'
          },
          {
            field: 'reasonCode',
            header: 'Dôvod'
          },
          {
            field: 'requiredDate',
            header: 'Dátum'
          }
        ];
        break;
      case FormType.feedback:
        this.table.columns = [
          {
            field: 'email',
            header: 'E-Mail'
          },
          {
            field: 'tukeRelationshipCode',
            header: 'Vzťah k TUKE'
          },
          {
            field: 'comment',
            header: 'Správa'
          }
        ];
        break;
    }
  }

}
