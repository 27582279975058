import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppCommunicationService } from './app-communication.service';
import {
  getAllAuthorsByFilterUrl,
  getAllDigitalBooksUrl, getAuthorByIdUrl,
  getCreateAuthorUrl,
  getCreateDigitalBookUrl,
  getDownloadDigiBookFileUrl,
  getUploadDigiBookUrl,
} from '../app.urls';
import { map } from 'rxjs/operators';
import { DigitalBookModel } from '../models/digital-book.model';
import { DigitalBookAuthorModel } from '../models/digital-book-author.model';
import { AuthenticationService } from "../../core-frontend-common/auth/authentication.service";

@Injectable({
  providedIn: 'root',
})
export class DigitalBooksService {

  constructor(
    protected http: HttpClient,
    protected authenticationService: AuthenticationService,
  ) {
  }

  getAllDigitalBooks(filter: any): Observable<{ items: DigitalBookModel[], totalItems: number }> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.post(getAllDigitalBooksUrl(), filter, httpOptions).pipe(map(res => res as { items: DigitalBookModel[], totalItems: number }));
    }
  }

  createDigitalBook(book: DigitalBookModel): Observable<DigitalBookModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.post(getCreateDigitalBookUrl(), book, httpOptions).pipe(map((res: any) => res as DigitalBookModel));
    }
  }

  createAuthor(author: DigitalBookAuthorModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.post(getCreateAuthorUrl(), author, httpOptions);
    }
  }

  searchUser(filter: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.post(getAllAuthorsByFilterUrl(), filter, httpOptions).pipe(map((res: any) => res.items));
    }
  }

  getSearched(user): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authenticationService.checkUser()) {
      return this.http.get(getAuthorByIdUrl(user.id), httpOptions);
    }
  }

  uploadFile(bookId: number, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        Accept: 'application/json',
      }),
    };

    if (this.authenticationService.checkUser()) {
      return this.http.post(getUploadDigiBookUrl(bookId), formData, httpOptions);
    }
  }

  downloadFile(id: number): Observable<Blob> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
      }),
      responseType: 'blob' as 'json',
    };

    return this.http.get(getDownloadDigiBookFileUrl(id), httpOptions).pipe(
      map(res => {
        return res as Blob;
      }),
    );
  }
}
