import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../app.urls';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppCommunicationService } from '../../../services/app-communication.service';
import { AuthenticationService } from '../../../../core-frontend-common/auth/authentication.service';
import { PortfolioItemModel } from './portfolio-item.model';

@Injectable()

export class LibtukePortfolioService {

  baseUrl = `${BASE_URL}/portfolio`;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {
  }

  getAllByFilterAnonymous(filter: any): Observable<PortfolioItemModel[]> {
    return this.http
      .post(`${this.baseUrl}/anonymous/GetAllByFilter`, filter)
      .pipe(map((res) => res as PortfolioItemModel[]));
  }

  getAllByFilterAdmin(filter: any): Observable<{items: PortfolioItemModel[], totalItems: number}> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authService.checkUser()) {
      return this.http
        .post(`${this.baseUrl}/GetAllByFilter`, filter, httpOptions)
        .pipe(map((res: any) => {
          return {items: res.items as PortfolioItemModel[], totalItems: res.totalItems};
        }));
    }
  }

  getById(id: number): Observable<PortfolioItemModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authService.checkUser()) {
      return this.http
        .get(`${this.baseUrl}/GetById/${id}`, httpOptions)
        .pipe(map((res) => res as PortfolioItemModel));
    }
  }

  create(news: PortfolioItemModel): Observable<PortfolioItemModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authService.checkUser()) {
      return this.http
        .post(`${this.baseUrl}/create`, news, httpOptions)
        .pipe(map((res) => res as PortfolioItemModel));
    }
  }

  update(news: PortfolioItemModel): Observable<PortfolioItemModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authService.checkUser()) {
     return this.http
      .post(`${this.baseUrl}/update`, news, httpOptions)
      .pipe(map((res) => res as PortfolioItemModel));
    }
  }

  delete(id: number): Observable<PortfolioItemModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authService.checkUser()) {
     return this.http
      .delete(`${this.baseUrl}/delete/${id}`, httpOptions)
      .pipe(map((res) => res as PortfolioItemModel));
    }
  }

  uploadImage(portfolioId: number, order: number, isMain = false, file: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name ? file.name : '');

    const httpOptions: object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
      }),
      reportProgress: false,
      observe: 'response',
    };

    return this.http.post(`${this.baseUrl}/file/upload/${portfolioId}/${order}/${isMain}`, formData, httpOptions);
  }

  deleteImage(portfolioId: number, imageId: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authService.checkUser()) {
      return this.http
        .delete(`${this.baseUrl}/file/delete/${portfolioId}/${imageId}`, httpOptions)
        .pipe(map((res) => res as any));
    }
  }

  changeImageOrder(portfolioImageId: number, orderNumber: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authService.checkUser()) {
      return this.http
        .put(`${this.baseUrl}/file/changeImageOrder/${portfolioImageId}/${orderNumber}`, {}, httpOptions)
        .pipe(map((res) => res as any));
    }
  }

  setMainImage(portfolioImageId: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authService.checkUser()) {
      return this.http
        .put(`${this.baseUrl}/file/setMainImage/${portfolioImageId}`, {}, httpOptions)
        .pipe(map((res) => res as any));
    }
  }

}
