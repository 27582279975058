import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { BookNoveltyModel } from '../../../../models/book-novelty.model';
import { BookNoveltyService } from '../../../../services/book-novelty.service';
import { convertBase64ToBlob } from '../../../../misc/convert-base64-to-blob';
import { ToastrService } from 'ngx-toastr';
import { AppCommunicationService } from '../../../../services/app-communication.service';
import { ConfirmationService } from 'primeng';

@Component({
  selector: 'smart-list-books',
  templateUrl: './list-books.component.html',
  styleUrls: ['./list-books.component.scss']
})
export class ListBooksComponent implements OnInit {
  table = {
    columns: [],
    page: 1,
    pageSize: 30,
    totalItems: 0
  };

  data: BookNoveltyModel[] = [];

  @Output() editItemEvent: EventEmitter<BookNoveltyModel> = new EventEmitter<BookNoveltyModel>();

  constructor(
    private bookService: BookNoveltyService,
    private toastr: ToastrService,
    private appCommunication: AppCommunicationService,
    private confirmation: ConfirmationService
  ) {
  }

  ngOnInit() {
  }

  setPage(pageInfo) {
    this.table.page = (pageInfo.first + pageInfo.rows) / this.table.pageSize;
    this.loadData();
  }

  loadData() {
    this.appCommunication.loadingOn('Načítavam dáta.');

    const queryDesigner = {
      page: this.table.page,
      pageSize: this.table.pageSize
    };

    this.bookService
      .getAllByFilter(queryDesigner)
      .subscribe(v => {
        this.data = v.items;
        this.table.totalItems = v.totalItems;
        this.appCommunication.loadingOff();
      }, err => {
        console.log(err);
        this.appCommunication.loadingOff();
      });
  }

  openImagePreview(image: string) {
    const converted = convertBase64ToBlob(image);
    if (!converted) {
      return;
    }
    window.open(URL.createObjectURL(converted), '_blank');
  }

  openDetail(book: BookNoveltyModel) {
    this.editItemEvent.emit(book);
  }

  showDeleteConfirmation(book: BookNoveltyModel) {
    this.confirmation
      .confirm({
        message: 'Skutočne si prajete odstrániť záznam?',
        acceptLabel: 'Áno',
        rejectLabel: 'Nie',
        accept: () => this.deleteBook(book)
      });
  }

  async deleteBook(book: BookNoveltyModel) {
    const res = await this.bookService
      .delete(book.id)
      .toPromise();

    this.appCommunication.loadingOn('Odstraňujem záznam.');

    // dont know why backend is returning null in case of 200 status
    if (res === null) {
      this.toastr.success('Záznam bol úspešne zmazaný.');
      this.loadData();
      this.appCommunication.loadingOff();
      return;
    }
    this.toastr.error('Záznam sa zmazať nepodarilo.');
    this.appCommunication.loadingOff();

  }
}
