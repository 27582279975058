/* ### BASE URL ### */
import { StringUtils } from '../core-frontend-common/utils/string-utils';
import {
  PRODUCTION_BUILD,
  SYSTEM_NAME,
  PROD_BUILD_USE_HTTPS,
  DEV_BUILD_USE_HTTPS,
  DEVAPI_BUILD,
  LOCAL_BACKEND,
  LOCAL_AUTH
} from './app.settings';
import {
  SYSTEM_NAME_ALFRED,
  SYSTEM_NAME_EPC,
  SYSTEM_NAME_ETD, SYSTEM_NAME_HELPDESK,
  SYSTEM_NAME_ISBN,
  SYSTEM_NAME_LIBADMIN,
  SYSTEM_NAME_ROOMBOOK, SYSTEM_NAME_TOOLS, SYSTEM_NAME_UIK, SYSTEM_NAME_AUTH,
  SYSTEM_NAME_ZP
} from './constants';
import { FormType } from './views/forms-view/form-types.enum';

function getApiProtocol(): string {
  if (PRODUCTION_BUILD) {
    if (PROD_BUILD_USE_HTTPS) {
      return 'https';
    } else {
      return 'http';
    }
  }

  if (DEVAPI_BUILD) {
    if (DEV_BUILD_USE_HTTPS) {
      return 'https';
    } else {
      return 'http';
    }
  }

  return 'http';
}

function getClientProtocol(): string {
  if (PRODUCTION_BUILD) {
    if (PROD_BUILD_USE_HTTPS) {
      return 'https';
    } else {
      return 'http';
    }
  }

  if (DEVAPI_BUILD) {
    if (DEV_BUILD_USE_HTTPS) {
      return 'https';
    } else {
      return 'http';
    }
  }

  return 'http';
}

export const LOCAL_HOST = 'localhost';         // local frontend host (default localhost)
export const LOCAL_FRONTEND_PORT = '3003';     // local frontend port
export const LOCAL_BACKEND_PORT = '63550';     // local API port

export const LOCAL_APP_PORTS = {
  [SYSTEM_NAME_EPC]: 0,
  [SYSTEM_NAME_ETD]: 3003,
  [SYSTEM_NAME_ISBN]: 3004,
  [SYSTEM_NAME_ROOMBOOK]: 3001,
  [SYSTEM_NAME_ZP]: 0,
  [SYSTEM_NAME_UIK]: 3002,
  [SYSTEM_NAME_HELPDESK]: 0,
  [SYSTEM_NAME_TOOLS]: 0,
  [SYSTEM_NAME_LIBADMIN]: 0,
  [SYSTEM_NAME_ALFRED]: 3003
};


export const PROD_HOST = 'portal.tuke.sk';         // production hostname
export const PROD_HOST_PORT = '';                      // production host port (empty for default)
export const PROD_API_HOST = 'portal.tuke.sk';        // production API hostname
export const PROD_API_HOST_PORT = '';                  // production API host port (empty for default)
export const DEV_HOST = 'portal.dev.msweb.tuke.sk';      // development hostname
export const DEV_HOST_PORT = '';                       // development host port (empty for default)
export const DEV_API_HOST = 'portal.dev.msweb.tuke.sk';     // development API hostname
export const DEV_API_HOST_PORT = '';                       // development API host port (empty for default)

// local addresses (used for local development)
export const LOCAL_FRONTEND_URL = `${getClientProtocol()}://${LOCAL_HOST}:${LOCAL_FRONTEND_PORT}`;
const LOCAL_API_URL = `${getApiProtocol()}://${LOCAL_HOST}:${LOCAL_BACKEND_PORT}/api`;

// base URL for frontend (non-local)
export const BASE_FRONTEND_URL = `${getClientProtocol()}://${PRODUCTION_BUILD ?
  (PROD_HOST + (StringUtils.isNullOrEmpty(PROD_HOST_PORT) ? '' : (':' + PROD_HOST_PORT))) :
  (DEV_HOST + (StringUtils.isNullOrEmpty(DEV_HOST_PORT) ? '' : (':' + DEV_HOST_PORT)))}`;

export const LIBADMIN_URL = getBaseApiUrl(SYSTEM_NAME_LIBADMIN);

// constructs base URL for API
export function getBaseApiUrl(systemName?: string): string {
  if (PRODUCTION_BUILD) {
    return `${getApiProtocol()}://api.${systemName}.${PROD_API_HOST}${
      StringUtils.isNullOrEmpty(PROD_API_HOST_PORT) ? '' : (':' + PROD_API_HOST_PORT)
    }/api`;
  } else if (LOCAL_BACKEND && !DEVAPI_BUILD) {
    return LOCAL_API_URL;
  }

  return `${getApiProtocol()}://api.${systemName}.${DEV_API_HOST}${
    StringUtils.isNullOrEmpty(DEV_API_HOST_PORT) ? '' : (':' + DEV_API_HOST_PORT)
  }/api`;
}

export function getBaseFrontendUrl(systemName?: string): string {
  if (systemName === SYSTEM_NAME_AUTH) {
    return `${getClientProtocol()}://${PRODUCTION_BUILD ? PROD_HOST : DEV_HOST}${
      StringUtils.isNullOrEmpty(PRODUCTION_BUILD ? PROD_HOST_PORT : DEV_HOST_PORT) ? '' : (':' + (PRODUCTION_BUILD ? PROD_HOST_PORT : DEV_HOST_PORT))
    }`;
  }
  return `${getClientProtocol()}://${systemName}.${PRODUCTION_BUILD ? PROD_HOST : DEV_HOST}${
    StringUtils.isNullOrEmpty(PRODUCTION_BUILD ? PROD_HOST_PORT : DEV_HOST_PORT) ? '' : (':' + (PRODUCTION_BUILD ? PROD_HOST_PORT : DEV_HOST_PORT))
  }`;
}

// constructs base URL for API host without suffix
function getCurrentApiHost(): string {
  if (PRODUCTION_BUILD) {
    return `${getApiProtocol()}://${PROD_API_HOST}`;
  } else if (LOCAL_BACKEND) {
    return `${getApiProtocol()}://${LOCAL_HOST}:${LOCAL_BACKEND_PORT}`;
  }

  return `${getApiProtocol()}://${DEV_API_HOST}`;
}

export const BASE_URL = getBaseApiUrl(SYSTEM_NAME);
export const ADMIN_URL = getBaseApiUrl(SYSTEM_NAME_LIBADMIN);
export const CURRENT_API_HOST_URL = getCurrentApiHost();

const API_FACULTY_CODEBOOK = '/codebook/getFaculties';
const API_DEPARTMENT_CODEBOOK = '/codebook/getDepartments/';

export function getAppConfigurationUrl() {
  return `${BASE_URL}/config/client`;
}

export function getSearchCollateFieldOfStudyUrl(phrase) {
  return `${BASE_URL}/codebook/searchCollateFieldOfStudy/${phrase}`;
}

export function getLanguagesUrl() {
  return `${BASE_URL}/codebook/getLanguages`;
}

/* ### USER URLS ### */
const API_GET_USER = '/user';
const API_GET_USER_HISTORICAL = '/user/getWithHistorical/';
const API_GET_USER_PHOTO = '/user/photo/';
const API_GET_SEARCHED_USER = '/user/getSearched';
const API_UPDATE_EXTERNAL_USER = '/user/updateExternalThesisUser/';
const API_SEARCH_USER_COLLATE = '/user/searchCollate/';

export function getFacultyCodebookUrl() {
  return BASE_URL + API_FACULTY_CODEBOOK;
}

export function getDepartmentCodebookUrl(facultyId) {
  return `${BASE_URL + API_DEPARTMENT_CODEBOOK}/${facultyId}`;
}

export function getHistoricalUserByIdUrl(id: number) {
  return BASE_URL + API_GET_USER_HISTORICAL + id;
}

export function getUserPhotoUrl(hash: string) {
  return BASE_URL + API_GET_USER_PHOTO + hash;
}

export function getSearchedUserUrl() {
  return BASE_URL + API_GET_SEARCHED_USER;
}

export function getUpdateExternalUserUrl(id) {
  return BASE_URL + API_UPDATE_EXTERNAL_USER + id;
}

export function getSearchUserCollateUrl(phrase) {
  return BASE_URL + API_SEARCH_USER_COLLATE + phrase;
}

export function getAllOrdersWithReaderUrl() {
  return `${BASE_URL}/borrow/getAllOrdersWithReader`;
}

export function printLabelUrl(section: number) {
  return `${BASE_URL}/borrow/printLabel/${section}`;
}

export function getUploadDigiBookUrl(digiId: number) {
  return `${BASE_URL}/digi/file/upload/${digiId}`;
}

export function getDownloadDigiBookFileUrl(digiId: number) {
  return `${BASE_URL}/digi/file/download/${digiId}`;
}

export function getCreateAuthorUrl() {
  return `${BASE_URL}/digi/author/create`;
}

export function getAllAuthorsByFilterUrl() {
  return `${BASE_URL}/digi/author/getAllByFilter`;
}

export function getAuthorByIdUrl(id: number) {
  return `${BASE_URL}/digi/author/get/${id}`;
}

export function getUpdateAuthorByIdUrl(id: number) {
  return `${BASE_URL}/digi/author/update/${id}`;
}

export function getCreateDigitalBookUrl() {
  return `${BASE_URL}/digi/book/create`;
}

export function getUpdateDigitalBookUrl(id: number) {
  return `${BASE_URL}/digi/book/update/${id}`;
}

export function getAllDigitalBooksUrl() {
  return `${BASE_URL}/digi/book/getAllByFilter`;
}

export function getDigitalBookByIdUrl(id: number) {
  return `${BASE_URL}/digi/book/get/${id}`;
}

export function getAllWarehouseLayoutUrl() {
  return `${BASE_URL}/warehouse/getAllWarehouseLayout`;
}

export function getUpdateWarehouseLayoutUrl(id: number) {
  return `${BASE_URL}/warehouse/update/${id}`;
}

export function getAllLogBorrowOrdersUrl() {
  return `${BASE_URL}/borrow/getLogBorrowOrders`;
}

export function getNotifyByBatchNumberUrl(batchNumber: number) {
  return `${BASE_URL}/borrow/notifyByBatchNumber/${batchNumber}`;
}

export function getSessionValidityCheckUrl() {
  return `${BASE_URL}/auth/validSession`;
}

export function getAllNotificationsForSystem(isForLoginScreen: boolean, systemName: string) {
  return LIBADMIN_URL + '/notification?isForLoginScreen=' + isForLoginScreen + '&application=' + systemName;
}

export function getCardByCardNumberUrl(cardNumber: string) {
  return `${BASE_URL}/readerCard/getLikeBarcode/${cardNumber}`;
}

export function getCardByCardNumberAdminUrl(cardNumber: string) {
  return `${BASE_URL}/readerCard/getLikeBarcodeAsAdmin/${cardNumber}`;
}

export function getAllCardsByFilterUrl() {
  return `${BASE_URL}/readerCard/getAllByFilter`;
}

export function getCreateCardUrl() {
  return `${BASE_URL}/readerCard/create`;
}

export function getRenewCardByIdUrl(id: number) {
  return `${BASE_URL}/readerCard/renew/${id}`;
}

export function getExpiredCardByIdUrl(id: number) {
  return `${BASE_URL}/readerCard/expire/${id}`;
}

export function getUpdateCardUrl() {
  return `${BASE_URL}/readerCard/update`;
}

export function getUpdateCardAsAdminUrl() {
  return `${BASE_URL}/readerCard/updateAsAdmin`;
}

export function getUserUvtByCardNumberUrl(cardNumber: string) {
  return `${BASE_URL}/readerCard/getUserUvtLikeBarcode/${cardNumber}`;
}

export function getMvsAllByFilterUrl() {
  return `${BASE_URL}/mvsBook/getAllByFilter`;
}

export function getMvsBookByIdUrl(id: number) {
  return `${BASE_URL}/mvsBook/get/${id}`;
}

export function getMvsCreateUrl() {
  return `${BASE_URL}/mvsBook/create`;
}

export function getMvsUpdateUrl() {
  return `${BASE_URL}/mvsBook/update`;
}

export function getMvsSetStatusUrl(id: number, status: number) {
  return `${BASE_URL}/mvsBook/setStatus/${id}/${status}`;
}

export function getMvsJournalAllByFilterUrl() {
  return `${BASE_URL}/mvsJournal/getAllByFilter`;
}

export function getMvsJournalByIdUrl(id: number) {
  return `${BASE_URL}/mvsJournal/get/${id}`;
}

export function getMvsJournalCreateUrl() {
  return `${BASE_URL}/mvsJournal/create`;
}

export function getMvsJournalUpdateUrl() {
  return `${BASE_URL}/mvsJournal/update`;
}

export function getMvsJournalSetStatusUrl(id: number, status: number) {
  return `${BASE_URL}/mvsJournal/setStatus/${id}/${status}`;
}

export function getFormSearchAllByFilterUrl() {
  return `${BASE_URL}/search/getAllByFilter`;
}

export function getFormSearchByIdUrl(id: number) {
  return `${BASE_URL}/search/get/${id}`;
}

export function getFormSearchCreateUrl() {
  return `${BASE_URL}/search/create`;
}

export function getFormSearchUpdateUrl() {
  return `${BASE_URL}/search/update`;
}

export function getFormSearchSetStatusUrl(id: number, status: number) {
  return `${BASE_URL}/search/setStatus/${id}/${status}`;
}

export function getSeminarAllByFilterUrl() {
  return `${BASE_URL}/seminar/getAllByFilter`;
}

export function getSeminarByIdUrl(id: number) {
  return `${BASE_URL}/seminar/get/${id}`;
}

export function getSeminarCreateUrl() {
  return `${BASE_URL}/seminar/create`;
}

export function getSeminarUpdateUrl() {
  return `${BASE_URL}/seminar/update`;
}

export function getSeminarSetStatusUrl(id: number, status: number) {
  return `${BASE_URL}/seminar/setStatus/${id}/${status}`;
}

export function getBuyDocumentAllByFilterUrl() {
  return `${BASE_URL}/buyDocument/getAllByFilter`;
}

export function getBuyDocumentByIdUrl(id: number) {
  return `${BASE_URL}/buyDocument/get/${id}`;
}

export function getBuyDocumentCreateUrl() {
  return `${BASE_URL}/buyDocument/create`;
}

export function getBuyDocumentUpdateUrl() {
  return `${BASE_URL}/buyDocument/update`;
}

export function getBuyDocumentSetStatusUrl(id: number, status: number) {
  return `${BASE_URL}/buyDocument/setStatus/${id}/${status}`;
}

export function getCreateFormUrl(formType: FormType): string {
  return `${BASE_URL}/form/${formType}/create`;
}

export function getUpdateFormUrl(formType: FormType): string {
  return `${BASE_URL}/form/${formType}/update`;
}

export function getSetStatusFormUrl(formType: FormType, id: number, status: number): string {
  return `${BASE_URL}/form/${formType}/setStatus/${id}/${status}`;
}

export function getByIdFormUrl(formType: FormType, id: number): string {
  return `${BASE_URL}/form/${formType}/get/${id}`;
}

export function getAllByFilterFormUrl(formType: FormType): string {
  return `${BASE_URL}/form/${formType}/getAllByFilter`;
}

export function getAllWorkflowUsersUrl() {
  return `${BASE_URL}/user/getAllWorkflowUsers`;
}

export function getCreateBookNoveltyUrl() {
  return `${BASE_URL}/bookNovelty/create`;
}

export function getUpdateBookNoveltyUrl() {
  return `${BASE_URL}/bookNovelty/update`;
}

export function getDeleteBookNoveltyUrl(id: number) {
  return `${BASE_URL}/bookNovelty/delete/${id}`;
}

export function getAllByFilterBookNoveltyUrl() {
  return `${BASE_URL}/bookNovelty/getAllByFilter`;
}

export function getCurrentUserCardNumberUrl() {
  return `${BASE_URL}/auth/getCurrentUserCck`;
}

export function getUserByPersonalNumberUrl(personalNumber: string) {
  return `${BASE_URL}/readerCard/getLikePersonalNumber/${personalNumber}`;
}

export function getUserPhotoByLoginUrl(login: string) {
  return `${BASE_URL}/isCard/get/${login}`;
}

export function getSearchUvtUserUrl(phrase: string, historicalIncluded: boolean) {
  return `${BASE_URL}/user/search/uvt/${phrase}/${historicalIncluded}`;
}
