import { BaseFormEntityRequest } from './BaseFormEntityRequest';

export class FormMvsBookRequest extends BaseFormEntityRequest {
  author: string;
  title: string;
  // yearOfPublishing?: number;
  isbn?: string;
  // citationSourceLocation?: string;
  validUntilDatetime?: Date;
  id?: number;
}
