import { Component, OnInit, ViewChild } from "@angular/core";
import { PaymentStatusEnum } from './payments';
import * as moment from 'moment';
import { AppCommunicationService } from '../../services/app-communication.service';
import { GeneralService } from '../../services/general.service';
import { first, mergeMap } from "rxjs/operators";
import { PaymentRefundComponent } from "./payment-refund/payment-refund.component";
import { PaymentEmailComponent } from "./payment-email/payment-email.component";

@Component({
  selector: 'payments-view',
  templateUrl: './payments-view.component.html',
  styleUrls: ['./payments-view.component.scss']
})
export class PaymentsViewComponent implements OnInit {

  filter = {
    text: '',
    faculty: [],
    year: [],
    type: [],
    page: 1,
    pageSize: 15,
    totalItems: 1
  };

  thesisTypeFilter = {
    data: [],
    settings: {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Označiť všetko',
      unSelectAllText: 'Odznačiť všetko',
      itemsShowLimit: 1,
      allowSearchFilter: false
    }
  };

  thesisYearFilter = {
    data: [],
    settings: {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Označiť všetko',
      unSelectAllText: 'Odznačiť všetko',
      itemsShowLimit: 2,
      allowSearchFilter: false
    }
  };

  thesisFacultyFilter = {
    data: [],
    settings: {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Označiť všetko',
      unSelectAllText: 'Odznačiť všetko',
      itemsShowLimit: 1,
      allowSearchFilter: false
    }
  };

  table = {
    columns: [],
    page: 1,
    pageSize: 30,
    totalItems: 0
  };

  paymentsData = [];

  private readonly PaymentStatusEnum = PaymentStatusEnum;

  @ViewChild(PaymentRefundComponent, {static: false}) paymentRefundComponent: PaymentRefundComponent;

  @ViewChild(PaymentEmailComponent, {static: false}) paymentEmailComponent: PaymentEmailComponent;

  constructor(
    private appCommunicationService: AppCommunicationService,
    private generalService: GeneralService
  ) {
  }

  ngOnInit() {

    this.appCommunicationService.loadingOn();

    this.generalService
      .getFaculties()
      .pipe(first())
      .pipe(mergeMap(faculties => {
        this.thesisFacultyFilter.data = [];

        faculties.forEach(faculty => {
          this.thesisFacultyFilter.data.push({
            item_id: faculty.facultyId,
            item_text: faculty.facultyCode
          });
        });
        return this.generalService.getListTypes();
      }))
      .subscribe(
        {
          next: value => {
              this.generateThesisTypeFilter(value);
              this.generateYearsFilter();
              this.appCommunicationService.loadingOff();
          },
          error: err => {
            this.appCommunicationService.loadingOff();
          }
        }
      );

  }

  loadData() {

    this.paymentsData = [
      {
        id: 1,
        student: 'Jozef Mrkvička',
        email: 'jozef.mrkvicka@tuke.sk',
        thesis: 'Bakalárska práca',
        faculty: 'Fakulta informatiky a informačných technológií',
        department: 'Ústav informatiky a matematiky',
        status: PaymentStatusEnum.Paid,
        createdDatetime: new Date(),
        paymentAmount: 10.00
      },
      {
        id: 2,
        student: 'Jozef Mrkvička',
        email: 'jozef.mrkvicka@tuke.sk',
        thesis: 'Bakalárska práca',
        faculty: 'Fakulta informatiky a informačných technológií',
        department: 'Ústav informatiky a matematiky',
        status: PaymentStatusEnum.Paid,
        createdDatetime: new Date(),
        paymentAmount: 10.00
      }
    ];

  }

  resetFilter() {
    this.filter = {
      text: '',
      year: [],
      faculty: [],
      type: [],
      page: 1,
      pageSize: 15,
      totalItems: 1
    };

    this.filterData();
  }

  generateThesisTypeFilter(thesisTypes: any[]): Promise<any> {
    this.thesisTypeFilter.data = [];
    if (thesisTypes) {
      thesisTypes.forEach(type => {
        this.thesisTypeFilter.data.push({
          item_id: type.id,
          item_text: type.translated
        });
      });
    }
    return Promise.resolve(this.thesisTypeFilter.data);
  }

  generateYearsFilter(): Promise<any> {
    this.thesisYearFilter.data = [];
    let initYear = Number.parseInt(moment(Date.now()).format('YYYY'), 0);
    const endYear = 2005;

    while (initYear >= endYear) {
      this.thesisYearFilter.data.push({
        item_id: initYear,
        item_text: initYear.toString()
      });
      initYear--;
    }
    return Promise.resolve(this.thesisYearFilter.data);
  }

  setTextFilterTimeout() {
    setTimeout(() => {
      let timeout = null;
      const textInput = document.getElementById('search') as HTMLInputElement;
      textInput.onkeyup = () => {
        clearTimeout(timeout);

        timeout = setTimeout(() => {
          this.filter.text = textInput.value;
          this.filterData();
        }, 500);
      };
    }, 1000);
  }

  onSelectAllThesisTypes(value) {
    this.filter.type.push(value);
    this.filterData();
  }

  onDeselectAllThesisTypes() {
    this.filter.type = [];
    this.filterData();
  }

  onSelectAllYears(value) {
    this.filter.year.push(value);
    this.filterData();
  }

  onDeselectAllYears() {
    this.filter.year = [];
    this.filterData();
  }

  filterData() {

  }

  setPage(pageInfo) {
    this.table.page = (pageInfo.first + pageInfo.rows) / this.table.pageSize;
    this.loadData();
  }

  clickedRefund(payment: any) {
    this.paymentRefundComponent.show(payment);
  }

  clickedEmail(payment: any) {
    this.paymentEmailComponent.show(payment);
  }

  openDetail() {

  }

}
