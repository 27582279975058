import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { LogBorrowOrderModel } from '../../../../models/log-borrow-order.model';
import { BorrowService } from '../../../../services/borrow.service';
import { BaseQueryFilterModel } from '../../../../models/base-query-filter.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'smart-borrow-notify-view',
  templateUrl: './borrow-notify-view.component.html',
  styleUrls: ['./borrow-notify-view.component.scss']
})
export class BorrowNotifyViewComponent implements OnInit {

  insertedBatchNumber: number;

  pTable = {
    pageSize: 30,
    totalItems: 0,
    page: 1
  };

  borrowHistory: LogBorrowOrderModel[] = [];

  @ViewChild('batchNumberInputEl', {static: false}) batchNumberInputEl: ElementRef;

  constructor(
    protected borrowService: BorrowService,
    protected toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.loadHistory();
  }

  setPage(pageInfo) {
    this.pTable.page = (pageInfo.first + pageInfo.rows) / 15;
  }

  loadHistory() {
    const filter: BaseQueryFilterModel = {
      page: 1,
      pageSize: 20,
      isOrderByIdDesc: false,
      isSortDesc: true,
      sortBy: 'isNotified'
    };
    this.borrowService
      .getAllLogBorrowOrders(filter)
      .subscribe(v => {
        this.borrowHistory = v.items;
        this.pTable.totalItems = v.totalItems;
        this.batchNumberInputEl.nativeElement.value = '';
      });
  }

  notify() {
    if (!this.insertedBatchNumber || this.insertedBatchNumber === 0) {
      return;
    }

    this.borrowService
      .notifyByBatchNumber(this.insertedBatchNumber)
      .subscribe(v => {
        this.loadHistory();
        this.insertedBatchNumber = 0;
        this.toastr.success('Študent notifikovaný', 'Úspech');
      }, e => {
        console.log(e);
        this.toastr.error('Nastala chyba', 'Chyba');
      });
  }

}
