import { Component, OnInit, ViewChild } from '@angular/core';
import { NewsItemModel } from '../news/news-item.model';
import { AddBookNoveltyComponent } from './add-book-novelty/add-book-novelty.component';
import { BookNoveltyModel } from "../../../models/book-novelty.model";

@Component({
  selector: 'smart-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.scss']
})
export class BooksComponent implements OnInit {

  TABS = {
    addBook: 'addBook',
    listBooks: 'listBooks'
  };

  currentTab = this.TABS.addBook;

  @ViewChild(AddBookNoveltyComponent, {static: false}) addBookComponent: AddBookNoveltyComponent;

  constructor() {
  }

  ngOnInit() {
  }

  editItem(item: BookNoveltyModel) {
    this.currentTab = this.TABS.addBook;

    setTimeout(() => {
      this.addBookComponent.editMode(item);
    }, 100);
  }

}
