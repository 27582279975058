export enum FormTypesEnum {
  acquisition = 'acquisition',
  buyDocument = 'buyDocument',
  digitalCopyRequest = 'digitalCopyRequest',
  education = 'education',
  epcCorrectionRequest = 'epcBiblioCorrection',
  feedback = 'feedback',
  mvsBooks = 'mvsBooks',
  mvsMagazine = 'mvsMagazine',
  priorityRequest = 'priorityRequest',
  searchRequest = 'searchRequest'
}

export enum FormType {
  mvsBook = 'mvsBook',
  mvsJournal = 'mvsJournal',
  search = 'search',
  seminar = 'seminar',
  buyDocument = 'buyDocument',
  feedback = 'feedback',
  epcCorrectionRequest = 'epcBiblioCorrection',
  epcDigitalCopy = 'epcDigitalCopy'
}
