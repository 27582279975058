import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEmptyString'
})
export class IsEmptyStringPipe implements PipeTransform {

  transform(value: string | null | undefined): string {
    if (value && value.length > 0) {
      return value;
    }
    return ' - '
  }

}
