import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CardsService } from '../../services/cards.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'smart-cards-view',
  templateUrl: './cards-view.component.html',
  styleUrls: ['./cards-view.component.scss']
})
export class CardsViewComponent implements OnInit {
  searchPhrase = '';

  searchedUsers: any[] = [];

  filteredUsers: any[] = [];

  selectedUser: any;

  photo: ArrayBuffer | string = null;

  withHistorical = false;

  nameFilter = '';

  @ViewChild('cardNumberEl', { static: false }) cardNumberEl: ElementRef;

  constructor(
    private cardsService: CardsService
  ) {
  }

  ngOnInit() {
  }

  reset() {
    this.searchPhrase = '';
    this.searchedUsers = [];
    this.filteredUsers = [];
    this.selectedUser = null;
    this.photo = null;
    this.withHistorical = false;
  }

  searchUser() {

    this.selectedUser = null;

    this.nameFilter = '';

    const filter = this.searchPhrase.split(' ');

    let search = '';

    if (filter.length > 1) {
      search = filter[0];
      this.nameFilter = filter[1];
    } else {
      search = this.searchPhrase;
    }

    this.cardsService
      .searchUvtUser(search, this.withHistorical)
      .pipe(first())
      .subscribe({
        next: value => {
          this.searchedUsers = value;

          this.photo = null;

          if (this.searchedUsers && this.searchedUsers.length === 1) {
            this.selectedUser = this.searchedUsers[0];

            this.getUserPhoto(this.selectedUser);
          }

          if (this.nameFilter && this.nameFilter.length > 0) {
            this.filterResultsByName();
          } else {
            this.filteredUsers = [...this.searchedUsers];
          }
        }
      });
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.photo = reader.result;
      },
      false,
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  selectUser(user: any) {
    this.selectedUser = user;
    this.getUserPhoto(this.selectedUser);
  }

  getUserPhoto(user: any) {
    if (user && user.login) {
      this.cardsService
        .getUserPhotoByLogin(user.login)
        .pipe(first())
        .subscribe({
          next: value => {
            this.createImageFromBlob(value);
          }
        });
    }
  }

  filterResultsByName() {
    this.filteredUsers = this.searchedUsers
      .filter(u => u.name.toLowerCase().indexOf(this.nameFilter.toLowerCase()) !== -1);

    if (this.filteredUsers && this.filteredUsers.length === 1) {
      this.selectUser(this.filteredUsers[0]);
    }
  }

  showAllResults() {
    this.selectedUser = null;
    this.photo = null;
    this.filteredUsers = [...this.searchedUsers];
  }
}
