import { Component, OnInit, ViewChild } from '@angular/core';
import { PortfolioHeadline, PortfolioItemModel, PortolioImage } from '../portfolio-item.model';
import { AppCommunicationService } from '../../../../services/app-communication.service';
import { LibtukePortfolioService } from '../libtuke-portfolio.service';
import { removeDiacriticsFromString } from '../../../../misc/remove-diacritics-from-string';
import { first } from 'rxjs/operators';
import { BaseDropdownItemModel } from '../../../../models/base-dropdown-item.model';
import { ToastrService } from 'ngx-toastr';
import { FileUpload } from 'primeng';
import { PRODUCTION_BUILD } from '../../../../app.settings';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

export type ArrayItems = 'priceDescription' | 'headline' | 'image';

@Component({
  selector: 'smart-add-portfolio',
  templateUrl: './add-portfolio.component.html',
  styleUrls: ['./add-portfolio.component.scss']
})
export class AddPortfolioComponent implements OnInit {

  portfolioItem: PortfolioItemModel = new PortfolioItemModel();

  origImages: PortolioImage[] = [];

  currentImages: PortolioImage[] = [];

  mainImage: File | PortolioImage = null;

  headlines: PortfolioHeadline[] = [];

  priceDescriptions: string[] = [];

  existingCategories: BaseDropdownItemModel[] = [];

  selectedDropdownCategory: BaseDropdownItemModel;

  createNewCategory = false;

  isEditing = false;

  baseImgUrl = PRODUCTION_BUILD ? 'https://lib.tuke.sk/libImages/portfolio/' : 'http://devlib.lib.tuke.sk/libImages/portfolio/';

  @ViewChild('imagesUploaderEl', { static: false }) imagesUploaderEl: FileUpload;

  constructor(
    private appCommunication: AppCommunicationService,
    private portfolioService: LibtukePortfolioService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.loadCategories();

    this.initArrays();
  }

  editMode(item: PortfolioItemModel) {
    this.portfolioItem = item;

    this.isEditing = true;

    this.priceDescriptions = this.portfolioItem.priceDescriptions.split(',');
    this.headlines = [...this.portfolioItem.headlines];
    this.origImages = [...this.portfolioItem.images];
    this.currentImages = [...this.portfolioItem.images];
    this.portfolioItem.type = item.type;

    this.sortImages();

    this.mainImage = this.origImages.find(i => i.isMain);

  }

  sortImages() {
    // sort images, first is isMain, then by orderNumber
    this.currentImages.sort((a, b) => {
      return a.orderNumber - b.orderNumber;
    });
  }

  loadCategories() {
    this.portfolioService
      .getAllByFilterAdmin({})
      .pipe(first())
      .subscribe({
        next: (data) => {
          const categories = data.items.map(i => i.type);

          categories.forEach(c => {

            const exists = this.existingCategories.find(cat => cat && cat.name && c && cat.name.toLowerCase() === c.toLowerCase());

            if (!exists) {
              this.existingCategories.push({ name: c, value: c });
            }
          });

          if (!this.isEditing) {
            setTimeout(() => {
              this.portfolioItem.type = this.existingCategories[0].name;
            }, 100);
          } else {
            const find = this.existingCategories.find(c => c.name.toLowerCase() === this.portfolioItem.type.toLowerCase());

            if (find) {
              this.selectedDropdownCategory = find;
            }
          }
        }
      });
  }

  reset() {
    this.portfolioItem = new PortfolioItemModel();
    this.priceDescriptions = [];
    this.origImages = [];
    this.currentImages = [];
    this.headlines = [];
    this.mainImage = null;
    if (this.imagesUploaderEl) {
      this.imagesUploaderEl.files = [];
    }
    this.initArrays();
  }

  hasUploadedFiles(): boolean {
    if (this.imagesUploaderEl) {
      return this.imagesUploaderEl.files.length > 0;
    }

    return false;
  }

  isCategoryCorrect(): boolean {
    return this.portfolioItem && this.portfolioItem.type && this.portfolioItem.type.length > 0;
  }

  async submit(e: Event) {
    e.preventDefault();

    const emptyInput = this.checkEmptyInputExists();

    if (!this.isCategoryCorrect()) {

      this.toastr.error('Nie je správne zvolená kategória produktu.');

      return;

    }

    if (emptyInput) {

      this.toastr.error('Vo formulári existujú prázdne polia. V tomto stave formulár nie je možné odoslať.');

      return;
    }

    if (!this.isEditing && !this.hasUploadedFiles()) {
      this.toastr.error('Vo formulári je nenahraný ani jeden obrázok. V tomto stave formulár nie je možné odoslať.');

      return;
    }

    if (!this.mainImage) {

      this.toastr.error('Prosím, označte jeden z obrázkov za hlavný.');

      return;

    }

    this.appCommunication.loadingOn('Ukladám.');

    this.currentImages.forEach((image, index) => {
      if (index === 0) {
        image.isMain = true;
      } else {
        image.isMain = false;
      }
    });

    this.portfolioItem.priceDescriptions = this.priceDescriptions.toString();
    this.portfolioItem.headlines = [...this.headlines];
    this.portfolioItem.images = this.currentImages;

    let api;
    if (this.isEditing) {
      api = this.portfolioService.update(this.portfolioItem);
    } else {
      api = this.portfolioService.create(this.portfolioItem);
    }

    // after api call, upload images
    try {

      const response = await api.pipe(first()).toPromise();

      this.toastr.success('Portfolio úspešne uložene.');

      await this.uploadImages(response);

      this.reset();

    } catch (err) {
      console.log(err);
      this.toastr.error('Portfolio sa uložiť nepodarilo.');
      this.appCommunication.loadingOff();
    }
  }

  checkEmptyInputExists(): boolean {
    let exists = false;

    this.headlines.forEach(headline => {
      Object.keys(headline)
        .forEach(key => {
          if (!headline[key] || headline[key].length <= 0) {
            exists = true;
          }
        });
    });

    this.priceDescriptions.forEach(price => {
      if (!price || price.trim().length <= 0) {
        exists = true;
      }
    });

    return exists;
  }

  uploadImages(res: PortfolioItemModel) {

    if (res && res.id) {

      const files = this.imagesUploaderEl.files;
      const mainImageIndex = files.findIndex(i => i.size === this.mainImage['size']);

      let maxOrdernumber = 0;

      if (this.origImages.length > 0) {
        maxOrdernumber = this.origImages.reduce((prev, current) => (prev.orderNumber > current.orderNumber) ? prev : current).orderNumber;
      }

      files.forEach((f, index) => {
        f['orderNumber'] = maxOrdernumber + index + 1;
        this.origImages.push({
          url: this.baseImgUrl + f.name,
          isMain: index === mainImageIndex,
          id: null,
          orderNumber: maxOrdernumber + index + 1
        });
      });

      if (mainImageIndex >= 0) {
        files[mainImageIndex]['isMain'] = true;
      }

      return new Promise((resolve, reject) => {

        files.forEach(f => {
          this.portfolioService.uploadImage(res.id, f['orderNumber'], f['isMain'], f)
            .pipe(first())
            .toPromise();
        });

        resolve();

      }).then(() => {

        this.appCommunication.loadingOff();

      }, () => {

        this.appCommunication.loadingOff();

      });

    }

  }

  initArrays() {
    this.addArrayItem('image');
    this.addArrayItem('priceDescription');
    this.addArrayItem('headline');
  }

  addArrayItem(type: ArrayItems) {
    switch (type) {
      case 'headline':
        this.headlines.push({
          title: 'Popis produktu',
          titleEn: 'Product description',
          description: '',
          descriptionEn: ''
        });
        break;
      case 'priceDescription':
        this.priceDescriptions.push('');
        break;
      case 'image':
        this.currentImages.push(new PortolioImage());
        break;
    }
  }

  removeArrayItem(type: ArrayItems, index: number) {

    let array = [];

    switch (type) {
      case 'headline':
        array = this.headlines;
        break;
      case 'priceDescription':
        array = this.priceDescriptions;
        break;
      case 'image':
        array = this.currentImages;
        break;
    }

    array.splice(index, 1);

    if (array.length === 0) {
      this.addArrayItem(type);
    }
  }

  trackByIdx(index: number, obj: any): any {
    return index;
  }

  newCategoryCreate() {
    this.createNewCategory = true;

    if (!this.portfolioItem.title) {
      return;
    }

    const text = removeDiacriticsFromString(this.portfolioItem.title).replace(' ', '');

    this.portfolioItem.type = text;
  }

  selectedCategory(category) {
    this.createNewCategory = false;
    this.portfolioItem.type = category.value.value;
  }

  removeFileFromFileUploader(file: File, e: Event) {
    const images = this.imagesUploaderEl.files;

    const findIndex = images.findIndex(i => i.size === file.size);

    if (findIndex >= 0) {
      this.imagesUploaderEl.remove(e, findIndex);
    }
  }

  checkAsMainImage(file: File) {
    this.mainImage = file;
  }

  setAsMainImage(image: PortolioImage) {
    this.portfolioService
      .setMainImage(image.id)
      .pipe(first())
      .subscribe({
        next: () => {
          this.toastr.success('Obrázok úspešne nastavený ako hlavný.');

          this.currentImages.forEach(i => {
            i.isMain = false;
          });

          image.isMain = true;

        },
        error: err => {
          this.toastr.error('Obrázok sa nepodarilo nastaviť ako hlavný.');
          console.log(err);
        }
      });
  }

  deleteImage(imageId: number) {
    this.portfolioService
      .deleteImage(this.portfolioItem.id, imageId)
      .pipe(first())
      .subscribe({
        next: () => {
          const index = this.currentImages.findIndex(i => i.id === imageId);
          if (index >= 0) {
            this.currentImages.splice(index, 1);
          }
          this.toastr.success('Obrázok úspešne odstránený.');
        },
        error: err => {
          this.toastr.error('Obrázok sa nepodarilo odstrániť.');
          console.log(err);
        }
      });
  }

  drop(event: CdkDragDrop<string[]>) {

    moveItemInArray(this.currentImages, event.previousIndex, event.currentIndex);

    // update orderNumber
    this.currentImages.forEach((image, index) => {

      if (image.orderNumber !== index + 1) {
        image.orderNumber = index + 1;

        this.portfolioService.changeImageOrder(image.id, image.orderNumber)
          .pipe(first())
          .subscribe({
            next: () => {
              console.log('Order number updated');
            },
            error: err => {
              console.error('Order number update error', err);
            }
          });
      }

    });
  }

  compareImagesOrder() {
    // compare orderNUmber of currentImages and origImages, if different, call updateOrderNumber for each image
    this.currentImages.forEach((image, index) => {
      if (image.orderNumber !== this.origImages[index].orderNumber) {
        this.portfolioService.changeImageOrder(image.id, image.orderNumber)
          .pipe(first())
          .subscribe({
            next: () => {
              console.log('Order number updated');
            },
            error: err => {
              console.error('Order number update error', err);
            }
          });
      }
    });
  }
}
