import { Component, OnInit } from '@angular/core';
import { BuyDocumentOrderPaidBy, BuyDocumentTypes } from './buy-document-types';
import { OKF_SUPPORT } from '../../../../app-constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseForm } from "../../base-form";
import { FormType } from "../../form-types.enum";
import { FormsService } from "../../../../services/forms.service";
import { ToastrService } from "ngx-toastr";
import { FormBuyDocumentRequest } from "../../../../models/forms/FormBuyDocumentRequest";

@Component({
  selector: 'app-buy-document-request-form',
  templateUrl: './buy-document-request-form.component.html',
  styleUrls: ['./buy-document-request-form.component.scss']
})
export class BuyDocumentRequestFormComponent extends BaseForm implements OnInit {

  okfSupport = OKF_SUPPORT;

  form: FormGroup;

  buyDocument: FormBuyDocumentRequest;

  constructor(
    protected fb: FormBuilder,
    protected formService: FormsService,
    protected toastr: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      book: this.fb.group({
        title: ['', [Validators.required]],
        isbnIssn: [''],
        url: [''],
        comment: ['']
      })
    });
  }

  async submit(e: Event) {
    e.preventDefault();

    this.buyDocument = new FormBuyDocumentRequest();
    this.buyDocument = this.fullFillUserForm(this.buyDocument);
    this.buyDocument = this.fullFillDataFromForm(this.buyDocument, this.form.get('book').value);

    const req = await this.formService
      .create(FormType.buyDocument, this.buyDocument).toPromise();

    if (req) {
      this.toastr.success('Záznam úspešne uložený.');
      this.form.reset();
      return;
    }

    this.toastr.error('Záznam sa uložiť nepodarilo.');
  }

  downloadForm() {
    console.log('download form');
  }

}
