import { NgModule } from "@angular/core";
import { FloatNumberFormatDirective } from "./floatNumberFormat.directive";
import { FocusDirective } from "./focus.directive";
import { RequestStateDirective } from './request-state.directive';

@NgModule({
  exports: [
    RequestStateDirective
  ],
  declarations: [
    FloatNumberFormatDirective,
    FocusDirective,
    RequestStateDirective
  ]
})

export class CustomDirectivesModule {}
