import { SYSTEM_FULL_NAME_ALFRED, SYSTEM_NAME_ALFRED } from './constants';

export const SYSTEM_NAME = SYSTEM_NAME_ALFRED;

export const SYSTEM_FULL_NAME = SYSTEM_FULL_NAME_ALFRED;

// Set to true when building for production server
export const PRODUCTION_BUILD = false;

// Set to true to use HTTPS protocol for PROD build
export const PROD_BUILD_USE_HTTPS = false;

// Set to true when building for development server
export const DEVAPI_BUILD = true;

// Set to true to use HTTPS protocol for DEV build
export const DEV_BUILD_USE_HTTPS = true;

// Set to true when application's backend runs locally
export const LOCAL_BACKEND = false;

// Set to true when auth (front + back) runs locally
export const LOCAL_AUTH = false;

// Set to true when developing locally for local app redirection
export const LOCAL_DEVELOPMENT = false;
