export const DigitalCopyRequestTypes = {
  qualification: {
    text: 'Kvalifikačný postup (dizertácia, habilitácia, ...)',
    value: 1
  },
  completization: {
    text: 'Kompletizácia publikačnej činnosti',
    value: 2
  },
  others: {
    text: 'Iné',
    value: 3
  }
};

export const EpcDigitalCopyTypes = [
  {
    text: DigitalCopyRequestTypes.qualification.text,
    value: DigitalCopyRequestTypes.qualification.value
  },
  {
    text: DigitalCopyRequestTypes.completization.text,
    value: DigitalCopyRequestTypes.completization.value
  },
  {
    text: DigitalCopyRequestTypes.others.text,
    value: DigitalCopyRequestTypes.others.value
  }
];

