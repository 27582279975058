import { Component, OnInit, ViewChild } from '@angular/core';
import { AddFaqComponent } from './add-faq/add-faq.component';
import { FaqItemModel } from './faq-item.model';

@Component({
  selector: 'smart-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  TABS = {
    addFaq: 'addFaq',
    listFaq: 'listFaq'
  };

  currentTab = this.TABS.addFaq;

  @ViewChild(AddFaqComponent, {static: false}) addFaqComponent: AddFaqComponent;

  constructor() {
  }

  ngOnInit() {
  }

  editItem(item: FaqItemModel) {
    this.currentTab = this.TABS.addFaq;

    setTimeout(() => {
      this.addFaqComponent.editMode(item);
    }, 100);
  }

}
