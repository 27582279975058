import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppCommunicationService } from './app-communication.service';
import {
  getAllLogBorrowOrdersUrl,
  getAllOrdersWithReaderUrl,
  getAllWarehouseLayoutUrl, getNotifyByBatchNumberUrl,
  getUpdateWarehouseLayoutUrl,
  printLabelUrl
} from '../app.urls';
import { Observable } from 'rxjs';
import { BorrowModel } from '../models/borrow.model';
import { map, catchError } from 'rxjs/operators';
import { WarehouseLayoutModel } from '../models/warehouseLayout.model';
import { AuthenticationService } from '../../core-frontend-common/auth/authentication.service';
import { LogBorrowOrderModel } from '../models/log-borrow-order.model';
import { BaseQueryFilterModel } from '../models/base-query-filter.model';

@Injectable({
  providedIn: 'root',
})
export class BorrowService {

  constructor(protected httpClient: HttpClient, protected _authenticationService: AuthenticationService) {
  }

  getAllOrdersWithReader(): Observable<BorrowModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this._authenticationService.checkUser()) {
      return this.httpClient.get(getAllOrdersWithReaderUrl(), httpOptions).pipe(map((res: any) => res as BorrowModel[]));
    }
  }

  printLabel(section: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this._authenticationService.checkUser()) {
      return this.httpClient.post(printLabelUrl(section), {}, httpOptions)
        .pipe(map(res => {
          return res;
        }, err => {
          return err;
        }))
        .pipe(catchError(e => {
          return e;
        }));
    }
  }

  getAllWarehouseLayout(): Observable<WarehouseLayoutModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this._authenticationService.checkUser()) {
      return this.httpClient.get(getAllWarehouseLayoutUrl(), httpOptions).pipe(map((res: any) => res.items as WarehouseLayoutModel[]));
    }
  }

  updateWarehouseLayout(warehouse: WarehouseLayoutModel): Observable<WarehouseLayoutModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this._authenticationService.checkUser()) {
      return this.httpClient.put(getUpdateWarehouseLayoutUrl(warehouse.id), warehouse, httpOptions).pipe(map((res: any) => res as WarehouseLayoutModel));
    }
  }

  getAllLogBorrowOrders(filter: BaseQueryFilterModel): Observable<{totalItems: number, items: LogBorrowOrderModel[]}> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this._authenticationService.checkUser()) {
      return this.httpClient.post(getAllLogBorrowOrdersUrl(), filter, httpOptions).pipe(map((res: any) => {
        return {
          totalItems: res.totalItems,
          items: res.items
        };
      }));
    }
  }

  notifyByBatchNumber(batchNumber: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this._authenticationService.checkUser()) {
      return this.httpClient.post(getNotifyByBatchNumberUrl(batchNumber), {}, httpOptions).pipe(map(res => res as LogBorrowOrderModel[]));
    }
  }


}
