import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './core-frontend-common/template/shared/layout/main/main.component';
import { LayoutModule } from './core-frontend-common/template/shared/layout/layout.module';
import { UserProfileViewComponent } from './+app-custom/views/user-profile-view/user-profile-view.component';
import { AuthGuard } from './core-frontend-common/auth/guards/auth.guard';
import { DevComponent } from './+app-custom/components/dev/dev.component';
import { DashboardComponent } from './+app-custom/views/dashboard/dashboard.component';
import { CardsViewComponent } from './+app-custom/views/cards-view/cards-view.component';
import { AutorityViewComponent } from './+app-custom/views/autority-view/autority-view.component';
import { MailerViewComponent } from './+app-custom/views/mailer-view/mailer-view.component';
import { InterStatsViewComponent } from './+app-custom/views/inter-stats-view/inter-stats-view.component';
import { BorrowViewComponent } from './+app-custom/views/warehouse-view/views/borrow-view/borrow-view.component';
import { WarehouseViewComponent } from './+app-custom/views/warehouse-view/views/warehouse-view/warehouse-view.component';
import { ReadercardViewComponent } from './+app-custom/views/warehouse-view/views/readercard-view/readercard-view.component';
import { DigitalBooksCatalogComponent } from './+app-custom/views/digital-books-view/digital-books-catalog/digital-books-catalog.component';
import { DigitalBooksFormComponent } from './+app-custom/views/digital-books-view/digital-books-form/digital-books-form.component';
import { BorrowNotifyViewComponent } from './+app-custom/views/warehouse-view/views/borrow-notify-view/borrow-notify-view.component';
import { RequestsViewComponent } from './+app-custom/views/requests-view/requests-view.component';
import { ArticlesManagementViewComponent } from './+app-custom/views/articles-management-view/articles-management-view.component';
import { LibtukeComponent } from './+app-custom/views/libtuke/libtuke.component';
import {
  PublicBorrowReadingCardComponent
} from './+app-custom/views/public-borrow/public-borrow-reading-card/public-borrow-reading-card.component';
import { UserRoles } from './+app-custom/constants';
import { PaymentsViewComponent } from "./+app-custom/views/payments-view/payments-view.component";

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full', canActivate: [AuthGuard] },
  {
    path: '',
    component: MainComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dev', component: DevComponent, pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent, data: { pageTitle: 'dashboard' }, pathMatch: 'full' },
      {
        path: 'warehouse',
        component: WarehouseViewComponent,
        data: { pageTitle: 'warehouse', roles: [UserRoles.Superadmin, UserRoles.AlfredWarehouse] },
        pathMatch: 'full'
      },
      {
        path: 'ebooks',
        component: DigitalBooksCatalogComponent,
        data: { pageTitle: 'ebooks', roles: [UserRoles.Superadmin, UserRoles.AlfredDigi] },
        pathMatch: 'full'
      },
      {
        path: 'ebooks/new',
        component: DigitalBooksFormComponent,
        data: { pageTitle: 'form', roles: [UserRoles.Superadmin, UserRoles.AlfredDigi] },
        pathMatch: 'full'
      },
      { path: 'cards', component: CardsViewComponent, data: { pageTitle: 'cards', roles: [UserRoles.Superadmin, UserRoles.AlfredAdmin] }, pathMatch: 'full' },
      { path: 'autority', component: AutorityViewComponent, data: { pageTitle: 'autority', roles: [UserRoles.Superadmin] }, pathMatch: 'full' },
      { path: 'mailer', component: MailerViewComponent, data: { pageTitle: 'mailer', roles: [UserRoles.Superadmin] }, pathMatch: 'full' },
      { path: 'stats', component: InterStatsViewComponent, data: { pageTitle: 'stats', roles: [UserRoles.Superadmin] }, pathMatch: 'full' },
      { path: 'profile', component: UserProfileViewComponent, data: { pageTitle: 'profile', roles: UserRoles.All }, pathMatch: 'full' },
      {
        path: 'borrow', component: BorrowViewComponent,
        data: { pageTitle: 'borrow', roles: [UserRoles.Superadmin, UserRoles.AlfredBorrow] }, pathMatch: 'full'
      },
      {
        path: 'readercard', component: ReadercardViewComponent,
        data: { pageTitle: 'readercard', roles: [UserRoles.Superadmin] }, pathMatch: 'full'
      },
      {
        path: 'borrow-notify', component: BorrowNotifyViewComponent,
        data: { pageTitle: 'borrowNotify', roles: [UserRoles.Superadmin, UserRoles.AlfredBorrow] }, pathMatch: 'full'
      },
      {
        path: 'forms',
        loadChildren: () => import('./+app-custom/views/forms-view/forms-view.module').then(mod => mod.FormsViewModule),
        data: {roles: UserRoles.All}
      },
      {
        path: 'requests',
        component: RequestsViewComponent,
        data: { pageTitle: 'requests', roles: [UserRoles.Superadmin, UserRoles.AlfredAdmin] }, pathMatch: 'full'
      },
      {
        path: 'articles',
        component: ArticlesManagementViewComponent,
        data: { pageTitle: 'articles', roles: [UserRoles.Superadmin] }
      },
      {
        path: 'libtuke',
        loadChildren: () => import('./+app-custom/views/libtuke/libtuke.module').then(mod => mod.LibtukeModule),
        data: { pageTitle: 'libtuke', roles: [UserRoles.Superadmin] }
      },
      {
        path: 'public-borrow/reading-card',
        component: PublicBorrowReadingCardComponent,
        data: { pageTitle: 'reading card', roles: UserRoles.All }
      },
      {
        path: 'payments',
        component: PaymentsViewComponent,
        data: { pageTitle: 'payments', roles: [UserRoles.Superadmin] }
      }
    ],
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [LayoutModule, RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule {}
