export const OVI_SUPPORT = {
  name: 'Ing. Čopjak',
  email: 'marek.copjak@tuke.sk',
  phone: '7722'
};

export const OKF_SUPPORT = {
  name: 'Mgr. Ondríková',
  email: 'jarmila.ondrikova@tuke.sk',
  phone: '7712'
};
