import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DigitalBooksService } from '../../../services/digital-books.service';
import { DigitalBookModel } from '../../../models/digital-book.model';
import {saveAs as importedSaveAs} from 'file-saver';

@Component({
  selector: 'smart-digital-books-catalog',
  templateUrl: './digital-books-catalog.component.html',
  styleUrls: ['./digital-books-catalog.component.scss']
})
export class DigitalBooksCatalogComponent implements OnInit, AfterViewInit {

  data: DigitalBookModel[] = [];

  inputFilter = '';

  loading = false;

  SORT_TYPES = {
    DESC: 'DESC',
    ASC: 'ASC'
  };

  pTable = {
    pageSize: 15,
    totalItems: 0,
    page: 1,
    sort: {
      createdDatetime: this.SORT_TYPES.DESC
    }
  };

  constructor(
    protected digitalBooksService: DigitalBooksService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    let timeout = null;
    const textInput = document.getElementById('search') as HTMLInputElement;
    textInput.onkeyup = () => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        this.inputFilter = textInput.value;
        this.getData();
      }, 500);
    };
  }

  clearSearchInput() {
    this.inputFilter = '';
    this.getData();
  }

  setPage(pageInfo) {
    this.pTable.page = (pageInfo.first + pageInfo.rows) / 15;
    this.getData();
  }

  toggleDateSortFilter() {
    if (this.pTable.sort.createdDatetime === this.SORT_TYPES.DESC) {
      this.pTable.sort.createdDatetime = this.SORT_TYPES.ASC;
      this.getData();
      return;
    }
    this.pTable.sort.createdDatetime = this.SORT_TYPES.DESC;
    this.getData();
    return;
  }

  getData() {

    this.loading = true;

    const queryDesigner = {
      search: [],
      page: this.pTable.page,
      pageSize: this.pTable.pageSize,
      orderBy: [
        {
          'field': 'CreatedDatetime',
          'order': this.pTable.sort.createdDatetime
        }
      ]
    };

    if (this.inputFilter.length > 0) {

      const stringArray = this.inputFilter.split(/(\s+)/).filter( function(e) { return e.trim().length > 0; } );
      for (let i = 0; i < stringArray.length; i++) {
        queryDesigner.search.push({
          fields: [],
          value: ''
        });
        queryDesigner.search[i].fields = ['Authors.Author.Surname', 'Title', 'Doi', 'Isbn', 'Issn', 'OtherIdentifier'];
        queryDesigner.search[i].value = stringArray[i];
      }
    }

    this.digitalBooksService
      .getAllDigitalBooks(queryDesigner)
      .subscribe(v => {
        this.pTable.totalItems = v.totalItems;
        this.data = v.items;
        this.loading = false;
      }, () => {
        this.loading = false;
      });
  }

  downloadFile(fileMetadataId: number) {
    this.digitalBooksService
      .downloadFile(fileMetadataId)
      .subscribe(res => {
        importedSaveAs(res);
      });
  }

}
