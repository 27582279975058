import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LIBADMIN_URL } from '../../../app.urls';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppCommunicationService } from '../../../services/app-communication.service';
import { AuthenticationService } from '../../../../core-frontend-common/auth/authentication.service';
import { FaqItemModel } from './faq-item.model';

@Injectable()

export class LibtukeFaqService {

  baseUrl = `${LIBADMIN_URL}/faq`;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {
  }

  getAllBySystem(system: string): Observable<{ totalItems: number, items: FaqItemModel[] }> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .get(`${this.baseUrl}/getFaqBySystem/${system}`, httpOptions)
      .pipe(map((res: any) => {
        return {
          totalItems: res.totalItems,
          items: res.items
        };
      }));
  }

  create(faq: FaqItemModel): Observable<FaqItemModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json'
      })
    };
    if (this.authService.checkUser()) {
      return this.http
        .post(`${this.baseUrl}/AddFaq`, faq, httpOptions)
        .pipe(map((res) => res as FaqItemModel));
    }
  }

  update(faq: FaqItemModel): Observable<FaqItemModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json'
      })
    };
    if (this.authService.checkUser()) {
      return this.http
        .put(`${this.baseUrl}/updateFaq/${faq.id}`, faq, httpOptions)
        .pipe(map((res) => res as FaqItemModel));
    }
  }

  delete(id: number): Observable<FaqItemModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json'
      })
    };
    if (this.authService.checkUser()) {
      return this.http
        .delete(`${this.baseUrl}/deleteFaq/${id}`, httpOptions)
        .pipe(map((res) => res as FaqItemModel));
    }
  }

}
