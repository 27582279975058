import { BaseFormEntityRequest } from './BaseFormEntityRequest';

export class FormSearchRequest extends BaseFormEntityRequest {
  description: string;
  keywordsSk: string;
  keywordsEn: string;
  yearRange: string;
  documentLanguage: string;
  id?: number;
  responsibleId?: number;
}
