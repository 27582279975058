import { Component, OnInit } from '@angular/core';
import { ReaderCardFullModel } from '../../../models/reader-card-full.model';
import { CardsService } from '../../../services/cards.service';
import { ReaderCardBaseComponent } from '../../../components/reader-card/reader-card-base.component';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AppCommunicationService } from '../../../services/app-communication.service';
import { I18nService } from "../../../../core-frontend-common/template/shared/i18n";

@Component({
  selector: 'smart-public-borrow-reading-card',
  templateUrl: './public-borrow-reading-card.component.html',
  styleUrls: ['./public-borrow-reading-card.component.scss']
})
export class PublicBorrowReadingCardComponent extends ReaderCardBaseComponent implements OnInit {

  cardNumber = '';

  noCard = false;

  searchedCard: ReaderCardFullModel;

  constructor(
    protected cardsService: CardsService,
    protected message: ToastrService,
    protected appCommunicationService: AppCommunicationService,
    protected i18n: I18nService
  ) {
    super(cardsService, message, appCommunicationService, i18n);

    this.setAdminView(false);
  }

  ngOnInit() {

    this.loadCurrentUserCard();

  }

  loadCurrentUserCard() {
    this.noCard = false;

    this.cardsService
      .getCurrentUserCardNumber()
      .pipe(first())
      .subscribe({
        next: value => {
          if (value.cck) {
            this.cardNumber = value.cck;
            this.getCardByCardNumber();
          } else {
            this.noCard = true;
          }
        },
        error: err => {
          console.log(err);
          this.noCard = true;
        }
      });
  }

  async clickedCreateCard() {

    const res = await this.createCard();

    if (res) {
      location.reload();
    }
  }

  async clickedChangeCardNumber() {
    const res = await this.changeCardBarcode();

    if (res) {
      location.reload();
    }
  }

}
