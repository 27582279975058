import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'smart-libtuke',
  templateUrl: './libtuke.component.html',
  styleUrls: ['./libtuke.component.scss']
})
export class LibtukeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
