import { Injectable } from '@angular/core';
import { ThemesEnum, themesList } from "./themes-list";
import { Theme } from "./theme";
import { AuthenticationService } from "../../core-frontend-common/auth/authentication.service";

@Injectable()
export class AppThemeService {

  currentTheme: Theme;

  LOCAL_STORAGE_KEY = 'app-theme';

  constructor() { }

  initTheme() {
    this.getFromLocalStorage();

    // check current user
    const user = AuthenticationService.getCurrentUser();

    if (user && user.facultyCode) {
      const faculty = user.facultyCode;

      // check if faculty is in ThemesEnum
      const theme = ThemesEnum[faculty.toUpperCase() as keyof typeof ThemesEnum];

      if (theme) {
        this.setTheme(theme);
        return;
      }

    }

    this.setTheme(ThemesEnum.DEFAULT);
  }

  getThemeByName(name: ThemesEnum) {
    return themesList.find(theme => theme.name === name);
  }

  getCurrentTheme(): Theme {
    return this.currentTheme;
  }

  getThemes(): Theme[] {
    return themesList;
  }

  setTheme(name: ThemesEnum) {
    this.currentTheme = this.getThemeByName(name);

    Object.keys(this.currentTheme.properties).forEach(property => {
      document.documentElement.style.setProperty(
        property,
        this.currentTheme.properties[property]
      );
    });

    this.storeToLocalStorage();
  }

  storeToLocalStorage() {
    localStorage.setItem(this.LOCAL_STORAGE_KEY, this.currentTheme.name);
  }

  getFromLocalStorage() {
    let themeName = localStorage.getItem(this.LOCAL_STORAGE_KEY) as ThemesEnum;

    if (!themeName) {
      themeName = ThemesEnum.DEFAULT;
    }

    this.setTheme(themeName);
  }
}
