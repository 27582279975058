import { NgModule } from '@angular/core';
import { ReaderCardMismatchFormComponent } from './reader-card-mismatch-form.component';
import { I18nModule } from '../../../../core-frontend-common/template/shared/i18n/i18n.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    I18nModule,
    FormsModule
  ],
  exports: [
    ReaderCardMismatchFormComponent
  ],
  declarations: [
    ReaderCardMismatchFormComponent
  ]
})

export class ReaderCardMismatchFormModule {
}
