import { NgModule } from "@angular/core";
import { CustomDatepickerComponent } from "./custom-datepicker.component";
import { CustomYearPickerComponent } from "./custom-year-picker/custom-year-picker.component";
import { MatDatepickerModule, MatFormFieldModule, MatInputModule } from "@angular/material";
import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { CustomMonthPickerComponent } from "./custom-month-picker/custom-month-picker.component";
import { CustomRegularDatePickerComponent } from "./custom-regular-date-picker/custom-regular-date-picker.component";

@NgModule({
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    CommonModule
  ],
  exports: [
    CustomDatepickerComponent
  ],
  declarations: [
    CustomDatepickerComponent,
    CustomYearPickerComponent,
    CustomMonthPickerComponent,
    CustomRegularDatePickerComponent
  ]
})

export class CustomDatepickerModule {}
