import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppCommunicationService } from './app-communication.service';
import { getAllByFilterBookNoveltyUrl, getCreateBookNoveltyUrl, getDeleteBookNoveltyUrl, getUpdateBookNoveltyUrl } from "../app.urls";
import { BookNoveltyModel } from '../models/book-novelty.model';
import { AuthenticationService } from '../../core-frontend-common/auth/authentication.service';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BookNoveltyService {

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) { }

  create(book: BookNoveltyModel): Observable<BookNoveltyModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authService.checkUser()) {
      return this.http.post(getCreateBookNoveltyUrl(), book, httpOptions).pipe(map(r => r as BookNoveltyModel));
    }
  }

  update(book: BookNoveltyModel): Observable<BookNoveltyModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authService.checkUser()) {
      return this.http.post(getUpdateBookNoveltyUrl(), book, httpOptions).pipe(map(r => r as BookNoveltyModel));
    }
  }

  delete(id: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authService.checkUser()) {
      return this.http.delete(getDeleteBookNoveltyUrl(id), httpOptions);
    }
  }

  getAllByFilter(filter: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${AppCommunicationService.getCurrentUser().access_token}`,
        'Content-Type': 'application/json',
      }),
    };
    if (this.authService.checkUser()) {
      return this.http.post(getAllByFilterBookNoveltyUrl(), filter, httpOptions);
    }
  }
}
