import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acquisitions-form',
  templateUrl: './acquisitions-form.component.html',
  styleUrls: ['./acquisitions-form.component.scss']
})
export class AcquisitionsFormComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  downloadProceedingForm1() {
    window.open('assets/files/zborniky.doc', '_blank');
  }

  downloadProceedingForm2() {
    window.open('assets/files/zborniky_online.doc', '_blank');
  }
}
