import { FormTypesEnum } from './+app-custom/views/forms-view/form-types.enum';
import { SYSTEM_NAME_AUTH, UserRoles } from "./+app-custom/constants";
import { getBaseFrontendUrl } from "./+app-custom/app.urls";

export const NavigationItems = [
  {
    title: 'modules',
    tags: 'modules',
    icon: 'fal fa-angle-down',
    routerLink: 'null',
    externalUrl: `${getBaseFrontendUrl(SYSTEM_NAME_AUTH)}`,
    roles: UserRoles.All
  },
  {
    title: 'Hlavná stránka',
    tags: 'application intel',
    routerLink: '/dashboard'
  },
  {
    title: 'Výpožička',
    tags: 'application intel',
    icon: 'fal fa-book',
    items: [
      {
        title: 'Vytvorenie čit. preukazu',
        tags: 'application intel',
        icon: 'fal fa-folder',
        routerLink: '/public-borrow/reading-card',
        roles: UserRoles.All
      }
    ],
    roles: UserRoles.All
  },
  {
    title: 'Výpožička admin',
    tags: 'application intel',
    icon: 'fal fa-book',
    items: [
      {
        title: 'Objednávky',
        tags: 'application intel',
        icon: 'fal fa-folder',
        routerLink: '/borrow',
        roles: [UserRoles.Superadmin, UserRoles.AlfredWarehouse]

      },
      {
        title: 'Rozloženie skladu',
        tags: 'application intel',
        icon: 'fal fa-map',
        routerLink: '/warehouse',
        roles: [UserRoles.Superadmin, UserRoles.AlfredWarehouse]

      },
      {
        title: 'Notifikovať študenta',
        tags: 'application intel',
        icon: 'fal fa-user',
        routerLink: '/borrow-notify',
        roles: [UserRoles.Superadmin, UserRoles.AlfredWarehouse]

      },
      {
        title: 'Vytvor čitateľa',
        tags: 'application intel',
        icon: 'fal fa-user',
        routerLink: '/readercard',
        roles: [UserRoles.Superadmin, UserRoles.AlfredWarehouse]

      }
    ],
    roles: [UserRoles.Superadmin, UserRoles.AlfredWarehouse]
  },

  {
    title: 'Formuláre',
    tags: 'application intel',
    icon: 'fal fa-book',
    items: [
      {
        title: 'MVS Knihy',
        tags: 'application intel',
        icon: 'fal fa-folder',
        routerLink: `/forms/${FormTypesEnum.mvsBooks}`,
        roles: UserRoles.All
      },
      {
        title: 'MVS časopisy',
        tags: 'application intel',
        icon: 'fal fa-map',
        routerLink: `/forms/${FormTypesEnum.mvsMagazine}`,
        roles: UserRoles.All
      },
      {
        title: 'Žiadosť o rešerš',
        tags: 'application intel',
        icon: 'fal fa-map',
        routerLink: `/forms/${FormTypesEnum.searchRequest}`,
        roles: UserRoles.All
      },
      {
        title: 'Feedback',
        tags: 'application intel',
        icon: 'fal fa-map',
        routerLink: `/forms/${FormTypesEnum.feedback}`,
        roles: UserRoles.All
      },
      {
        title: 'Vzdelávanie a školenia prihláška',
        tags: 'application intel',
        icon: 'fal fa-map',
        routerLink: `/forms/${FormTypesEnum.education}`,
        roles: UserRoles.All
      },
      {
        title: 'Potvrdenie o prevzatí zborníka',
        tags: 'application intel',
        icon: 'fal fa-map',
        routerLink: `/forms/${FormTypesEnum.acquisition}`,
        roles: UserRoles.All
      },
      {
        title: 'Poskytnutie dig. kópií',
        tags: 'application intel',
        icon: 'fal fa-map',
        routerLink: `/forms/${FormTypesEnum.digitalCopyRequest}`,
        roles: UserRoles.All
      },
      {
        title: 'Korekcia záznamu EPC',
        tags: 'application intel',
        icon: 'fal fa-map',
        routerLink: `/forms/${FormTypesEnum.epcCorrectionRequest}`,
        roles: UserRoles.All
      },
      {
        title: 'Návrh na zakúpenie dokumentov',
        tags: 'application intel',
        icon: 'fal fa-map',
        routerLink: `/forms/${FormTypesEnum.buyDocument}`,
        roles: UserRoles.All
      }
    ],
    roles: UserRoles.All
  },
  {
    title: 'Žiadosti',
    tags: 'application intel',
    routerLink: '/requests',
    icon: 'fal fa-book',
    roles: [UserRoles.Superadmin, UserRoles.AlfredAdmin]
  },
  {
    title: 'Články',
    tags: 'application intel',
    routerLink: '/articles',
    icon: 'fal fa-book',
    roles: [UserRoles.Superadmin]
  },
  {
    title: 'Digit. kníh',
    tags: 'application intel',
    icon: 'fal fa-book',
    items: [
      {
        title: 'Formulár',
        tags: 'application intel',
        icon: 'fal fa-paragraph',
        routerLink: '/ebooks/new',
        roles: [UserRoles.Superadmin, UserRoles.AlfredDigi]

      },
      {
        title: 'Katalóg',
        tags: 'application intel',
        icon: 'fal fa-bookmark',
        routerLink: '/ebooks',
        roles: [UserRoles.Superadmin, UserRoles.AlfredDigi]
      }
    ],
    roles: [UserRoles.Superadmin, UserRoles.AlfredDigi]
  },
  {
    title: 'Karty',
    tags: 'application intel',
    icon: 'fal fa-id-card',
    routerLink: '/cards',
    roles: [UserRoles.Superadmin, UserRoles.AlfredAdmin]

  },
  {
    title: 'Autority',
    tags: 'application intel',
    icon: 'fal fa-user',
    routerLink: '/autority',
    roles: [UserRoles.Superadmin]

  },
  {
    title: 'Mailovač',
    tags: 'application intel',
    icon: 'fal fa-envelope',
    routerLink: '/mailer',
    roles: [UserRoles.Superadmin]

  },
  {
    title: 'Štatistiky',
    tags: 'application intel',
    icon: 'fal fa-chart-bar',
    routerLink: '/stats',
    roles: [UserRoles.Superadmin]
  },
  {
    title: 'LibTuke',
    tags: 'application intel',
    icon: 'fal fa-book',
    items: [
      {
        title: 'Knihy',
        tags: 'application intel',
        icon: 'fal fa-folder',
        routerLink: '/libtuke/books',
        roles: [UserRoles.AlfredAdmin, UserRoles.Superadmin, UserRoles.AlfredDigi]
      },
      {
        title: 'Portfólio',
        tags: 'application intel',
        icon: 'fal fa-folder',
        routerLink: '/libtuke/portfolio',
        roles: [UserRoles.AlfredAdmin, UserRoles.Superadmin, UserRoles.AlfredDigi]
      },
      {
        title: 'Novinky',
        tags: 'application intel',
        icon: 'fal fa-folder',
        routerLink: '/libtuke/news',
        roles: [UserRoles.AlfredAdmin, UserRoles.Superadmin, UserRoles.AlfredDigi]
      },
      {
        title: 'FAQ',
        tags: 'application intel',
        icon: 'fal fa-folder',
        routerLink: '/libtuke/faq',
        roles: [UserRoles.AlfredAdmin, UserRoles.Superadmin, UserRoles.AlfredDigi]
      }
    ],
    roles: [UserRoles.AlfredAdmin, UserRoles.Superadmin, UserRoles.AlfredDigi]
  },
  {
    title: 'Platby',
    tags: 'application intel',
    icon: 'fal fa-chart-bar',
    routerLink: '/payments',
    roles: [UserRoles.Superadmin]
  }
];
