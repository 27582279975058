import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: 'smart-reader-card-mismatch-form',
  templateUrl: './reader-card-mismatch-form.component.html',
  styleUrls: ['./reader-card-mismatch-form.component.scss']
})
export class ReaderCardMismatchFormComponent implements OnInit {

  barcode = '';

  @Input() set setBarcode(barcode: string) {
    this.barcode = barcode;
    this.changedBarcodeValue();
  }

  @Output()
  barcodeChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
  }

  changedBarcodeValue() {
    this.barcodeChanged.emit(this.barcode);
  }

}
