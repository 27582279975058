import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { DialogButtonModel } from './models/dialog-button.model';

@Component({
  selector: 'smart-base-modal-dialog',
  templateUrl: './base-modal-dialog.component.html',
  styleUrls: ['./base-modal-dialog.component.scss']
})
export class BaseModalDialogComponent implements OnInit {
  title: string;
  content: string;
  template;
  buttons: DialogButtonModel[] = [];

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
  }

}
