import { Component, OnInit } from '@angular/core';
import { FormTypesEnum } from './form-types.enum';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'smart-forms-view',
  templateUrl: './forms-view.component.html',
  styleUrls: ['./forms-view.component.scss']
})
export class FormsViewComponent implements OnInit {

  currentForm: FormTypesEnum;

  constructor(
    protected activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {

    this.activatedRoute
      .params
      .subscribe(v => {
        if (v.type) {
          this.currentForm = v.type as FormTypesEnum;
        }
      });

  }

}
