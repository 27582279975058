import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DialogModel } from '../components/dialog/models/dialog.model';
import { BaseModalDialogService } from '../components/dialog/base-modal-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  constructor(
    protected notificationService: ToastrService,
    protected baseModalService: BaseModalDialogService
  ) { }

  successNotification(text: string, title?: string) {
    this.notificationService
      .success(text, title);
  }

  errorNotification(text: string, title?: string) {
    this.notificationService
      .error(text, title);
  }

  openDialog(data: DialogModel) {
    this.baseModalService
      .openDialog(data);
  }
}
