import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { DialogModel } from './models/dialog.model';
import { BaseModalDialogComponent } from './base-modal-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class BaseModalDialogService {

  constructor(
    protected bsModalService: BsModalService
  ) { }

  openDialog(data: DialogModel) {
    this.bsModalService
      .show(BaseModalDialogComponent, {initialState: data, backdrop: 'static'});
  }
}
