import { Component, OnInit } from '@angular/core';
import { CroppedEvent } from 'ngx-photo-editor';
import { BookNoveltyModel } from '../../../../models/book-novelty.model';
import { BookNoveltyService } from '../../../../services/book-novelty.service';
import { ToastrService } from 'ngx-toastr';
import { AppCommunicationService } from '../../../../services/app-communication.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'smart-add-book-novelty',
  templateUrl: './add-book-novelty.component.html',
  styleUrls: ['./add-book-novelty.component.scss']
})
export class AddBookNoveltyComponent implements OnInit {
  book: BookNoveltyModel = new BookNoveltyModel();

  imageChangedEvent: any;

  base64: any;

  isEditing = false;

  constructor(
    private bookService: BookNoveltyService,
    private toastr: ToastrService,
    private appCommunication: AppCommunicationService
  ) {
  }

  ngOnInit() {
  }

  editMode(item: BookNoveltyModel) {
    this.book = item;

    this.base64 = item.coverImageBlob;

    this.isEditing = true;
  }

  fileChangeEvent(event: any) {
    this.imageChangedEvent = event;
  }

  imageCropped(event: CroppedEvent) {
    this.base64 = event.base64;
  }

  removeImage() {
    this.base64 = null;
    this.imageChangedEvent = null;
  }

  reset() {
    this.removeImage();
    this.book = new BookNoveltyModel();
  }

  submit(e: Event) {
    e.preventDefault();

    if (this.base64) {
      this.book.coverImageBlob = this.base64;
    }

    this.appCommunication.loadingOn('Ukladám.');

    let api;

    if (this.isEditing) {
      api = this.bookService.update(this.book);
    } else {
      api = this.bookService.create(this.book);
    }

    api.pipe(first())
      .subscribe(v => {
        this.toastr.success('Kniha úspešne uložená.');
        this.reset();
        this.appCommunication.loadingOff();
      }, err => {
        console.log(err);
        this.toastr.error('Knihu sa uložiť nepodarilo.');
        this.appCommunication.loadingOff();
      });
  }

}
