import { NgModule } from '@angular/core';
import { FormsViewComponent } from './forms-view.component';
import { CommonModule } from '@angular/common';
import { AcquisitionsFormComponent } from './forms/acquisitions-form/acquisitions-form.component';
import { BuyDocumentRequestFormComponent } from './forms/buy-document-request-form/buy-document-request-form.component';
import { DigitalCopyRequestFormComponent } from './forms/digital-copy-request-form/digital-copy-request-form.component';
import { EducationFormComponent } from './forms/education-form/education-form.component';
import { EpcCorrectionRequestFormComponent } from './forms/epc-correction-request-form/epc-correction-request-form.component';
import { FeedbackFormComponent } from './forms/feedback-form/feedback-form.component';
import { MvsBooksFormComponent } from './forms/mvs-books-form/mvs-books-form.component';
import { MvsMagazineFormComponent } from './forms/mvs-magazine-form/mvs-magazine-form.component';
import { PriorityRequestFormComponent } from './forms/priority-request-form/priority-request-form.component';
import { SearchFormComponent } from './forms/search-form/search-form.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../core-frontend-common/auth/guards/auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '../../../core-frontend-common/template/shared/i18n/i18n.module';
import { UserFormSectionComponent } from './forms/user-form-section/user-form-section.component';
import { CustomDatepickerModule } from '../../components/custom-datepicker/custom-datepicker.module';
import { CalendarModule, ChipsModule, DropdownModule } from 'primeng';
import { FormTypesEnum } from './form-types.enum';

const routes: Routes = [
  {
    path: '',
    component: FormsViewComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: FormTypesEnum.mvsBooks, component: MvsBooksFormComponent, pathMatch: 'full' },
      { path: FormTypesEnum.mvsMagazine, component: MvsMagazineFormComponent, pathMatch: 'full' },
      { path: FormTypesEnum.searchRequest, component: SearchFormComponent, pathMatch: 'full' },
      { path: FormTypesEnum.feedback, component: FeedbackFormComponent, pathMatch: 'full' },
      { path: FormTypesEnum.education, component: EducationFormComponent, pathMatch: 'full' },
      { path: FormTypesEnum.acquisition, component: AcquisitionsFormComponent, pathMatch: 'full' },
      { path: FormTypesEnum.digitalCopyRequest, component: DigitalCopyRequestFormComponent, pathMatch: 'full' },
      { path: FormTypesEnum.epcCorrectionRequest, component: EpcCorrectionRequestFormComponent, pathMatch: 'full' },
      { path: FormTypesEnum.buyDocument, component: BuyDocumentRequestFormComponent, pathMatch: 'full' }
    ],
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    I18nModule,
    ReactiveFormsModule,
    CustomDatepickerModule,
    CalendarModule,
    ChipsModule,
    DropdownModule
  ],
  declarations: [
    FormsViewComponent,
    AcquisitionsFormComponent,
    BuyDocumentRequestFormComponent,
    DigitalCopyRequestFormComponent,
    EducationFormComponent,
    EpcCorrectionRequestFormComponent,
    FeedbackFormComponent,
    MvsBooksFormComponent,
    MvsMagazineFormComponent,
    PriorityRequestFormComponent,
    SearchFormComponent,
    UserFormSectionComponent
  ]
})

export class FormsViewModule {}
