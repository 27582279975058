export class FullUserModel {
  activeStatusCode: string;
  birthdate: string;
  cck: string;
  io: string;
  oc: string;
  city: string;
  country: string;
  createdDatetime: Date;
  email: string;
  employmentCode: string;
  endEmploymentDate: Date;
  facultyCode: string;
  facultyId: number;
  facultyName: string;
  fieldOfStudyCode1: string;
  fieldOfStudyName1: string;
  gender: string;
  historicalParentId: number;
  id: number;
  isLdapActive: boolean;
  isThesisRegistered: boolean;
  login: string;
  name: string;
  personTypeCode: string;
  phone: string;
  photoOID: string;
  postalCode: number;
  rolesAsString: string;
  startEmploymentDate: Date;
  street: string;
  studyDepartmentCode: string;
  studyDepartmentName: string;
  studyForm: string;
  studyLevel: string;
  studyProgramCode: string;
  studyProgramName: string;
  surname: string;
  title: string;
  titleAfter: string;
  workCenterCode: string;
  workCenterId: number;
  workCenterName: string;
}
