export class WarehouseLayoutModel {
        id: number;
        floor: number;
        rackSide: string;
        titleIdStartAsString: string;
        titleIdEndAsString: string;
        titleIdStartAsNumber: number;
        titleIdEndAsNumber: number;
        rackType: string;
        section: number;
        rackNumber: number;
        isRackSliding: boolean;
}