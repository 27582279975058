import { Component } from '@angular/core';
import { ReaderCardFullModel } from '../../models/reader-card-full.model';
import { FullUserModel } from '../../models/full-user.model';
import { CardsService } from '../../services/cards.service';
import * as moment from 'moment';
import { ReaderCardFormOutput } from './reader-card-form/reader-card-form-output';
import { isValidEmail } from '../../misc/is-valid-email';
import { ReaderCardCreateRequestModel } from '../../models/reader-card-create-request.model';
import { ToastrService } from 'ngx-toastr';
import { ReaderCardCategoryEnum } from '../../constants';
import { first } from 'rxjs/operators';
import { ReaderCardUpdateRequestModel } from '../../models/reader-card-update-request.model';
import { AppCommunicationService } from '../../services/app-communication.service';
import { I18nService } from "../../../core-frontend-common/template/shared/i18n";

export enum ViewStates {
  input,
  searching,
  searched,
  found,
  not_found,
  creatingCard,
  cardNumbersMismatch
}

export enum CardStates {
  deleted = 'UK=DELETED',
  not_deleted = 'UK'
}

@Component({
  selector: 'app-reader-card-base',
  template: ''
})

export class ReaderCardBaseComponent {

  searchedCard: ReaderCardFullModel;

  searchedUser: FullUserModel;

  viewStates = ViewStates;

  currentViewState = ViewStates.input;

  cardStates = CardStates;

  cardNumber = '';

  bornDateFromInput = '';

  allowedEdit = false;

  newBarcode = '';

  isAdmin = false;

  constructor(
    protected cardsService: CardsService,
    protected message: ToastrService,
    protected appCommunicationService: AppCommunicationService,
    protected i18n: I18nService
  ) {
  }

  public setAdminView(isAdmin: boolean = false) {
    this.isAdmin = isAdmin;
  }

  getCardByCardNumber() {
    if (this.searchedCard && this.searchedCard.barcode && this.cardNumber === this.searchedCard.barcode.trim()) {
      return;
    }

    this.currentViewState = this.viewStates.searching;

    this.appCommunicationService.loadingOn();

    let api;

    if (this.isAdmin) {
      api = this.cardsService.getCardByCardNumberAdmin(this.cardNumber);
    } else {
      api = this.cardsService.getCardByCardNumber(this.cardNumber);
    }

    api.subscribe(v => {

      if (!v) {
        this.currentViewState = this.viewStates.not_found;

        this.cardsService.getUserByCardNumber(this.cardNumber)
          .subscribe(user => {
            if (user) {
              this.searchedUser = user;

              this.getUserByPersonalNumber(this.searchedUser.oc);
            } else {
              this.appCommunicationService.loadingOff();
              this.message.error('Nastala chyba pri hľadaní karty. Skúste to prosím neskôr.');
            }
          }, error => {
            console.log(error);
            this.appCommunicationService.loadingOff();
          });
      } else {
        this.currentViewState = this.viewStates.found;

        this.searchedCard = v;

        this.cardNumber = this.searchedCard.barcode.trim();

        this.appCommunicationService.loadingOff();
      }

    }, e => {
      console.log(e);
      this.currentViewState = this.viewStates.input;

      this.appCommunicationService.loadingOff();
    });
  }

  getUserByPersonalNumber(personalNumber: string) {
    this.cardsService
      .getUserByPersonalNumber(personalNumber)
      .pipe(first())
      .subscribe({
        next: value => {
          // dany citatel ma citatelsku kartu len nesedia cisla karty
          if (value) {

            this.currentViewState = ViewStates.cardNumbersMismatch;

            this.searchedCard = value;

            this.appCommunicationService.loadingOff();

            // bud mu umoznime zmenit cislo karty alebo zobrazime hlasku nech pride do UK

          } else {

            this.fullFillFormWithUserData();

            this.appCommunicationService.loadingOff();

          }
        },
        error: err => {

          this.fullFillFormWithUserData();

          this.appCommunicationService.loadingOff();

          console.log(err);
        }
      });
  }

  fullFillFormWithUserData() {
    if (!this.searchedUser) {
      return;
    }

    this.searchedCard = new ReaderCardFullModel();
    this.searchedCard.barcode = this.searchedUser.cck;
    this.searchedCard.identifyCard = this.searchedUser.io;
    this.searchedCard.identify = this.searchedUser.oc;

    this.searchedCard.sign = this.searchedUser.title;
    this.searchedCard.firstname = this.searchedUser.name;
    this.searchedCard.lastname = this.searchedUser.surname;
    this.searchedCard.phone = this.searchedUser.phone;
    this.searchedCard.email = this.searchedUser.email;
    this.searchedCard.address1town = this.searchedUser.city;
    this.searchedCard.address1street = this.searchedUser.street;
    this.searchedCard.address1psc = this.searchedUser.postalCode ? this.searchedUser.postalCode.toString(0) : '';

    this.searchedCard = this.fullFillPersonType(this.searchedCard, this.searchedUser);

    if (this.searchedUser.workCenterName) {
      this.searchedCard.employeer = this.searchedUser.workCenterName;
    } else if (this.searchedUser.workCenterCode) {
      this.searchedCard.employeer = this.searchedUser.workCenterCode;
    } else if (this.searchedUser.facultyName) {
      this.searchedCard.employeer = this.searchedUser.facultyName;
    } else if (this.searchedUser.facultyCode) {
      this.searchedCard.employeer = this.searchedUser.facultyCode;
    }
    this.searchedCard.borndate = this.searchedUser.birthdate ? moment(this.searchedUser.birthdate).toDate() : null;

    this.currentViewState = this.viewStates.creatingCard;
    this.allowedEdit = true;
  }

  fullFillPersonType(card: ReaderCardFullModel, user: FullUserModel) {
    let isStudent = false;

    if (user.personTypeCode) {

      if (user.personTypeCode.toLowerCase() === 'd') {
        isStudent = true;
      }
      if (user.personTypeCode.toLowerCase() === 'e') {
        isStudent = true;
      }
      if (user.personTypeCode.toLowerCase() === 's') {
        isStudent = true;
      }
      if (user.personTypeCode.toLowerCase() === 'x') {
        isStudent = true;
      }
      if (user.personTypeCode.toLowerCase() === 'm') {
        isStudent = true;
      }

    }

    if (isStudent) {
      card.category = ReaderCardCategoryEnum.student;
    } else {
      card.category = ReaderCardCategoryEnum.employeer;
    }

    return card;
  }

  changedSearchedCard(change: ReaderCardFormOutput) {
    this.searchedCard = change.form;
    this.bornDateFromInput = change.bornDateFromInput;
  }

  newCardBarcodeChanged(barcode: string) {
    this.newBarcode = barcode;
  }

  isValidForm(): boolean {
    return !!(this.searchedCard.barcode && this.searchedCard.firstname &&
      this.searchedCard.lastname && this.searchedCard.address1town &&
      this.searchedCard.address1street && this.searchedCard.address1psc &&
      this.searchedCard.employeer && isValidEmail(this.searchedCard.email) &&
      this.searchedCard.phone && !isNaN(this.searchedCard.category));
  }

  createCard(): Promise<boolean> {

    return new Promise((resolve, reject) => {
      this.appCommunicationService.loadingOn();

      const createCard = new ReaderCardCreateRequestModel();

      Object.assign(createCard, this.searchedCard);

      if (!this.searchedCard.borndate) {

        if (this.bornDateFromInput) {

          const date = moment(this.bornDateFromInput, 'DD.MM.YYYY').toDate();

          if (date) {
            createCard.borndate = date;
          } else {
            resolve(false);
            return;
          }

        } else {
          resolve(false);
          return;

        }

      }

      // @ts-ignore
      createCard.borndate = moment(createCard.borndate).format('YYYY-MM-DDTHH:mm:ss');

      this.cardsService
        .createCard(createCard)
        .subscribe(v => {
          this.message.success('Karta bola úspešne vytvorená.');
          this.appCommunicationService.loadingOff();
          resolve(true);
        }, e => {
          resolve(false);
          this.message.error('Kartu sa vytvoriť nepodarilo.');

          if (e.error) {
            // check if is json object, if yes iterate over keys and show all errors
            if (typeof e.error === 'object') {
              Object.keys(e.error).forEach(key => {
                this.message.error(this.i18n.getTranslation(e.error[key]));
              });
            } else {
              this.message.error(e.error);
            }
          }

          this.appCommunicationService.loadingOff();
        });

    });
  }

  changeCardBarcode(): Promise<boolean> {

    return new Promise((resolve, reject) => {
      this.appCommunicationService.loadingOn();

      const updateCard = new ReaderCardUpdateRequestModel();

      Object.assign(updateCard, this.searchedCard);

      updateCard.barcode = this.cardNumber;

      this.cardsService
        .updateCard(updateCard)
        .subscribe(v => {
          this.message.success('Karta bola úspešne zmenená.');
          this.appCommunicationService.loadingOff();
          resolve(true);
        }, e => {
          resolve(false);
          this.message.error('Kartu sa zmeniť nepodarilo.');
          this.appCommunicationService.loadingOff();
        });

    });
  }
}
