import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ReaderCardFullModel } from '../../../models/reader-card-full.model';
import { ReaderCardCategories } from '../../../constants';
import { FullUserModel } from '../../../models/full-user.model';
import { Dropdown } from 'primeng';
import { ReaderCardFormOutput } from './reader-card-form-output';

@Component({
  selector: 'smart-reader-card-form',
  templateUrl: './reader-card-form.component.html',
  styleUrls: ['./reader-card-form.component.scss']
})
export class ReaderCardFormComponent implements OnInit {

  searchedCard: ReaderCardFullModel;

  searchedUser: FullUserModel;

  readerCardCategories = ReaderCardCategories;

  bornDateFromInput = '';

  isAdmin = false;

  @Input() isCreating = false;

  @Input() isUpdating = false;

  @Input() allowedEdit = false;

  @ViewChild('readerCardEl', { static: false }) readerCardEl: Dropdown;

  @Input() set setSearchedCard(card: ReaderCardFullModel) {
    this.searchedCard = card;

    setTimeout(() => {
        this.fullFillReaderCardEl();
    }, 250);
  }

  @Input() set setSearchedUser(user: FullUserModel) {
    this.searchedUser = user;
  }

  @Output() changedSearchedCard: EventEmitter<ReaderCardFormOutput> = new EventEmitter<ReaderCardFormOutput>();

  @Input() set setIsAdmin(isAdmin: boolean) {
    this.isAdmin = isAdmin;
  }

  constructor() {
  }

  ngOnInit() {
  }

  onReaderCardCategoryChange(category) {
    this.searchedCard.category = category.value.value;
    this.changedForm();
  }

  changedBornDate(v) {
    this.bornDateFromInput = v;
    this.changedForm();
  }

  changedRemark() {
    this.changedForm();
  }

  fullFillReaderCardEl() {
    if (!isNaN(this.searchedCard.category)) {
      const selected = this.readerCardCategories.find(r => r.value === this.searchedCard.category);

      this.readerCardEl.selectedOption = { label: selected.title, value: selected };
    }
  }

  detectChangeSearchedCard() {
    this.changedSearchedCard.emit({form: this.searchedCard, bornDateFromInput: this.bornDateFromInput});
  }

  changedForm() {
    this.detectChangeSearchedCard();
  }
}
