import { Pipe, PipeTransform } from '@angular/core';
import {
  SeminarFormTypeEnum,
  SeminarFormTypes,
  TukeRelationshipEnum,
  TukeRelationShips
} from '../views/forms-view/forms/education-form/seminars';
import { FormStatusEnum, FormStatuses } from '../constants';
import {
  EpcCorrectionTypes
} from '../views/forms-view/forms/epc-correction-request-form/epc-correction-request-types';
import { EpcDigitalCopyTypes } from '../views/forms-view/forms/digital-copy-request-form/digital-copy-request-types';

export type FormCodesTypes = 'seminar' | 'formStatus' | 'tukeRelationship' | 'epcCorrection' | 'epcDigitalCopy';

@Pipe({
  name: 'formsCodes'
})
export class FormsCodesPipe implements PipeTransform {

  transform(code: SeminarFormTypeEnum | FormStatusEnum | TukeRelationshipEnum | number, type: FormCodesTypes): any {
    switch (type) {
      case 'seminar':
        return SeminarFormTypes.find(r => r.code === code) || ' - ';
      case 'formStatus':
        return FormStatuses.find(s => s.value === code) || ' - ';
      case 'tukeRelationship':
        return TukeRelationShips.find(r => r.code === code) || ' - ';
      case 'epcCorrection':
        return EpcCorrectionTypes.find(r => r.value === code) || ' - ';
      case 'epcDigitalCopy':
        return EpcDigitalCopyTypes.find(r => r.value === code) || ' - ';
    }
    return '';
  }

}
