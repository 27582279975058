import { Component, OnInit } from '@angular/core';
import { DigitalCopyRequestTypes } from './digital-copy-request-types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OVI_SUPPORT } from '../../../../app-constants';
import { BaseForm } from "../../base-form";
import { FormEpcBiblioCorrectionRequest } from "../../../../models/forms/FormEpcBiblioCorrectionRequest";
import { FormType } from "../../form-types.enum";
import { ToastrService } from "ngx-toastr";
import { FormsService } from "../../../../services/forms.service";
import { FormEpcDigitalCopyRequest } from "../../../../models/forms/FormEpcDigitalCopyRequest";

@Component({
  selector: 'app-digital-copy-request-form',
  templateUrl: './digital-copy-request-form.component.html',
  styleUrls: ['./digital-copy-request-form.component.scss']
})
export class DigitalCopyRequestFormComponent extends BaseForm implements OnInit {
  oviSupport = OVI_SUPPORT;

  digitalCopyRequestTypes = DigitalCopyRequestTypes;

  form: FormGroup;

  digitalCopy: FormEpcDigitalCopyRequest;

  constructor(
    protected fb: FormBuilder,
    protected toastr: ToastrService,
    protected formService: FormsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      book: this.fb.group({
        archiveIds: ['', [Validators.required]],
        reasonCode: ['', [Validators.required]],
        requiredDate: ['', [Validators.required]],
        message: ['']
      })
    });

    this.form.get('book').get('reasonCode')
      .valueChanges.subscribe({
      next: value => {
        if (value === this.digitalCopyRequestTypes.others.value) {
          this.form.get('book').get('message').setValidators([Validators.required]);
          this.form.get('book').get('message').updateValueAndValidity({emitEvent: true});
        } else {
          this.form.get('book').get('message').clearValidators();
          this.form.get('book').get('message').updateValueAndValidity({emitEvent: true});
        }
      }
    });
  }

  async submit(e: Event) {
    e.preventDefault();

    this.digitalCopy = new FormEpcBiblioCorrectionRequest();
    this.digitalCopy = this.fullFillUserForm(this.digitalCopy);
    this.digitalCopy = this.fullFillDataFromForm(this.digitalCopy, this.form.get('book').value);

    const req = await this.formService
      .create(FormType.epcDigitalCopy, this.digitalCopy).toPromise();

    if (req) {
      this.toastr.success('Záznam úspešne uložený.');
      this.form.reset();
      return;
    }

    this.toastr.error('Záznam sa uložiť nepodarilo.');
  }

  downloadForm() {
    console.log('download form');
  }

}
