import { Component, OnInit, OnDestroy } from '@angular/core';
import { BorrowModel } from '../../../../models/borrow.model';
import { BorrowService } from '../../../../services/borrow.service';
import { BtnFilterItemModel } from '../../../../components/btn-group-filter/btn-filter-item.model';
import { WarehouseSections } from '../../../../constants';


@Component({
  selector: 'smart-borrow-view',
  templateUrl: './borrow-view.component.html',
  styleUrls: ['./borrow-view.component.scss']
})
export class BorrowViewComponent implements OnInit, OnDestroy {

  btnFilter: BtnFilterItemModel[] = [
    {
      id: WarehouseSections.SECTION_1,
      color: '#92A2A8',
      title: 'Sekcia1',
      count: 0
    },
    {
      id: WarehouseSections.SECTION_2,
      color: '#92A2A8',
      title: 'Sekcia2',
      count: 0
    },
    {
      id: WarehouseSections.SECTION_3,
      color: '#92A2A8',
      title: 'Sekcia3',
      count: 0
    },
    {
      id: WarehouseSections.SECTION_4,
      color: '#92A2A8',
      title: 'Sekcia4',
      count: 0
    },
    {
      id: WarehouseSections.SECTION_5,
      color: '#92A2A8',
      title: 'Sekcia5',
      count: 0
    },
    {
      id: WarehouseSections.SECTION_6,
      color: '#92A2A8',
      title: 'Sekcia6',
      count: 0
    },
    {
      id: WarehouseSections.SECTION_7,
      color: '#92A2A8',
      title: 'Sekcia7',
      count: 0
    },
    {
      id: WarehouseSections.SECTION_8,
      color: '#92A2A8',
      title: 'Sekcia8',
      count: 0
    }
  ];

  selectedBtnId: number = WarehouseSections.ALL_SECTION;

  allBorrowOrders: BorrowModel[] = [];

  filteredBorrowOrders: BorrowModel[] = [];

  date: Date = new Date();

  refreshInterval;

  constructor(protected borrowService: BorrowService) {
    this.refreshInterval = setInterval(() => {
      this.date = new Date();
      this.getAllOrdersWithReader();
    }, 60000);
  }

  ngOnInit() {
    this.getAllOrdersWithReader();
  }

  ngOnDestroy() {
    clearInterval(this.refreshInterval);
  }

  getAllOrdersWithReader() {
    this.borrowService
      .getAllOrdersWithReader()
      .subscribe(borrowOrders => {
        this.allBorrowOrders = borrowOrders;
        this.filterBorrowOrders();
        this.countBorrowOrdersToSections();
      });
  }

  onSelectedBtnFilter(btnId: number) {
    this.selectedBtnId = btnId;
    this.filterBorrowOrders();

  }

  filterBorrowOrders() {
    if (this.selectedBtnId === WarehouseSections.ALL_SECTION) {
      this.filteredBorrowOrders = this.allBorrowOrders;
    } else {
      this.filteredBorrowOrders = this.allBorrowOrders.filter(item => item.warehousePosition.section === this.selectedBtnId);
    }
  }

  countBorrowOrdersToSections() {
    this.btnFilter.forEach(item => {

      const countOnSection = this.allBorrowOrders.filter(order => order.warehousePosition.section === item.id);
      if (countOnSection) {
        item.count = countOnSection.length;
        return;
      }

      item.count = 0;

    });

  }

  printLabel(sectionId: number) {
    this.borrowService
      .printLabel(sectionId)
      .subscribe(() => {
        this.getAllOrdersWithReader();
      });
  }

}
