import { Pipe, PipeTransform } from '@angular/core';
import { PERSON_TYPES_USER_REG } from '../constants';

@Pipe({
  name: 'userRegPerson'
})
export class UserRegPersonPipe implements PipeTransform {

  transform(personType: string): any {
    const person = PERSON_TYPES_USER_REG.find(p => p.code.toLowerCase() === personType.toLowerCase());

    if (person) {
      return person.name;
    }

    return '-';
  }

}
