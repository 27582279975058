import { Component, OnInit } from '@angular/core';
import { NewsItemModel } from '../news-item.model';
import { AppCommunicationService } from '../../../../services/app-communication.service';
import { LibtukeNewsService } from '../libtuke-news.service';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

@Component({
  selector: 'smart-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.scss']
})
export class AddNewsComponent implements OnInit {
  newsItem: NewsItemModel = new NewsItemModel();

  isEditing = false;

  constructor(
    private appCommunication: AppCommunicationService,
    private newsService: LibtukeNewsService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
  }

  editMode(item: NewsItemModel) {
    this.newsItem = item;

    this.isEditing = true;
  }

  reset() {
    this.newsItem = new NewsItemModel();
  }

  submit(e: Event) {
    e.preventDefault();

    this.appCommunication.loadingOn('Ukladám.');

    let api;

    if (this.isEditing) {
      api = this.newsService.update(this.newsItem);
    } else {
      api = this.newsService.create(this.newsItem);
    }

    api.pipe(first())
      .subscribe(v => {
        this.toastr.success('Novinka úspešne uložená.');
        this.reset();
        this.appCommunication.loadingOff();
      }, err => {
        console.log(err);
        this.toastr.error('Novinku sa uložiť nepodarilo.');
        this.appCommunication.loadingOff();
      });
  }

}
