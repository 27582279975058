import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: 'smart-card-item',
  templateUrl: './card-item.component.html',
  styleUrls: ['./card-item.component.scss']
})
export class CardItemComponent implements OnInit {

  @Input() photo: ArrayBuffer | string = '';

  @Input() user;

  @Output() clickedUserContainer: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  openPhoto(photo) {
    const image = new Image();
    image.src = photo;

    const w = window.open('', '_blank');
    w.document.write(image.outerHTML);
  }

  clickedUser(e: Event) {
    e.preventDefault();
    this.clickedUserContainer.emit(this.user);
  }
}
