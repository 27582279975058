import { BaseFormEntityRequest } from './BaseFormEntityRequest';

export class FormSeminarRequest extends BaseFormEntityRequest {
  tukeRelationshipCode: number;
  typeCode?: number;
  categoryCode: string;
  id?: number;
  eventDatetime: Date;
  participantCount: number;
  visitorsCount?: number;
  responsibleById?: number;
  responsibleBy?: any;
}
