import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BaseForm } from "../../base-form";
import { FormMvsBookRequest } from "../../../../models/forms/FormMvsBookRequest";
import { FormType } from "../../form-types.enum";
import { FormMvsJournalRequest } from "../../../../models/forms/FormMvsJournalRequest";
import { FormsService } from "../../../../services/forms.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-mvs-magazine-form',
  templateUrl: './mvs-magazine-form.component.html',
  styleUrls: ['./mvs-magazine-form.component.scss']
})
export class MvsMagazineFormComponent extends BaseForm implements OnInit {
  form: FormGroup;

  mvsJournal: FormMvsJournalRequest;

  constructor(
    protected fb: FormBuilder,
    protected formService: FormsService,
    protected toastr: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      book: this.fb.group({
        journalTitle: ['', [Validators.required]],
        issue: ['', [Validators.required]],
        yearOfPublishing: ['', [Validators.required]],
        volume: ['', [Validators.required]],
        issn: [''],
        author: ['', [Validators.required]],
        articleTitle: ['', [Validators.required]],
        pageRange: ['', [Validators.required]],
        validUntilDatetime: [''],
        message: ['']
      })
    });
  }

  async submit(e: Event) {
    e.preventDefault();

    this.mvsJournal = new FormMvsJournalRequest();
    this.mvsJournal = this.fullFillUserForm(this.mvsJournal);
    this.mvsJournal = this.fullFillDataFromForm(this.mvsJournal, this.form.get('book').value);

    const req = await this.formService
      .create(FormType.mvsJournal, this.mvsJournal).toPromise();

    if (req) {
      this.toastr.success('Záznam úspešne uložený.');
      this.form.reset();
      return;
    }

    this.toastr.error('Záznam sa uložiť nepodarilo.');
  }

}
