import { DigitalBookAuthorModel } from './digital-book-author.model';

export class DigitalBookModel {
  id?: number;

  title: string;
  subtitle: string;
  authorIds: number[];
  isbn: string;
  issn: string;
  doi: string;
  otherIdentifier: string;
  yearOfPublishing: string;
  comment: string;
  fileMetadataId: number;

  fileMetadata?: any;
  authors?: DigitalBookAuthorModel[];
}
