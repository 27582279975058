import { UserFormValue, UserSectionValidity } from './forms/user-form-section/user-form-section.component';
import { FormBuyDocumentRequest } from '../../models/forms/FormBuyDocumentRequest';
import { FormMvsBookRequest } from '../../models/forms/FormMvsBookRequest';
import { FormMvsJournalRequest } from '../../models/forms/FormMvsJournalRequest';
import { FormSearchRequest } from '../../models/forms/FormSearchRequest';
import { FormSeminarRequest } from '../../models/forms/FormSeminarRequest';
import * as moment from 'moment';
import { FormEpcBiblioCorrectionRequest } from '../../models/forms/FormEpcBiblioCorrectionRequest';

export abstract class BaseForm {
  isUserFormValid = false;
  userFormValue: UserFormValue;

  userFormSectionChanged(form: UserSectionValidity) {
    this.isUserFormValid = form.valid;
    this.userFormValue = form.user;
  }

  // @ts-ignore
  fullFillUserForm(m: FormEpcBiblioCorrectionRequest): FormEpcBiblioCorrectionRequest;
  fullFillUserForm(m: FormBuyDocumentRequest): FormBuyDocumentRequest;
  fullFillUserForm(m: FormMvsBookRequest): FormMvsBookRequest;
  fullFillUserForm(m: FormMvsJournalRequest): FormMvsJournalRequest;
  fullFillUserForm(m: FormSearchRequest): FormSearchRequest;
  fullFillUserForm(m: FormSeminarRequest): FormSeminarRequest;
  fullFillUserForm(m: FormSeminarRequest): FormSeminarRequest {
    Object.keys(this.userFormValue)
      .forEach(key => {
        m[key] = this.userFormValue[key];
      });
    return m;
  }

  // @ts-ignore
  fullFillDataFromForm(m: FormEpcBiblioCorrectionRequest, formValue: any): FormEpcBiblioCorrectionRequest;
  fullFillDataFromForm(m: FormBuyDocumentRequest, formValue: any): FormBuyDocumentRequest;
  fullFillDataFromForm(m: FormMvsBookRequest, formValue: any): FormMvsBookRequest;
  fullFillDataFromForm(m: FormMvsJournalRequest, formValue: any): FormMvsJournalRequest;
  fullFillDataFromForm(m: FormSearchRequest, formValue: any): FormSearchRequest;
  fullFillDataFromForm(m: FormSeminarRequest, formValue: any): FormSeminarRequest;
  fullFillDataFromForm(m: FormSeminarRequest, formValue: any): FormSeminarRequest {
    Object.keys(formValue)
      .forEach(key => {
        m[key] = formValue[key];

        if (m[key] !== null && m[key] !== undefined && m[key].length > 0) {

          if (key.toLowerCase().includes('datetime')) {
            const date = new Date(m[key]);
            if (date) {
              m[key] = moment(date).format('YYYY-MM-DDTHH:mm:ss');
            }
          }

          if (key.toLowerCase().includes('requireddate'.toLowerCase())) {
            const date = new Date(m[key]);
            if (date) {
              m[key] = moment(date).format('YYYY-MM-DDTHH:mm:ss');
            }
          }

          if (key.toLowerCase().includes('yearofpublishing')) {
            const date = new Date(m[key]);
            if (date) {
              m[key] = moment(date).format('YYYY');
            }
          }

        }

      });
    return m;
  }

}
