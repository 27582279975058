import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseForm } from '../../base-form';
import { FormMvsBookRequest } from '../../../../models/forms/FormMvsBookRequest';
import { FormsService } from '../../../../services/forms.service';
import { FormType } from '../../form-types.enum';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mvs-books-form',
  templateUrl: './mvs-books-form.component.html',
  styleUrls: ['./mvs-books-form.component.scss']
})
export class MvsBooksFormComponent extends BaseForm implements OnInit {

  form: FormGroup;

  mvsBook: FormMvsBookRequest = new FormMvsBookRequest();

  constructor(
    protected fb: FormBuilder,
    protected formService: FormsService,
    protected toastr: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      book: this.fb.group({
        author: ['', [Validators.required, Validators.minLength(2)]],
        title: ['', [Validators.required, Validators.minLength(2)]],
        isbn: [''],
        validUntilDatetime: [''],
        message: ['']
      })
    });
  }

  async submit(e: Event) {
    e.preventDefault();
    this.mvsBook = new FormMvsBookRequest();
    this.mvsBook = this.fullFillUserForm(this.mvsBook);
    this.mvsBook = this.fullFillDataFromForm(this.mvsBook, this.form.get('book').value);

    const req = await this.formService
      .create(FormType.mvsBook, this.mvsBook).toPromise();

    if (req) {
      this.toastr.success('Záznam úspešne uložený.');
      this.form.reset();
      return;
    }

    this.toastr.error('Záznam sa uložiť nepodarilo.');
  }


}
